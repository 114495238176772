import React from "react";
import _ from "lodash";
import { LinkOutlined, } from "@ant-design/icons";

const OrdererHeader = ({ orderer, linksToShow }) => {
  return (
    <>
      {orderer.picture &&
        <div
          className="gx-product-image"
          style={{
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            height: "400px",
            backgroundImage: `url('${orderer.picture}')`,
          }}
        ></div>
      }
      <div
        className="gx-profile-banner"
        style={{ marginLeft: "0px", marginRight: "0px" }}
      >
        <div className="gx-profile-container">
          <div className="gx-profile-banner-top">
            <div className="gx-profile-banner-top-left">
              {orderer.logo &&
                <div className="gx-profile-banner-avatar">
                  <img
                    alt={orderer.name}
                    src={orderer.logo}
                    style={{ maxHeight: "3.5rem" }}
                  />
                </div>
              }
              <div className="gx-profile-banner-avatar-info">
                <h2 className="gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">
                  {orderer.name}
                </h2>
                {orderer.country &&
                  <p className="gx-mb-0 gx-fs-lg">
                    <i style={{ marginBottom: "-7px" }} title={orderer.country.name} className={`flag flag-24 gx-mr-2 flag-${_.lowerCase(orderer.country.a2)}`} />
                    {orderer.country?.name}
                  </p>
                }
              </div>
            </div>
          </div>
          <div className="gx-profile-banner-bottom">
            <div className="gx-tab-list">
              <ul className="gx-navbar-nav">
                {linksToShow.includes("about") && (
                  <li>
                    <a href="#about">
                      <span className="gx-link">About</span>
                    </a>
                  </li>
                )}
                {linksToShow.includes("statistics") && (
                  <li>
                    <a href="#statistics">
                      <span className="gx-link">Statistics</span>
                    </a>
                  </li>
                )}
                {linksToShow.includes("orders") && (
                  <li>
                    <a href="#orders">
                      <span className="gx-link">Orders</span>
                    </a>
                  </li>
                )}
                {linksToShow.includes("location") && (
                  <li>
                    <a href="#location">
                      <span className="gx-link">Location</span>
                    </a>
                  </li>
                )}
                {linksToShow.includes("contact") && (
                  <li>
                    <a href="#contact">
                      <span className="gx-link">Contact</span>
                    </a>
                  </li>
                )}
                {linksToShow.includes("subCompanies") && (
                  <li>
                    <a href="#subCompanies">
                      <span className="gx-link">Sister Companies</span>
                    </a>
                  </li>
                )}

                <li>
                  <a href={`https://www.trusteddocks.com${orderer.url}`} target="_blank">
                    <span className="gx-link">Fleet Information</span>
                    <LinkOutlined className="ml5" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrdererHeader;
