import React, { useMemo, Fragment } from "react";
import { Col, Row, Table, Tabs, Tag } from "antd";
import About from "../../components/profile/About/index";
import Contact from "../../components/profile/Contact/index";
import Auxiliary from "../../util/Auxiliary";
import OrdererHeader from "../../components/profile/OrdererHeader/index";
import { Link, useParams } from "react-router-dom";
import CircularProgress from "../../components/CircularProgress";
import { useAuth0 } from "@auth0/auth0-react";
import Widget from "../../components/Widget";
import ContentAuthorization from "../ContentAuthorization";
import usePageQueries from "./queries";
import GalleryAndLightbox from "../../components/GalleryAndLightbox";
import moment from "moment";
import { useIntl } from "react-intl";
import _ from "lodash";
import PieChart from "../../components/PieChart";
import BarChart from "../../components/BarChart";
import useChartData from "./chartsData";
import Address from "../../components/profile/Address";
import TinyBarChart from "../../routes/extensions/charts/recharts/bar/Components/TinyBarChart";
import { Helmet } from "react-helmet";

const Orderer = () => {
  const intl = useIntl();
  let { slug } = useParams();
  let ordererId = slug.split("-")[0];

  const { isAuthenticated } = useAuth0();

  const {
    permissions,
    permissionsError,
    main,
    pageDataLoading,
    mainError,
    ordersData,
    ordersDataError,
  } = usePageQueries({ isAuthenticated, ordererId });

  const {
    deliveredShipsPieChartData,
    vesselTypesPieChartData,
    deliveredAndOrderedShipsPerYearBarChartData,
    ordersByLengthBarChartData,
  } = useChartData({ ordersData });

  const linksToShow = useMemo(() => {
    const link = [];
    if (ordersData && ordersData.length > 0) link.splice(1, 0, "orders");
    if (ordersData && ordersData.length > 0) link.splice(1, 0, "statistics");
    if (main && main.description) link.splice(0, 0, "about");
    if (main && main.contacts) link.push("contact");
    if (main && main.subCompanies && main.subCompanies.length > 0) link.push("subCompanies");
    return link;
  }, [ordersData, main]);

  const isError =
    mainError ||
    permissionsError ||
    ordersDataError;

  const pagePermissions = permissions || {};

  if (pageDataLoading) return <CircularProgress />;
  if (isError) return <div>Sorry an Error occurred</div>;

  const PhotoGallery = () => {
    if (!main?.images?.length) return null;
    return (
      <div id="photos">
        <Widget styleName="gx-card-profile">
          <div className="ant-card-head">
            <span className="ant-card-head-title gx-mb-1">Photo Gallery</span>
          </div>
          <div className="gx-pt-md-3">
            <GalleryAndLightbox urlObjects={main?.images} type="image" />
          </div>
        </Widget>
      </div>
    );
  };

  const Statistic = () => {
    return (
      <div id="statistics">
        <Widget styleName="gx-card-profile">
          <div className="ant-card-head">
            <span className="ant-card-head-title gx-mb-1">Statistics last 5 years (from {new Date().getFullYear() - 5})</span>
          </div>
          <div className="gx-pt-md-3">
            <Row>
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <PieChart
                  data={deliveredShipsPieChartData}
                  title="Delivery Status"
                />
              </Col>
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <PieChart data={vesselTypesPieChartData} title="Vessel Types" />
              </Col>
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <TinyBarChart
                  data={deliveredAndOrderedShipsPerYearBarChartData}
                  title="Ordered / Delivered / Delivery per Year"
                />
              </Col>
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <BarChart
                  data={ordersByLengthBarChartData}
                  title="Vessel Length"
                  YAxisLabel="Number of Vessels"
                />
              </Col>
            </Row>
          </div>
        </Widget>
      </div>
    );
  }

  const Sister = ({ companies }) => {
    return (
      <div id="subCompanies">
        <Widget styleName="gx-card-profile">
          <div className="ant-card-head">
            <span className="ant-card-head-title gx-mb-1">Sister Companies</span>
          </div>
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              {companies.map((c, index) => {
                return (
                  <Fragment key={`sub_com_${index}`}>
                    <a target="_blank"  href={`/orderers/${c.idStr}-${_.snakeCase(c.name)}`} >
                      <Tag>
                        {c.name}
                        {c.onOrder ? " (On Order: " + c.onOrder + ")" : ""}
                      </Tag>
                    </a>
                  </Fragment>
                )
              })}
            </Col>
          </Row>
        </Widget>
      </div>
    );
  }

  const Orders = () => {
    const orderbookColumns = useMemo(
      () => [
        {
          title: "Imo",
          dataIndex: "imo",
          sorter: (a, b) => (a.imo > b.imo ? 1 : -1),
          render: (text, record) => (
            <a
              href={`/app/orders/${record.id}-${_.kebabCase(text)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {text}
            </a>
          ),
        },
        {
          title: "Hull No.",
          dataIndex: "hullNo",
          sorter: (a, b) => (a.hullNo > b.hullNo ? 1 : -1),
        },
        {
          title: "Orderer",
          dataIndex: "orderer",
          key: "orderer",
          render: (text, record) => (
            <>
              {record.orderer?.ordererUrl && (
                <span className="gx-link">
                  <a
                    href={`/app${record.orderer?.ordererUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {record.orderer?.name}
                  </a>
                </span>
              )}

              {!record.orderer?.ordererUrl && <>{record.orderer?.name}</>}
            </>
          ),
          sorter: (a, b) => a.orderer?.name > b.orderer?.name,
        },
        {
          title: "Vessel Type",
          dataIndex: "tdVesselType",
          render: (text, record) => (
            <a
              href={`/app/orders/${record.id}-${_.kebabCase(text)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              {text}{" "}
            </a>
          ),
          sorter: (a, b) => a.tdVesselType > b.tdVesselType,
        },
        {
          title: "Name",
          dataIndex: "name",
          sorter: (a, b) => (a.name > b.name ? 1 : -1),
          render: (text, record) => (
            <a
              href={`/app/orders/${record.id}-${_.kebabCase(text)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              {text}{" "}
            </a>
          ),
        },
        {
          title: "Length",
          dataIndex: "length",
          key: "length",
          defaultSortOrder: "descend",
          sorter: (a, b) => (a["length"] > b["length"] ? 1 : -1),
        },
        {
          title: "Width",
          dataIndex: "width",
          key: "width",
          sorter: (a, b) => (a.width > b.width ? 1 : -1),
        },
        {
          title: "DWT",
          dataIndex: "deadweight",
          key: "deadweight",
          defaultSortOrder: "descend",
          sorter: (a, b) => (a.deadweight > b.deadweight ? 1 : -1),
        },
        {
          title: "TEU",
          dataIndex: "teu",
          key: "teu",
          sorter: (a, b) => (a.teu > b.teu ? 1 : -1),
        },
        {
          title: "Status",
          dataIndex: "tdStatus",
          key: "tdStatus",
          render: (text, record) => (
            <>
              {_.startCase(_.lowerCase(text))}
            </>
          ),
          sorter: (a, b) => (a.tdStatus > b.tdStatus ? 1 : -1),
        },
        {
          title: "Order Date",
          dataIndex: "orderDate",
          key: "orderDate",
          render: (d) => (d ? moment(d).format("DD.MMM.YYYY") : ""),
          sorter: (a, b) => (moment(a).isAfter(moment(b)) ? 1 : -1),
        },
        {
          title: "Delivery Date",
          dataIndex: "deliveryDate",
          key: "deliveryDate",
          render: (d) => (d ? moment(d).format("DD.MMM.YYYY") : ""),
          sorter: (a, b) => (moment(a).isAfter(moment(b)) ? 1 : -1),
        },
      ],
      []
    );

    return (
      <div id="orders">
        <Widget styleName="gx-card-profile">
          <div className="ant-card-head">
            <span className="ant-card-head-title gx-mb-1">Order Details</span>
          </div>
          <Tabs type="card">
            {
              [
                { name: "ON_ORDER", title: "On Order" },
                { name: "DELIVER_EXPECTED", title: "Delivery Expected" },
                { name: "LOI", title: "LOI" },
                { name: "OPTION", title: "Option" },
                { name: "RUMOURS", title: "Rumours" },
                { name: "DELIVERED", title: "Delivered" },
                { name: "CANCELLED", title: "Cancelled" },
              ].map((status, idx) => {
                const s = status.name
                const t = status.title
                return (
                  <Fragment key={`order_detail_${idx}`}>
                    {ordersData.filter(d => d.tdStatus === s).length > 0 &&
                      <Tabs.TabPane tab={`${t} (${ordersData.filter(d => d.tdStatus === s).length})`} key={s}>
                        <Table
                          className="gx-table-responsive"
                          columns={orderbookColumns}
                          rowKey="id"
                          dataSource={ordersData.filter(d => d.tdStatus === s) || []}
                          sortDirections={["ascend", "descend", "ascend"]}
                          pagination={{ hideOnSinglePage: true }}
                        />
                      </Tabs.TabPane>
                    }
                  </Fragment>
                )
              })
            }
          </Tabs>
        </Widget>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>{`Orderer - ${main.name}`}</title>
      </Helmet>
      <Auxiliary>
        <OrdererHeader orderer={main} linksToShow={linksToShow} />
        <div className="gx-profile-content">
          <Row>
            <Col xl={18} lg={14} md={14} sm={24} xs={24}>
              {ordersData && ordersData.description > 0 && (
                <ContentAuthorization
                  sectionId="shipyard-company_main_details"
                  permission={pagePermissions.company_main_details}
                >
                  <About content={main.description} />
                </ContentAuthorization>
              )}
              {ordersData && ordersData.length > 0 && (
                <ContentAuthorization
                  sectionId="orderer-company_contacts"
                  permission={{ show: true, mask: isAuthenticated ? false : true }}
                >
                  <Statistic />
                </ContentAuthorization>
              )}
              {ordersData && ordersData.length > 0 && (
                <ContentAuthorization
                  sectionId="orderer-company_contacts"
                  permission={{ show: true, mask: isAuthenticated ? false : true }}
                >
                  <Orders />
                </ContentAuthorization>
              )}
              <ContentAuthorization
                sectionId="shipyard-company_main_picture"
                permission={pagePermissions.company_main_picture}
              >
                <PhotoGallery />
              </ContentAuthorization>

              {main && main.subCompanies && main.subCompanies.length > 0 && (
                <ContentAuthorization
                  sectionId="shipyard-company_main_details"
                  permission={pagePermissions.company_main_details}
                >
                  <Sister companies={main.subCompanies} />
                </ContentAuthorization>
              )}
            </Col>

            <Col xl={6} lg={10} md={10} sm={24} xs={24}>
              {main.address && (
                <Address address={main.address} email={main.email} />
              )}
              {main.contacts && (
                <ContentAuthorization
                  sectionId="shipyard-company_contacts"
                  permission={pagePermissions.company_contacts}
                >
                  <Contact contactList={main.contacts} />
                </ContentAuthorization>
              )}
            </Col>
          </Row>
        </div>
      </Auxiliary>
    </>
  );
};
export default Orderer;
