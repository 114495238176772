import { useQuery } from "react-query";
import request from "../../util/request";

const QUERY_KEYS = {
  SHIPYARD_MAIN: "shipyard-main",
  SHIPYARD_VESSELS: "shipyard-vessels",
  SHIPYARD_GEODATA: "shipyard-geodata",
  SHIPYARD_PERMISSIONS: "shipyard-permissions",
  SHIPYARD_ORDERS: "shipyard-orders",
};

const usePageQueries = ({ isAuthenticated }) => {
  const {
    data: ordersData,
    isLoading: ordersDataLoading,
    isError: ordersDataError,
  } = useQuery(
    QUERY_KEYS.SHIPYARD_ORDERS,
    async () => {
      const response = await request({
        url: `new-ships-api/${isAuthenticated ? "user" : "public"
          }/events/new-built`,
        method: "GET",
      });

      return response;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: vessels,
    isLoading: vesselsLoading,
    isError: vesselsError,
  } = useQuery(
    QUERY_KEYS.SHIPYARD_VESSELS,
    async () => {
      const response = await request({
        url: `new-ships-api/${isAuthenticated ? "user" : "public"
          }/events/new-built/vessels`,
        method: "GET",
      });
      return response;
    },
    { refetchOnMount: false, refetchOnWindowFocus: false }
  );

  const {
    data: shipyards,
  } = useQuery(
    QUERY_KEYS.SHIPYARD_GEODATA,
    async () => {
      const response = await request({
        url: `new-ships-api/${isAuthenticated ? "user" : "public"
          }/events/new-built/shipyards`,
        method: "GET",
      });
      return response;
    },
    { refetchOnMount: false, refetchOnWindowFocus: false }
  );

  const {
    data: permissions,
    isLoading: permissionsLoading,
    isError: permissionsError,
  } = useQuery(
    QUERY_KEYS.SHIPYARD_PERMISSIONS,
    async () => {
      const response = await request({
        url: `new-ships-api/${isAuthenticated ? "user" : "public"
          }/content-permissions`,
        method: "GET",
        params: {
          page: "tracker",
        },
      });

      return response;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: 30000000,
      refetchOnWindowFocus: false,
      refetch: false,
    }
  );

  return {
    permissions,
    permissionsLoading,
    permissionsError,
    vessels,
    vesselsLoading,
    vesselsError,
    shipyards,
    ordersData,
    ordersDataLoading,
    ordersDataError,
  };
};

export default usePageQueries;
