import React, { useEffect, useMemo, useState } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Card,
  Select,
  Slider,
  Checkbox,
  DatePicker,
} from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import moment from "moment";
import SearchWithAutoComplete from "../SearchWithAutoComplete";
import TwoStepSelect from "../TwoStepSelect";
import MultipleSelect from "../MultipleSelect";

const { Option } = Select;
const monthFormat = "YYYY/MM";

const { RangePicker } = DatePicker;

const fields = [
  {
    name: "shipyardObject",
    placeholder: "Shipyard",
    label: "Shipyard",
    key: "shipyard",
    type: "search-with-autocomplete",
    customProps: { types: ["SHIPYARD"] },
    extend: false,
  },
  {
    name: "ordererObject",
    placeholder: "Orderer",
    label: "Orderer",
    key: "orderer",
    type: "search-with-autocomplete",
    customProps: { types: ["SHIPMANAGER", "SHIPOWNER"] },
    extend: false,
  },
  {
    name: "length",
    placeholder: "Length",
    label: "Length",
    key: "length",
    type: "range",
    min: 10,
    max: 400,
    marks: { 10: "10", 100: "100", 200: "200", 300: "300", 400: "400" },
    extend: false,
  },
  {
    name: "width",
    placeholder: "Width",
    label: "Width",
    key: "width",
    type: "range",
    min: 10,
    max: 70,
    marks: { 10: "10", 30: "30", 50: "50", 70: "70" },
    extend: false,
  },
  {
    name: "draught",
    placeholder: "Draught",
    label: "Draught",
    key: "draught",
    type: "range",
    min: 1,
    max: 30,
    marks: { 1: "1", 10: "10", 20: "20", 30: "30" },
    extend: false,
  },
  {
    name: "vessel-type",
    placeholder: "All vessel types",
    label: "Vessel Type",
    key: "vessel-type",
    type: "select-vessel-type",
    extend: false,
  },
  {
    name: "delivery-date",
    placeholder: "Delivery Date",
    label: "Delivery Date",
    key: "delivery-date",
    type: "datepicker",
    extend: false,
  },
  {
    name: "order-date",
    placeholder: "Order Date",
    label: "Order Date",
    key: "order-date",
    type: "datepicker",
    extend: false,
  },
  {
    name: "shipyard-location",
    label: "Shipyard Location",
    key: "shipyard-location",
    type: "two-step-select",
    customProps: {
      stepOneLabel: "Shipyard Continent",
      stepTwoLabel: "Shipyard Country",
      stepOnePlaceholder: "Continent",
      stepTwoPlaceholder: "Country",
      stepOneName: "shipyard-continent",
      stepTwoName: "shipyard-country",
    },
    extend: false,
  },
  {
    name: "shipyard-location-exclude",
    label: "Shipyard Location exclude",
    key: "shipyard-location-exclude",
    type: "checkbox",
    extend: false,
  },
  {
    name: "orderer-location",
    label: "Orderer Location",
    key: "orderer-location",
    type: "two-step-select",
    customProps: {
      stepOneLabel: "Orderer Continent",
      stepTwoLabel: "Orderer Country",
      stepOnePlaceholder: "Continent",
      stepTwoPlaceholder: "Country",
      stepOneName: "orderer-continent",
      stepTwoName: "orderer-country",
    },
    extend: false,
  },
  {
    name: "orderer-location-exclude",
    label: "Orderer Location exclude",
    key: "orderer-location-exclude",
    type: "checkbox",
    extend: false,
  },
  {
    label: "Main Engine",
    type: "title",
    extend: true,
    key: "main-engine",
  },
  {
    name: "machinery-design",
    placeholder: "Machinery Design",
    label: "Machinery Design",
    key: "machinery-design",
    type: "selector",
    extend: true,
    data: [
      { "label": "MAN", "value": "man" },
      { "label": "Scania", "value": "scania" },
      { "label": "Yanmar", "value": "yanmar" },
      { "label": "Mitsubishi (MHI)", "value": "mitsubishi_mhi" },
      { "label": "WinGD", "value": "wingd" },
      { "label": "MTU", "value": "mtu" },
      { "label": "Hanshin", "value": "hanshin" },
      { "label": "Bergens", "value": "bergens" },
      { "label": "Guangzhou Diesel", "value": "guangzhou_diesel" },
      { "label": "Wärtsilä", "value": "wartsila" },
      { "label": "Caterpillar", "value": "caterpillar" },
      { "label": "Cummins", "value": "cummins" },
      { "label": "A.B.C.", "value": "a_b_c" },
      { "label": "Volvo Penta", "value": "volvo_penta" },
      { "label": "MAN B&W", "value": "man_b_w" },
      { "label": "Daihatsu", "value": "daihatsu" },
      { "label": "Chinese Standard", "value": "chinese_standard" },
      { "label": "General Electric (GE)", "value": "general_electric_ge" },
      { "label": "MaK", "value": "mak" },
      { "label": "Niigata", "value": "niigata" }
    ]
  },
  {
    name: "machinery-type",
    placeholder: "Machinery Type",
    label: "Machinery Type",
    key: "machinery-type",
    type: "text",
    extend: true,
  },
  {
    name: "machinery-power",
    placeholder: "Machinery Power",
    label: "Machinery Power (kW)",
    key: "machinery-power",
    type: "range",
    min: 1000,
    max: 80000,
    marks: {
      1000: "1000",
      20000: "20000",
      40000: "400000",
      60000: "60000",
      80000: "80000",
    },
    extend: true,
  },
  {
    name: "service-speed",
    placeholder: "Servicespeed",
    label: "Servicespeed (kn)",
    key: "service-speed",
    type: "range",
    min: 10,
    max: 20,
    marks: { 10: "10", 15: "15", 20: "20" },
    extend: true,
  },
  {
    name: "stroke-engine",
    placeholder: "Stroke Engine",
    label: "Stroke Engine",
    key: "stroke-engine",
    type: "selector",
    extend: true,
    data: [
      { value: 2, label: "2 Strokes" },
      { value: 4, label: "4 Strokes" }
    ]
  },
  {
    name: "type-of-drive-power",
    placeholder: "Type of Drive/Power",
    label: "Type of Drive/Power",
    key: "type-of-drive-power",
    type: "multi-select",
    extend: true,
    data: [
      "ammonia-ready",
      "battery-powered",
      "D-E prop.",
      "D-E hybrid",
      "D-E-propulsion/Azimuth",
      "dual-fuel",
      "electric-hybrid",
      "full electric",
      "hybrid",
      "hybrid battery",
      "KaMeWa Waterjets",
      "LNG",
      "LNG + battery",
      "LNG + Bio",
      "LNG + MGO",
      "LNG ready",
      "LNG-gas propulsion",
      "LPG",
      "Methanol",
      "methanol or LNG",
      "plug-in hybrid",
      "Schottel SRP 510 L FP",
      "Waterjets",
    ],
  },
  {
    label: "Additional",
    type: "title",
    key: "additional",
    extend: true,
  },
  {
    name: "ship-name",
    placeholder: "Ship Name",
    label: "Ship Name",
    key: "ship-name",
    type: "text",
    extend: true,
  },
  {
    name: "class",
    placeholder: "Classification Society",
    label: "Classification Society",
    key: "class",
    type: "selector",
    extend: true,
    data: [
      { label: "American Bureau of Shipping (ABS)", value: "american_bureau_of_shipping_abs" },
      { label: "Biro Klasifikasi Indonesia (BKI)", value: "biro_klasifikasi_indonesia_bki" },
      { label: "Bureau Veritas (BV)", value: "bureau_veritas_bv" },
      { label: "China Classification Society (CCS)", value: "china_classification_society_ccs" },
      { label: "Croatian Register of Shipping (CRS)", value: "croatian_register_of_shipping_crs" },
      { label: "DNV GL", value: "dnv_gl" },
      { label: "Hellenic Register of Shipping (HRS)", value: "hellenic_register_of_shipping_hrs" },
      { label: "Hrvatsaki Registar Brodova (CRS)", value: "hrvatsaki_registar_brodova_crs" },
      { label: "Indian Register of Shipping (IRS)", value: "indian_register_of_shipping_irs" },
      { label: "Korean Register of Shipping (KR)", value: "korean_register_of_shipping_kr" },
      { label: "Lloyd's Register of Shipping (LR)", value: "lloyd_s_register_of_shipping_lr" },
      { label: "Nippon Kaiji Kyökai (NK)", value: "nk" },
      { label: "Polski Rejestr Statkow (PRS)", value: "polski_rejestr_statkow_prs" },
      { label: "Registro Brasileiro de Navios (RBNA)", value: "registro_brasileiro_de_navios_rbna" },
      { label: "Registro Italiano Navale (RINA)", value: "registro_italiano_navale_rina" },
      { label: "Russian Maritime Register (RS)", value: "russian_maritime_register_rs" },
      { label: "Türk Loydu Vakfi (TL)", value: "turk_loydu_vakfi_tl" },
      { label: "Vietnam Register of Shipping (VIRES)", value: "vietnam_register_of_shipping_vires" }
    ]
  },
  {
    name: "ice-class",
    placeholder: "Iceclass",
    label: "Iceclass",
    key: "ice-class",
    type: "text",
    extend: true,
  },
  {
    name: "hull-no",
    placeholder: "Hull NO.",
    label: "Hull No.",
    key: "hull-no",
    type: "text",
    extend: true,
  },
  {
    name: "imo-no",
    placeholder: "IMO NO.",
    label: "IMO No.",
    key: "imo-no",
    type: "text",
    extend: true,
  },
  {
    label: "Tonnage & Capacity",
    type: "title",
    extend: true,
    key: "tunnage-n-capacity",
  },
  {
    name: "gt",
    placeholder: "GT",
    label: "Gross Tonnage",
    key: "gt",
    type: "range",
    min: 1000,
    max: 250000,
    marks: { 1000: "1000", 100000: "100000", 250000: "250000" },
    extend: true,
  },
  {
    name: "dwt",
    placeholder: "DWT",
    label: "Deadweight",
    key: "dwt",
    type: "range",
    min: 1000,
    max: 250000,
    marks: { 1000: "1000", 100000: "100000", 250000: "250000" },
    extend: true,
  },
  {
    name: "teu",
    placeholder: "teu",
    label: "TEU",
    key: "teu",
    type: "range",
    min: 100,
    max: 25000,
    marks: { 100: "100", 10000: "10000", 25000: "25000" },
    extend: true,
  },
  {
    name: "reeferplugs",
    placeholder: "Reeferplugs",
    label: "Reeferplugs",
    key: "reeferplugs",
    type: "range",
    min: 10,
    max: 2000,
    marks: { 10: "10", 500: "500", 1000: "1000", 2000: "2000" },
    extend: true,
  },
  {
    name: "capacity-grain",
    placeholder: "Capacity grain",
    label: "Capacity grain (cbm)",
    key: "capacity-grain",
    type: "range",
    min: 1000,
    max: 250000,
    marks: { 1000: "1000", 100000: "100000", 250000: "250000" },
    extend: true,
  },
  {
    name: "capacity-bale",
    placeholder: "Capacity bale",
    label: "Capacity bale (cbm)",
    key: "capacity-bale",
    type: "range",
    min: 1000,
    max: 250000,
    marks: { 1000: "1000", 100000: "100000", 250000: "250000" },
    extend: true,
  },
  {
    name: "capacity-liquid",
    placeholder: "Capacity liquid",
    label: "Capacity liquid (cbm)",
    key: "capacity-liquid",
    type: "range",
    min: 1000,
    max: 250000,
    marks: { 1000: "1000", 100000: "100000", 250000: "250000" },
    extend: true,
  },
  {
    name: "passenger",
    placeholder: "Passenger",
    label: "Passenger",
    key: "passenger",
    type: "range",
    min: 10,
    max: 2000,
    marks: { 10: "10", 500: "500", 1000: "1000", 2000: "2000" },
    extend: true,
  },
  {
    name: "cars",
    placeholder: "Cars",
    label: "Cars",
    key: "cars",
    type: "range",
    min: 10,
    max: 10000,
    marks: { 10: "10", 2000: "2000", 5000: "5000", 10000: "10000" },
    extend: true,
  },
  {
    name: "trailer",
    placeholder: "Trailer",
    label: "Trailer",
    key: "trainer",
    type: "range",
    min: 10,
    max: 200,
    marks: { 10: "10", 100: "100", 200: "200" },
    extend: true,
  },
  {
    name: "lane-meter",
    placeholder: "Lane meter",
    label: "Lane meter",
    key: "lane-meter",
    type: "range",
    min: 10,
    max: 500,
    marks: { 10: "10", 100: "100", 200: "200", 300: "300", 500: "500" },
    extend: true,
  },
  {
    name: "bollard-pull",
    placeholder: "Bollard Pull",
    label: "Bollard Pull",
    key: "bollard-pull",
    type: "range",
    min: 10,
    max: 100,
    marks: { 10: "10", 100: "100" },
    extend: true,
  },
  {
    label: "New Ships Orderbook",
    type: "title",
    extend: true,
    key: "new-ships-orderbook",
  },
];

const AdvancedSearchForm = ({
  vesselTypes,
  filters,
  handleSubmit,
  continentsAndCountries,
  setTableVariables
}) => {
  const [expand, setExpand] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      ...filters,
      "lane-meter": filters["laneMeter"] ? filters["laneMeter"] : [10, 500],
      "bollard-pull": filters["bollardPull"] ? filters["bollardPull"] : [10, 100],
      "passenger": filters["cargoPassenger"] ? filters["cargoPassenger"] : [10, 2000],
      "capacity-grain": filters["capacityGrain"] ? filters["capacityGrain"] : [1000, 250000],
      "capacity-liquid": filters["capacityLiquid"] ? filters["capacityLiquid"] : [1000, 250000],
      "capacity-bale": filters["capacityBale"] ? filters["capacityBale"] : [1000, 250000],
      "imo-no": filters["imoNo"] ? filters["imoNo"] : "",
      "hull-no": filters["hullNo"] ? filters["hullNo"] : "",
      "ice-class": filters["iceClass"] ? filters["iceClass"] : "",
      "class": filters["classificationSociety"] ? filters["classificationSociety"] : "",
      "machinery-power": filters["machineryPower"] ? filters["machineryPower"] : [1000, 80000],
      "service-speed": filters["serviceSpeed"] ? filters["serviceSpeed"] : [1, 20],
      "ship-name": filters["shipName"] ? filters["shipName"] : "",
      "stroke-engine": filters["strokeEngine"] ? filters["strokeEngine"] : "",
      "type-of-drive-power": filters["typeOfDrive"] ? filters["typeOfDrive"] : [],
      "vessel-type": filters["vesselType"] ? filters["vesselType"] : "",
      "machinery-design": filters["machineryDesign"] ? filters["machineryDesign"] : "",
      "machinery-type": filters["machineryType"] ? filters["machineryType"] : "",
      "order-status": filters["orderStatus"] ? filters["orderStatus"] : ["ON_ORDER", "Option", "LOI", "Rumours", "DELIVER_EXPECTED"],
      "shipyard-continent": filters["shipyardContinent"] ? filters["shipyardContinent"] : "All Continents",
      "shipyard-country": filters["shipyardCountry"] ? filters["shipyardCountry"] : "",
      "orderer-continent": filters["ordererContinent"] ? filters["ordererContinent"] : "All Continents",
      "orderer-country": filters["ordererCountry"] ? filters["ordererCountry"] : "",
      "delivery-date": [filters["deliveryDateStart"] ? moment(filters["deliveryDateStart"] * 1000) : null, filters["deliveryDateEnd"] ? moment(filters["deliveryDateEnd"] * 1000) : null],
      "order-date": [filters["orderDateStart"] ? moment(filters["orderDateStart"] * 1000) : null, filters["orderDateEnd"] ? moment(filters["orderDateEnd"] * 1000) : null],
      "shipyard-location-exclude": filters["shipyardLocationExclude"] ? true : false,
      "orderer-location-exclude": filters["ordererLocationExclude"] ? true : false,
    })
  }, [filters])

  let [customFieldObserver] = useState({});

  const formattedContinentsAndCountries = useMemo(() => {
    if (!continentsAndCountries) return;
    const formatted = { "All Continents": [] };
    let allCountries = []

    Object.keys(continentsAndCountries).forEach((continent) => {
      let fullContinent = "Europe";
      if (continent === "AS") fullContinent = "Asia";
      if (continent === "NA") fullContinent = "North America";
      if (continent === "OC") fullContinent = "Oceania";
      if (continent === "SA") fullContinent = "South America";
      if (continent === "AF") fullContinent = "Africa";

      formatted[fullContinent] = continentsAndCountries[continent].map(
        (country) => {
          return {
            value: country.a3,
            label: country.name,
            id: country.id,
          };
        }
      );
      allCountries.push(...formatted[fullContinent])
    });
    formatted["All Continents"] = allCountries.sort((a, b) => a.label.localeCompare(b.label))
    return formatted;
  }, [continentsAndCountries]);

  const shipyardOnSelect = (id) => {
    form.setFieldsValue({
      shipyardObject: id,
    });
  };

  const ordererOnSelect = (id) => {
    form.setFieldsValue({
      ordererObject: id,
    });
  };

  const generateFields = (extend) => {
    return fields
      .filter((f) => f.extend === extend)
      .map(
        (
          { key, label, placeholder, name, type, min, max, marks, customProps, data },
          index
        ) => {
          switch (type) {
            default:
            case "checkbox":
              return (
                <Col lg={12} md={24} sm={24} xs={24} key={key}>
                  <Form.Item
                    name={name}
                    label={label}
                    valuePropName="checked"
                  >
                    <Checkbox name={name}></Checkbox>
                  </Form.Item>
                </Col>
              );
            case "text":
              return (
                <Col lg={12} md={24} sm={24} xs={24} key={key}>
                  <Form.Item
                    name={name}
                    label={label}
                    rules={[
                      {
                        required: false,
                        message: "Input something!",
                      },
                    ]}
                  >
                    <Input placeholder={placeholder} />
                  </Form.Item>
                </Col>
              );
            case "range":
              return (
                <Col lg={12} md={24} sm={24} xs={24} key={key}>
                  <Form.Item
                    name={name}
                    label={label}
                    rules={[
                      {
                        required: false,
                        message: "Input something!",
                      },
                    ]}
                  >
                    <Slider range min={min} max={max} step={5} marks={marks} />
                  </Form.Item>
                </Col>
              );
            case "select-vessel-type":
              return (
                <Col lg={12} md={24} sm={24} xs={24} key={key}>
                  <Form.Item
                    name={name}
                    label={label}
                    rules={[
                      {
                        required: false,
                        message: "Input something!",
                      },
                    ]}
                  >
                    <Select
                      placeholder={placeholder}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option>All Vessel Types</Option>
                      {vesselTypes.map((t) => (
                        <Option value={t.id} key={`vessel_type_${t.id}`}>
                          {t.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              );
            case "datepicker":
              return (
                <Col lg={12} md={24} sm={24} xs={24} key={key}>
                  <Form.Item
                    name={name}
                    label={label}
                    rules={[
                      {
                        required: false,
                        message: "Input something!",
                      },
                    ]}
                  >
                    <RangePicker
                      allowEmpty={[true, true]}
                      picker="month"
                      className="gx-mb-3 gx-w-100"
                      format={monthFormat}
                    />
                  </Form.Item>
                </Col>
              );
            case "search-with-autocomplete":
              return (
                <Col lg={12} md={24} sm={24} xs={24} key={key}>
                  <Form.Item name={name} label={label}>
                    <SearchWithAutoComplete
                      onSelect={
                        key === "shipyard" ? shipyardOnSelect : ordererOnSelect
                      }
                      placeholder={placeholder}
                      {...customProps}
                      initValue={{ name: filters[key + "Name"], value: filters[key] }}
                    />
                  </Form.Item>
                </Col>
              );
            case "multi-select":
              return (
                <Col lg={12} md={24} sm={24} xs={24} key={key}>
                  <MultipleSelect name={name} label={label} placeholder={placeholder} data={data} />
                </Col>
              );
            case "selector":
              return (
                <Col lg={12} md={24} sm={24} xs={24} key={key}>
                  <Form.Item
                    name={name}
                    label={label}
                  >
                    <Select
                      placeholder={placeholder}
                    >
                      {data.map((d, index) => (
                        <Option value={d.value} key={`${key}_${index}`} >
                          {d.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              );
            case "placeholder":
              return (
                <Col
                  lg={12}
                  md={24}
                  sm={24}
                  xs={24}
                  key={`${type}${index}`}
                ></Col>
              );
            case "two-step-select":
              return (
                <Col lg={24} md={24} sm={24} xs={24} key={key}>
                  <Row>
                    <TwoStepSelect
                      index={key}
                      data={formattedContinentsAndCountries}
                      {...customProps}
                      setFieldsValue={form.setFieldsValue}
                      clearObserver={customFieldObserver}
                    />
                  </Row>
                </Col>
              );
            case "title":
              return (
                <Col lg={24} md={24} sm={24} xs={24} key={key}>
                  <span
                    style={{
                      fontSize: "0.9rem",
                      fontWeight: 600,
                      marginBottom: "2rem",
                      display: "block",
                    }}
                    key={key}
                  >
                    {label}
                  </span>
                </Col>
              );
            case "select-machinery-design":
              return (
                <Col lg={12} md={24} sm={24} xs={24} key={key}>
                  <Form.Item
                    name={name}
                    label={label}
                    rules={[
                      {
                        required: false,
                        message: "Input something!",
                      },
                    ]}
                  >
                    <Select
                      placeholder={placeholder}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {vesselTypes.map((type) => (
                        <Option value={type} key={type}>
                          {type}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              );
            case "select-machinery-type":
              return (
                <Col lg={12} md={24} sm={24} xs={24} key={key}>
                  <Form.Item
                    name={name}
                    label={label}
                    rules={[
                      {
                        required: false,
                        message: "Input something!",
                      },
                    ]}
                  >
                    <Select
                      placeholder={placeholder}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {vesselTypes.map((type) => (
                        <Option value={type} key={type}>
                          {type}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              );
            case "select-iceclass":
              return (
                <Col lg={12} md={24} sm={24} xs={24} key={key}>
                  <Form.Item
                    name={name}
                    label={label}
                    rules={[
                      {
                        required: false,
                        message: "Input something!",
                      },
                    ]}
                  >
                    <Select
                      placeholder={placeholder}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {vesselTypes.map((type) => (
                        <Option value={type} key={type}>
                          {type}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              );
          }
        }
      );
  };

  const formItemLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const options = [
    { label: "On Order", value: "ON_ORDER" },
    { label: "Option", value: "Option" },
    { label: "LOI", value: "LOI" },
    { label: "Rumours", value: "Rumours" },
    { label: "Deliver expected", value: "DELIVER_EXPECTED" },
    { label: "Delivered", value: "Delivered" },
    { label: "Cancelled", value: "Cancelled" },
  ];

  return (
    <Card className="gx-card" title="Advanced Search">
      <Form
        {...formItemLayout}
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
        onFinish={handleSubmit}
        initialValues={{
          "order-status": ["ON_ORDER", "Option", "LOI", "Rumours", "DELIVER_EXPECTED"],
          "order-date": moment(),
          "delivery-date": moment(),
          length: [10, 400],
          width: [10, 70],
          draught: [1, 30],
          "machinery-power": [1000, 80000],
          "service-speed": [1, 20],
          gt: [1000, 250000],
          dwt: [1000, 250000],
          teu: [100, 25000],
          reeferplugs: [10, 2000],
          "capacity-grain": [1000, 250000],
          "capacity-liquid": [1000, 250000],
          "capacity-bale": [1000, 250000],
          passenger: [10, 2000],
          cars: [10, 10000],
          trailer: [10, 200],
          "lane-meter": [10, 500],
          "bollard-pull": [10, 100],
          "shipyard": null,
          "shipyardName": null
        }}
      >
        <Row gutter={24}>{generateFields(false)}</Row>
        {expand && <Row gutter={24}>{generateFields(true)}</Row>}
        <Row gutter={24}></Row>

        <Row>
          <Col  md={12} >
            <Form.Item name="order-status" label="Order Status">
              <Checkbox.Group options={options} />
            </Form.Item>
          </Col>
          <Col md={12}  style={{ textAlign: "right" }}>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
            <Button
              onClick={() => {
                form.resetFields();
                setTableVariables({
                  pagination: { current: 1, pageSize: 20 },
                  filters: {},
                  sort: {
                    column: { title: "Order Date", dataIndex: "orderDate", key: "orderDate" },
                    order: "descend",
                    field: "orderDate",
                    columnKey: "orderDate",
                  }
                })
                Object.values(customFieldObserver).forEach((clear) => clear());
              }}
            >
              Clear
            </Button>
            <a
              href="#/"
              style={{
                display: "inline-block",
                verticalAlign: "middle",
                marginBottom: 15,
              }}
              onClick={() => {
                setExpand(!expand);
              }}
            >
              {expand ? <UpOutlined /> : <DownOutlined />} Advanced Search
            </a>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default AdvancedSearchForm;
