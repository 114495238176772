import _ from 'lodash'

export const engineBuilders = {
  keys : ["555", "505", "547", "558", "556", "563", "553", "565", "516", "536", "518", "559", "564", "504", "538", "531", "562", "560", "523", "528", "507", "522", "548", "541", "566", "520", "549", "561", "544", "557", "525", "527", "499", "535", "539", "534", "501", "542", "506", "543", "529", "526", "519", "545", "502", "503", "550", "524", "532", "515", "533", "537", "510", "551", "521", "540", "567", "514", "513", "530", "512", "509", "508", "546", "552", "511", "554"], 
  values : {
    "499":"JANMAR",
    "502":"MTU",
    "503":"MWM",
    "504":"DEUTZ",
    "505":"ABB",
    "506":"MAN",
    "508":"WUXI",
    "510":"SSANGYONG",
    "511":"ZHENJIANG MARINE",
    "512":"VOLVO",
    "513":"VALMET",
    "514":"ULSTEIN",
    "515":"SCANIA",
    "516":"CUMMINS",
    "518":"DAIHATSU",
    "519":"MITSUBISHI",
    "520":"HIMSEN",
    "521":"SULZER",
    "522":"GUASCOR",
    "523":"FORD",
    "524":"NISHISHIBA",
    "525":"ISUZU",
    "526":"MIRRLEES",
    "527":"IVECO",
    "528":"GARDNER",
    "529":"MERCEDES",
    "530":"VALMET",
    "531":"DGFA",
    "532":"PERKINS",
    "533":"SISU",
    "534":"LISTER",
    "535":"JOHN DEERE",
    "536":"DAF",
    "537":"SKL",
    "538":"DGA",
    "539":"KAWASAKI",
    "540":"TAIYO",
    "541":"HATZ",
    "507":"GENERAL MOTOR",
    "542":"MAKITA",
    "543":"MANISES DIESEL",
    "544":"HYUNDAI",
    "545":"MITSUI",
    "546":"YANMAR",
    "547":"AKASAKA",
    "548":"HANSHIN",
    "549":"HITACHI",
    "550":"NIIGATA",
    "551":"STX",
    "552":"YICHANG",
    "553":"BRODOSPLIT",
    "554":"ZIBO DIESEL",
    "555":"3. MAJ",
    "556":"ASTILLEROS",
    "557":"ISHIKAWAJIMA",
    "558":"ANGLO BELGIAN",
    "559":"DALIAN MARINE",
    "560":"DOOSAN",
    "561":"HUDONG HEAVY MACHINERY",
    "562":"DIESEL UNITED",
    "563":"BERGEN",
    "564":"DETROIT DIESEL",
    "565":"CSSC",
    "566":"HHI",
    "567":"ULJANIK",
    "509":"WÄRTSILÄ",
    "501":"MAK/CATERPILLAR",
  }
}


export const maritimeCountries = {
  keys :["16", "33", "41", "47", "61", "76", "1", "85", "100", "101", "106", "108", "130", "243", "151", "161", "172", "178", "193", "114", "200", "206", "219", "224", "225", "226", "227"], 
  values: {
    "16": "Australia",
    "33": "Brazil",
    "41": "Canada",
    "47": "China",
    "61": "Denmark",
    "76": "France",
    "1": "Germany",
    "85": "Greece",
    "100": "India",
    "101": "Indonesia",
    "106": "Italy",
    "108": "Japan",
    "130": "Malaysia",
    "243": "Montenegro",
    "151": "Netherlands",
    "161": "Norway",
    "172": "Poland",
    "178": "Russian Federation",
    "193": "Singapore",
    "114": "South Korea",
    "200": "Spain",
    "206": "Sweden",
    "219": "Turkey",
    "224": "Ukraine",
    "225": "United Arab Emirates",
    "226": "United Kingdom",
    "227": "United States",
  }
}

export const colors = {
  "general_cargo_ship": "#008000",
  "specialized_cargo_ship": "#32CD32",
  "container_ship": "#666666",
  "ro_ro_cargo_ship": "#90EE90",
  "bulk_carrier": "#A52A2A",
  "oil_and_chemical_tanker": "#00008B",
  "gas_tanker": "#880096",
  "other_tanker": "#FA8072",
  "passenger_ship": "#EE82EE",
  "offshore_vessel": "#FFFF00",
  "service_ship": "#6495ED",
  "tug": "#ADD8E6",
  "fishing_vessel": "#40E0D0",
  "pleasure_craft": "#FFC0CB",
  "other": "#DCDCDC",
  "": "#DCDCDC",
  "90": "#FF0000",
  "180": "#FFFF00",
  "360": "#009688"
}

export const vesselTypeImage = {
  "general_cargo_ship": "cargo",
  "specialized_cargo_ship": "cargo",
  "container_ship": "container",
  "ro_ro_cargo_ship": "cargo",
  "bulk_carrier": "bulk",
  "oil_and_chemical_tanker": "tanker",
  "gas_tanker": "tanker",
  "other_tanker": "tanker",
  "passenger_ship": "other",
  "offshore_vessel": "other",
  "service_ship": "other",
  "tug": "other",
  "fishing_vessel": "other",
  "pleasure_craft": "other",
  "other": "other",
  "": "other",
}

export const allVesselTypes = ["general_cargo_ship", "specialized_cargo_ship", "container_ship", "ro_ro_cargo_ship", "bulk_carrier", "oil_and_chemical_tanker", "gas_tanker", "other_tanker", "passenger_ship", "offshore_vessel", "service_ship", "tug", "fishing_vessel", "pleasure_craft", "other"]

export const allSailingareas = {
  keys :["1319", "1309", "1313", "1331", "1336", "1318", "1310", "1332", "1312", "1329", "1330", "1316", "1321", "1328", "1326", "1315", "1314", "1337", "1322", "1320", "1327", "1317", "1325", "1324", "1311"],
  values : {
    "1319": "Australia/Oceania",
    "1309": "Baltic Sea",
    "1313": "Black Sea",
    "1331": "Caribbean Sea",
    "1336": "Caspian Sea",
    "1318": "East Africa",
    "1332": "East Coast South America",
    "1312": "East Mediterranean",
    "1329": "Great Lakes",
    "1330": "Gulf",
    "1316": "Indian Ocean",
    "1321": "North Asia (Japan, Korea)",
    "1328": "North America East Coast",
    "1324": "North America West Coast",
    "1310": "North Sea / Atlantic",
    "1326": "North Pacific",
    "1315": "Persian Gulf",
    "1314": "Red Sea",
    "1337": "South Africa",
    "1322": "South Asia",
    "1320": "South East Asia",
    "1327": "South Pacific Ocean",
    "1317": "West Africa",
    "1325": "West Coast South America & Central America",
    "1311": "West Mediterranean Sea",
  }
}

export const allContinents = {
  keys :["EU", "AF", "NA", "SA", "AS", "OC"],
  values : {
    "EU":"Europe",
    "AF":"Africa",
    "NA":"North America",
    "SA":"South America",
    "AS":"Asia",
    "OC":"Oceania",
  }
}

export const allWeekdays = {
  keys :["1", "2", "3", "4", "5", "6", "7"],
  values : {
    "1": "Sunday",
    "2": "Monday",
    "3": "Tuesday",
    "4": "Wednesday",
    "5": "Thursday",
    "6": "Friday",
    "7": "Saturday",
  }
}

export const nextSurveyMarks = {
  marks: [
    {
      value: 0,
      label: '< 3 months',
    },
    {
      value: 1,
      label: '< 6 months',
    },
    {
      value: 2,
      label: '< 1 year',
    },
    {
      value: 3,
      label: 'all vessels',
    },
  ],
  valueToMark: {
    "3": 0,
    "6": 1,
    "12": 2,
    "": 3,
  }
}

export const lastDockingMarks = {
  marks: [
    {
      value: 0,
      label: '< 6 M',
    },
    {
      value: 1,
      label: '< 1 Y',
    },
    {
      value: 2,
      label: '< 2 Y',
    },
    {
      value: 3,
      label: '< 3 Y',
    },
    {
      value: 4,
      label: '< 4 Y',
    },
    {
      value: 5,
      label: '< 5 Y',
    },
    {
      value: 6,
      label: 'all vessels',
    },
  ],
  valueToMark: {
    "6": 0,
    "12": 1,
    "24": 2,
    "36": 3,
    "48": 4,
    "60": 5,
    "": 6,
  }
};

export const ageMarks = {
  marks: [
    {
      value: 0,
      label: 'new',
    },
    {
      value: 1,
      label: '5 years',
    },
    {
      value: 2,
      label: '10 years',
    },
    {
      value: 3,
      label: '15 years',
    },
    {
      value: 4,
      label: '20 years',
    },
    {
      value: 5,
      label: '> 20 years',
    },
  ],
  minValueToMark: {
    "0": 0,
    "5": 1,
    "10": 2,
    "15": 3,
    "20": 4,
    "": 5,
  },
  maxValueToMark: {
    "1": 0,
    "5": 1,
    "10": 2,
    "15": 3,
    "20": 4,
    "": 5,
  }
};

export const getVesselColorByType = (vesselType) => {
  let mainType = "";
  if (vesselType.indexOf('/') >= 0) {
    mainType = vesselType.substring(0, vesselType.indexOf('/'));
  } else {
    mainType = vesselType;
  }
  return colors[mainType];
}

const scale = {
  HUGE: 0.8,
  LARGE: 0.8,
  MEDIUM: 0.6,
  SMALL: 0.4,
  MINI: 0.4,
  "":0.4,
}

export const getVesselScale = (vesselSize) => {
  return scale[vesselSize];
}

export const getVesselPopupContent = (properties) => {
  let html =""
  if (properties.type === 'vessel') {
    html = '<table class="table table-default nowrap"><tbody>'
    if (properties.fleet_name) {
      html += `<tr><td>Fleet</td><td>${properties.fleet_name}</td></tr>`
    }
    html += `<tr><td>Name</td><td>${properties.name}</td></tr>`
    if (properties.td_status) {
      html += `<tr><td>Status</td><td>
      <span style="border-radius: 2px; background-color: rgb(6, 187, 138); color: #fff; padding-left: 7px; padding-right: 7px;">${properties.td_status}</span>
      </td></tr>`;
    }
    if (properties.imo != "null"){ //eslint-disable-line eqeqeq
      html += `<tr><td>IMO</td><td>${properties.imo}</td></tr>`
    }
    if (properties.length && properties.length !== 'null' && properties.width && properties.width !== 'null') {
      html += `<tr><td>Length x Width</td><td>${properties.length}m x ${properties.width}m</td></tr>`
    }
    if (properties.vessel_type && properties.vessel_type !== 'null') {
      html += `<tr><td>Type</td><td>${_.startCase(_.toLower(properties.vessel_type))}</td></tr>`
    }
    if (properties.scrubber_installed !== "null"){ //eslint-disable-line eqeqeq
      html += `<tr><td>Scrubber</td><td>${properties.scrubber_installed?"Installed":"NOT Installed"}</td></tr>`
    }
    if (properties.eta && properties.eta !== 'null' && properties.destination && properties.destination !== 'null') {
      html += `<tr><td></td><td></td></tr>`
      html += `<tr><td>ETA</td><td>${properties.eta}</td></tr>`
      html += `<tr><td>Destination</td><td>${properties.destination.replace(/[@"]/g,"")}</td></tr>`
    }
    html += `<tr><td>Updated At</td><td>${properties.positiontimestamp}</td></tr>`
    if (properties.next_survey && properties.next_survey !== 'null') {
      html += `<tr><td></td><td></td></tr>`
      html += `<tr><td>Class Expires</td><td>${properties.next_survey}</td></tr>`
    }
    html += '</tbody></table>'
  } else if (properties.type === 'shipyard'){
    html = '<table class="table table-default nowrap"><tbody>'
    if (properties.shipyard_type === 'new_build' ){
      html += `<tr><td>New building yard</td><td>${properties.name}</td></tr>`
    }else if (properties.shipyard_type === 'repair'){
      html += `<tr><td>Repair yard</td><td>${properties.name}</td></tr>`
    }

    html += '</tbody></table>'
  } else if (properties.type === 'underwater'){
    html = '<table class="table table-default nowrap"><tbody>'
    html += `<tr><td>Underwater Service</td><td>${properties.name}</td></tr>`
    html += '</tbody></table>'
  }
  return html;
}

export const getShipyardPopupContent = (properties) => {
  let html = "";
  html = '<table class="table table-default nowrap"><tbody>';
  if (properties.name) {
    html += `<tr><td></td><td>${properties.name}</td></tr>`;
  }
  html += '</tbody></table>'
  return html
};


export const drawVessel = (context, rawVesselType, nextSurveyDays, moving) => {

  let vesselType = rawVesselType;
  if (rawVesselType.indexOf('/') >= 0) {
    vesselType = rawVesselType.substring(0, rawVesselType.indexOf('/'));
  } else {
    vesselType = rawVesselType;
  }

  let p = new Path2D("m 6.4101838,17.990374 c -1.283031,0 -3.9845684,4.053097 -3.9845684,5.760349 l 0,47.726349 c 0,1.707253 1.0413006,3.092412 2.3243316,3.092417 l 4.6486631,0 c 1.2830309,0 2.3243319,-1.385164 2.3243319,-3.092417 l 0,-47.726349 c 0,-1.707252 -2.7015371,-5.760349 -3.9845688,-5.760349 z m 0,7.18465 1.3281894,0 c 1.0997409,0 3.3204738,2.86671 3.3204738,4.490406 l 0,40.599584 c 0,1.623731 -0.892544,2.943322 -1.9922843,2.94332 l -3.9845683,0 c -1.0997409,0 -1.992284,-1.319589 -1.9922843,-2.94332 l 0,-40.599584 c 0,-1.623696 2.2207328,-4.490406 3.3204737,-4.490406 z");
  context.stroke(p);
  context.fill(p);

  if (vesselTypeImage[vesselType]==='bulk') {
    p = new Path2D("m 3.0001741,29.365482 8.2496819,0 0,12.73186 -8.2496819,0 z");
    context.stroke(p);
    context.fill(p);

    p = new Path2D("m 2.9952582,43.838731 8.2496818,0 0,12.73186 -8.2496818,0 z");
    context.stroke(p);
    context.fill(p);

    p = new Path2D("m 2.9952583,58.15237 8.2496817,0 0,12.73186 -8.2496817,0 z");
    context.stroke(p);
    context.fill(p);
  } else if (vesselTypeImage[vesselType]==='tanker') {
    p = new Path2D("M 10.955519,35.990112 A 3.8714991,3.8714991 0 0 1 7.0840197,39.861611 3.8714991,3.8714991 0 0 1 3.2125206,35.990112 3.8714991,3.8714991 0 0 1 7.0840197,32.118613 3.8714991,3.8714991 0 0 1 10.955519,35.990112 Z");
    context.stroke(p);
    context.fill(p);

    p = new Path2D("M 10.873147,45.298187 A 3.8714991,3.8714991 0 0 1 7.0016475,49.169686 3.8714991,3.8714991 0 0 1 3.1301484,45.298187 3.8714991,3.8714991 0 0 1 7.0016475,41.426688 3.8714991,3.8714991 0 0 1 10.873147,45.298187 Z");
    context.stroke(p);
    context.fill(p);

    p = new Path2D("M 10.955519,55.594727 A 3.8714991,3.8714991 0 0 1 7.0840197,59.466226 3.8714991,3.8714991 0 0 1 3.2125206,55.594727 3.8714991,3.8714991 0 0 1 7.0840197,51.723228 3.8714991,3.8714991 0 0 1 10.955519,55.594727 Z");
    context.stroke(p);
    context.fill(p);

    p = new Path2D("m 10.955519,65.561775 a 3.8714991,3.8714991 0 0 1 -3.8714989,3.871499 3.8714991,3.8714991 0 0 1 -3.871499,-3.871499 3.8714991,3.8714991 0 0 1 3.871499,-3.871499 3.8714991,3.8714991 0 0 1 3.8714989,3.871499 z");
    context.stroke(p);
    context.fill(p);
  }  else if (vesselTypeImage[vesselType]==='container') {
    p = new Path2D("m 3.0049505,30.339933 8.0750825,0 0,2.152641 -8.0750825,0 z");
    context.stroke(p);
    context.fill(p);

    p = new Path2D("m 2.9918554,34.237587 8.0750826,0 0,2.152641 -8.0750826,0 z");
    context.stroke(p);
    context.fill(p);

    p = new Path2D("m 2.9756603,38.069298 8.0750827,0 0,2.152641 -8.0750827,0 z");
    context.stroke(p);
    context.fill(p);

    p = new Path2D("m 3.0581684,41.833817 8.0750826,0 0,2.15264 -8.0750826,0 z");
    context.stroke(p);
    context.fill(p);

    p = new Path2D("m 2.9756603,45.730965 8.0750827,0 0,2.15264 -8.0750827,0 z");
    context.stroke(p);
    context.fill(p);

    p = new Path2D("m 2.9918554,49.47929 8.0750826,0 0,2.152641 -8.0750826,0 z");
    context.stroke(p);
    context.fill(p);

    p = new Path2D("m 2.9756603,53.725727 8.0750827,0 0,2.152641 -8.0750827,0 z");
    context.stroke(p);
    context.fill(p);

    p = new Path2D("m 3.0331092,57.915592 8.0750828,0 0,2.152641 -8.0750828,0 z");
    context.stroke(p);
    context.fill(p);

    p = new Path2D("m 3.0832272,62.077995 8.0750828,0 0,2.152641 -8.0750828,0 z");
    context.stroke(p);
    context.fill(p);

    p = new Path2D("m 3.0331092,66.224205 8.0750828,0 0,2.152641 -8.0750828,0 z");
    context.stroke(p);
    context.fill(p);

  } else if (vesselTypeImage[vesselType]==='cargo') {
    p = new Path2D("m 3.0001741,29.365482 8.2496819,0 0,12.73186 -8.2496819,0 z");
    context.stroke(p);
    context.fill(p);

    p = new Path2D("m 2.9918554,49.47929 8.0750826,0 0,2.152641 -8.0750826,0 z");
    context.stroke(p);
    context.fill(p);

    p = new Path2D("m 2.9756603,53.725727 8.0750827,0 0,2.152641 -8.0750827,0 z");
    context.stroke(p);
    context.fill(p);

    p = new Path2D("m 3.0331092,57.915592 8.0750828,0 0,2.152641 -8.0750828,0 z");
    context.stroke(p);
    context.fill(p);

    p = new Path2D("m 3.0832272,62.077995 8.0750828,0 0,2.152641 -8.0750828,0 z");
    context.stroke(p);
    context.fill(p);

    p = new Path2D("m 3.0331092,66.224205 8.0750828,0 0,2.152641 -8.0750828,0 z");
    context.stroke(p);
    context.fill(p);
  } else {
    p = new Path2D("m 6.5747459,29.583194 1.0791666,0 c 0.8935501,0 2.6979165,2.462653 2.6979165,3.857491 l 0,34.877153 c 0,1.394869 -0.7251998,2.528466 -1.6187498,2.528465 l -3.2375,0 c -0.8935499,0 -1.6187496,-1.133596 -1.61875,-2.528465 l 0,-34.877153 c 0,-1.394839 1.8043667,-3.857491 2.6979167,-3.857491 z");
    context.stroke(p);
    context.fill(p);
  }

  if (moving==='false') {
    p = new Path2D("m 6.984375,-2 c -2.355545,1.679e-4 -4.6993353,0.9519142 -6.375,2.59765625 -1.6756647,1.64574205 -2.6599507,3.97047815 -2.6601562,6.31249995 -4.718e-4,2.3424454 0.9844236,4.6681768 2.6601562,6.3144528 1.6757326,1.646277 4.019029,2.597489 6.375,2.597657 2.3563894,4.77e-4 4.700692,-0.95132 6.376953,-2.597657 1.676261,-1.646336 2.660628,-3.9715913 2.660156,-6.3144528 C 16.021279,4.5677183 15.037521,2.2434582 13.361328,0.59765625 11.685135,-1.0481457 9.3403384,-2.0004776 6.984375,-2 Z M 6.2617188,3.2050781 C 6.826702,3.1091884 7.4106654,3.143357 7.9707031,3.3164062 8.5291546,3.4889699 9.0670919,3.7974814 9.5605469,4.2832031 9.8863538,4.603906 10.134345,4.9368216 10.3125,5.2714844 c 0.179856,0.3378595 0.2928,0.6800226 0.351562,1.0351562 0.05847,0.3533537 0.06137,0.7222294 0,1.1015625 C 10.602919,7.7861651 10.477227,8.1810653 10.271484,8.59375 10.112394,8.9128573 9.8779844,9.2232651 9.5800781,9.5 9.2822403,9.776672 8.9236693,10.017839 8.5292969,10.201172 8.1367228,10.38367 7.7084274,10.507608 7.2910156,10.560547 6.8714899,10.613752 6.4663141,10.597461 6.0917969,10.513672 5.764917,10.440544 5.3963617,10.297845 5.0546875,10.103516 4.7112698,9.9081939 4.3914618,9.6592598 4.1523438,9.4082031 3.6738502,8.9058189 3.4612706,8.5611414 3.328125,8.1992188 3.1951556,7.8377745 3.1240344,7.3891009 3.1503906,6.6328125 3.189332,5.5154117 3.0072393,6.2255396 3.4550781,5.3378906 3.7718709,4.7099841 4.191801,4.2329323 4.671875,3.8808594 5.1532176,3.5278555 5.6987223,3.3006313 6.2617188,3.2050781 Z")
    context.stroke(p);
    context.fill(p);
  }

  if (nextSurveyDays === '90' || nextSurveyDays === '180') {
    p = new Path2D("m 5.984375,13.865601 c -1.932,0 -6.00109701,4.513062 -5.99999997,6.414063 l 0.0312501,54.142577 c 0.0010977,1.901 1.56800007,3.44336 3.50000007,3.44336 l 6.9999998,0 c 1.932,0 3.501097,-1.54236 3.5,-3.44336 l -0.03125,-54.142577 c -0.0011,-1.901001 -4.0679987,-6.414063 -6,-6.414063 z m 0,1.586956 2,0 c 1.6559997,0 4.998915,3.735518 5,5.543479 l 0.03125,52.076596 c 0.0011,1.808001 -1.344,3.277344 -3,3.277344 l -6,0 c -1.656,0 -2.9989151,-1.469343 -3.0000001,-3.277344 L 0.98437504,20.996036 C 0.98327503,19.188074 4.328375,15.452557 5.984375,15.452557 Z")
    context.fillStyle = "red";
    context.stroke(p);
    context.fill(p);
  }
}

export const createMissingImage = (id, width, height, prefix, scale) => {
  
  // extract the color from the id
  const [vesselType, nextSurveyDays, moving] = id
    .replace(prefix, '')
    .split('-')
    .map(m => m);
  
  const bytesPerPixel = 4;

  // Each pixel is represented by 4 bytes: red, green, blue, and alpha.
  let data = new Uint8Array(width * height * bytesPerPixel);

  let canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  let context = canvas.getContext('2d');
  context.globalAlpha = 1;
  context.fillStyle = getVesselColorByType('oil_and_chemical_tanker');
  context.strokeStyle = "white";
  context.lineWidth = 1;
  context.scale(scale, scale);

  drawVessel(context, vesselType, nextSurveyDays, moving);

  data = context.getImageData(
  0,
  0,
  width,
  height
  ).data;
  return data;
}