import React, { useMemo, useState } from "react";
import moment from "moment";
import _ from "lodash";
import Widget from "components/Widget";
import { Button, Col, DatePicker, Form, Input, Modal, Row, Table, Timeline } from "antd";
import FormItem from "antd/lib/form/FormItem";
import SearchWithAutoComplete from "../../SearchWithAutoComplete";

const WorkingHistory = ({ histories, postHistory, deleteByHistoryId }) => {

  const historyColumns = useMemo(
    () => [
      {
        title: "Date",
        dataIndex: "date",
        render: (text, record) => (
          <>
            {record.readableDate}
          </>
        ),
      },
      {
        title: "Company",
        dataIndex: "company",
        render: (text, record) => (
          <>
            {record.company?.companyUrl &&
              <a href={`/app${record.company.companyUrl}`} target="_blank">
                {record.company.name}
              </a>
            }
            {!record.company?.companyUrl &&
              <>
                {record.company.name}
              </>
            }
          </>
        ),
      },
      {
        title: "Position",
        dataIndex: "position",
        render: (text, record) => (
          <>
            {record.position ? record.position + " - " : ""}
          </>
        ),
      },
      {
        title: "",
        dataIndex: "action",
        render: (text, record) => (
          <>
            <a href="!#" onClick={(e)=>{
              e.preventDefault()
              deleteByHistoryId.mutate(record.id)
            }}>remove</a>
          </>
        ),
      },
    ],
    []
  );

  const [visible, setVisible] = useState(false);
  const showModal = () => {
    setVisible(true);
  };
  const handleCancel = () => {
    setVisible(false);
  };

  const [form] = Form.useForm();
  const [checkCompany, setCheckCompany] = useState(true);
  const { MonthPicker } = DatePicker;
  const monthFormat = 'YYYY-MMM';

  return (
    <div id="contact">
      <Widget title="Working History" styleName="gx-card-profile-sm">
        <div className="gx-profile-banner-bottom">
          <span className="gx-link gx-profile-setting" onClick={showModal}>
            <i className="icon icon-setting gx-fs-lg gx-mr-2 gx-mr-sm-3 gx-d-inline-flex gx-vertical-align-middle" />
            <span className="gx-d-inline-flex gx-vertical-align-middle gx-ml-1 gx-ml-sm-0">edit</span>
          </span>
        </div>
        <Timeline>
          {histories.filter(h => h.company).map((h, index) => {
            return (
              <Timeline.Item key={`histo_${index}`}>
                {h.readableDate}
                <br />
                {h.position ? h.position + " - " : ""}
                {h.company?.companyUrl &&
                  <a href={`/app${h.company.companyUrl}`} target="_blank">
                    {h.company.name}
                  </a>
                }

                {!h.company?.companyUrl &&
                  <>
                    {h.company.name}
                  </>
                }
              </Timeline.Item>
            )
          })}
        </Timeline>

        <Modal
          forceRender
          visible={visible}
          title="Add working history"
          onCancel={handleCancel}
          width={1000}
          footer={[
            <Button key="back" onClick={handleCancel}>Close</Button>,
          ]}
        >
          <Form
            form={form}
            initialValues={{ remember: false, companyId: null, startDate: null }}
            onFinish={() => {
              postHistory.mutate({
                ...form.getFieldsValue(),
                startWorkDate: form.getFieldsValue().startDate ? form.getFieldsValue().startDate.toDate() : null,
                endWorkDate: form.getFieldsValue().endDate ? form.getFieldsValue().endDate.toDate() : null
              })
            }}
          >
            <Row>
              <Col md={12} className="full-width-search">
                <FormItem name="company" label="Company" rules={[{ required: true }]}>
                  <SearchWithAutoComplete types={['SHIPYARD', 'SHIPMANAGER', 'EQUIPMENT_MANUFACTURER', 'SERVICE_SUPPLIER']} placeholder="Search Shipyard | Shipmanager | Equipment Manufacturer | Service Supplier"
                    onChange={() => { }}
                    onSelect={(e) => {
                      form.setFieldsValue({ company: { id: e?.split("/")[2]?.split("-")[0] } })
                    }}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col md={8} className="full-width-search">
                <FormItem name="startDate" label="Start Date" rules={[{ required: true }]}>
                  <MonthPicker className="gx-mb-3 gx-w-100" format={monthFormat} />
                </FormItem>
              </Col>
              <Col md={8} className="full-width-search">
                <FormItem name="endDate" label="End Date" >
                  <MonthPicker className="gx-mb-3 gx-w-100" format={monthFormat} />
                </FormItem>
              </Col>
              <Col md={8} className="full-width-search">
                <FormItem name="position" label="Position" >
                  <Input
                  />
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Button type="primary" htmlType="submit" >Add</Button>
            </Row>
          </Form>

          <Table
            className="gx-table-responsive"
            columns={historyColumns}
            rowKey="id"
            dataSource={histories.filter(h => h.company) || []}
            sortDirections={["ascend", "descend", "ascend"]}
            pagination={{ hideOnSinglePage: true }}
          />
        </Modal>
      </Widget>
    </div>
  );
};

export default WorkingHistory;
