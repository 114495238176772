import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Settings from "./Settings";
import Auth from "./Auth";
import Notes from "./Notes";
import Contact from "./Contact";
import Common from "./Common";
import Map from './Map'
import Translation from './Translation.reducer'
import Vessel from './Vessel.reducer'
import Shipyard from './Shipyard.reducer';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  notes: Notes,
  contact: Contact,
  common: Common,
  map: Map,
  translation: Translation,
  vessel: Vessel,
  shipyard: Shipyard,
});

export default createRootReducer
