import { apiMdl } from "./api.middleware"
import { authMdl } from "./auth.middleware"
import { mapMdl } from "./map.middleware"
import { shipyardMdl } from "./shipyard.middleware"
import { translationMdl } from "./translation.middleware"
import { vesselMdl } from "./vessel.middleware"

export const rootMiddlewares = [
  ...apiMdl,
  ...mapMdl,
  ...translationMdl,
  ...vesselMdl,
  ...shipyardMdl,
  ...authMdl,
]