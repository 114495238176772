import React from "react";
import { Avatar } from "antd";

const ProfileHeader = ({ userInfo }) => {
  return (
    <div className="gx-profile-banner">
      <div className="gx-profile-container">
        <div className="gx-profile-banner-top">
          <div className="gx-profile-banner-top-left">
            <div className="gx-profile-banner-avatar">
              <Avatar className="gx-size-90" alt="..." src={userInfo.image} />
            </div>
            <div className="gx-profile-banner-avatar-info">
              <h2 className="gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">{userInfo.title ? userInfo.title : ""} {userInfo.name}</h2>
              <h2 className="gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">{userInfo.type}</h2>
              <p className="gx-mb-0 gx-fs-lg">{userInfo.company}</p>
            </div>
          </div>
          <div className="gx-profile-banner-top-right">
            <ul className="gx-follower-list">
              
            </ul>
          </div>
        </div>
        <div className="gx-profile-banner-bottom">
          <div className="gx-tab-list">
            <ul className="gx-navbar-nav">
              <li>
                <span className="gx-link">About</span>
              </li>
              <li>
                <span className="gx-link">Working History</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ProfileHeader;
