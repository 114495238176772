import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

const SignIn = () => {
  const history = useHistory();
  const {
    user, isAuthenticated
  } = useAuth0();

 useEffect(()=>{
  if (isAuthenticated) {
    //when user is back from auth0, redirect to home
    history.replace('/home');
  }
 }, [user, isAuthenticated, history])
 
  return <> Authenticating in progress...</>;
};

const RedirectHandler = () => {
  const {
    logout
  } = useAuth0();
  logout({
    localOnly:true
  });
  return <div>Redirecting you to login...</div>;
};

export default withAuthenticationRequired(SignIn, {
  onRedirecting: () => <RedirectHandler />,
});
