import React, { useMemo } from "react";
import { Card, Col, Row, Table } from "antd";
import Auxiliary from "../../util/Auxiliary";
import MapContainer from "../../components/Map";
import { useAuth0 } from "@auth0/auth0-react";
import Widget from "../../components/Widget";
import ContentAuthorization from "../ContentAuthorization";
import usePageQueries from "./queries";
import moment from "moment";
import { useIntl } from "react-intl";

const RecyclingTracker = () => {
  const intl = useIntl();
  const { isAuthenticated } = useAuth0();
  const {
    permissionsError,
    vessels,
    shipyards,
    ordersData,
    ordersDataError,
  } = usePageQueries({ isAuthenticated });

  const isError =
    permissionsError ||
    ordersDataError;

  if (isError) return <div>Sorry an Error occurred</div>;

  const Location = () => (
    <Card>
      <div id="location">
        <MapContainer
          type="shipyard"
          vessels={vessels}
          shipyards={shipyards}
        />
      </div>
    </Card>
  );


  const Orders = () => {
    const orderbookColumns = useMemo(
      () => [
        {
          title: "Recycling Yard",
          dataIndex: "shipyard",
          key: "shipyard",
          render: (text, record) => (
              <>
                {record.shipyard.name}
              </>
          ),
          sorter: (a, b) =>
            a.shipyard.name
              .toLowerCase()
              .localeCompare(b.shipyard.name.toLowerCase()),
        },
        {
          title: "Imo",
          dataIndex: "imo",
          sorter: (a, b) => (a.imo > b.imo ? 1 : -1),
          render: (text, record) => (
            <>
              {text}
            </>
          ),
        },
        {
          title: "Vessel Type",
          dataIndex: "tdVesselType",
          render: (text, record) => (
            <>
              {" "}
              { text }{" "}
            </>
          ),
          sorter: (a, b) => a.tdVesselType > b.tdVesselType,
        },
        {
          title: "Name",
          dataIndex: "name",
          sorter: (a, b) => (a.name > b.name ? 1 : -1),
          render: (text, record) => (
            <>
              {" "}
              {text}{" "}
            </>
          ),
        },
        {
          title: "Length",
          dataIndex: "length",
          key: "length",
          defaultSortOrder: "descend",
          sorter: (a, b) => (a["length"] > b["length"] ? 1 : -1),
        }
      ],
      []
    );

    return (
      <div id="orders">
        <Widget styleName="gx-card-profile">
          <div className="ant-card-head">
            <span className="ant-card-head-title gx-mb-1">Latest Recycling</span>
          </div>
          {ordersData.length > 0 &&
            <Table
              rowKey="imo"
              className="gx-table-responsive"
              columns={orderbookColumns}
              dataSource={ordersData || []}
              sortDirections={["ascend", "descend", "ascend"]}
              pagination={{ hideOnSinglePage: true }}
            />
          }
        </Widget>
      </div>
    );
  };

  return (
    <>
      <Auxiliary>
        <div className="gx-profile-content">
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Location />

              {ordersData && ordersData.length > 0 && (
                <ContentAuthorization
                  sectionId="shipyard-company_contacts"
                  permission={{ show: true, mask: isAuthenticated ? false : true }}
                >
                  <Orders />
                </ContentAuthorization>
              )}
            </Col>
          </Row>
        </div>
      </Auxiliary>
    </>
  );
};
export default RecyclingTracker;
