import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import logo from "../Logo";
import ReactDOMServer from "react-dom/server";

import {
  center,
} from "@turf/turf";
import "mapbox-gl/dist/mapbox-gl.css";
import {
  createMissingImage,
  getVesselScale,
} from "./helpers";
import _ from "lodash";

mapboxgl.accessToken =
  "pk.eyJ1IjoiY2hlbi1tYXJpdGltZWRhdGFzeXN0ZW1zLWNvbSIsImEiOiJjajNlNjduMzkwMHBhMzFzMjJnMGlpZmhvIn0.buGEUU37tesHnaWbKZET1A";

let COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];



//generate more colors for randomness
for (let i = 0; i < 30; i++) {
  COLORS = COLORS.concat(["#0088FE", "#00C49F", "#FFBB28", "#FF8042"]);
}

function addShipyardIconSourceAndLayer(map) {
  if (!map.getSource("shipyard-position-data")) {
    map.addSource("shipyard-position-data", {
      type: "geojson",
      data: { type: "FeatureCollection", features: [] },
    });
  }

  if (!map.getLayer("shipyard-position-layer")) {
    map.addLayer({
      id: "shipyard-position-layer",
      source: "shipyard-position-data",
      type: "symbol",
      layout: {
        "icon-size": 0.4,
        "icon-image": "shipyard",
        "text-allow-overlap": true,
        "icon-allow-overlap": true,
      },
    });
  }


  if (!map.getSource("all-region-data")) {
    map.addSource("all-region-data", {
      type: "geojson",
      data: { type: "FeatureCollection", features: [] },
    });
  }

  if (!map.getLayer("all-region-layer")) {
    map.addLayer({
      "id": "all-region-layer",
      "source": "all-region-data",
      'type': 'fill',
      'layout': {},
      'paint': {
        'fill-color': '#009688',
        'fill-opacity': 0.3,
        'fill-antialias': true,
        'fill-outline-color': '#009688'
      }
    });
  }

  if (!map.getSource("all-country-data")) {
    map.addSource("all-country-data", {
      "type": "geojson",
      data: { type: "FeatureCollection", features: [] },
    });
  }
  if (!map.getLayer("all-country-layer")) {
    map.addLayer({
      "id": "all-country-layer",
      "source": "all-country-data",
      'type': 'fill',
      'layout': {},
      'paint': {
        'fill-color': '#009688',
        'fill-opacity': 0.3,
        'fill-antialias': true,
        'fill-outline-color': '#009688'
      }
    });
  }
}

function addMissingImages(map) {
  map.on("styleimagemissing", (e) => {
    const prefix = "vessel-icon-";

    let imageName = e.id; // id of the missing image
    if (imageName.indexOf(prefix) === 0 && !map.hasImage(imageName)) {
      const [vesselType, vesselSize] = imageName // eslint-disable-line no-unused-vars
        .replace(prefix, "")
        .split("-")
        .map((m) => m);

      let scale = getVesselScale(vesselSize);
      const width = Math.ceil(16 * scale);
      const height = Math.ceil(78 * scale);
      let data = createMissingImage(imageName, width, height, prefix, scale);
      map.addImage(imageName, { width, height, data });
    }
  });

  map.loadImage(logo, function (error, image) {
    if (error) {
      throw error;
    }

    if (!map.hasImage("shipyard")) map.addImage("shipyard", image);
    if (!map.hasImage("shipyard-")) map.addImage("shipyard-", image);
  });
}

const StatisticsMap = ({ shipyards, countries, regions, tab }) => {

  const mapRef = useRef(null);

  const popupRef = useRef();
  let [mapLoaded, setMapLoaded] = useState(false);

  const getShipyardPopupContent = ({ properties }) => {
    let content = ''
    if (properties.type === 'shipyard') {
      content = (
        <div style={{ textAlign: "center", width: '100%' }}>{properties.name}</div>
      );
    } else if (properties.type === 'sailingarea') {
      content = (
        <div style={{ textAlign: "center", width: '100%' }}>{properties.name}</div>
      );
    } else if (properties.type === 'country') {
      content = (
        <div style={{ textAlign: "center", width: '100%' }}>{properties.name}</div>
      );
    }
    return ReactDOMServer.renderToString(content);
  };

  //parse and set company areas and docks
  useEffect(() => {
    if (!mapLoaded) return;

    if (shipyards) {
      const shipyardPositions = {
        type: "FeatureCollection",
        features: shipyards.map((shipyard) => ({
          geometry: {
            type: "Point",
            coordinates: [shipyard.companyLocation.x, shipyard.companyLocation.y],
          },
          id: "_" + shipyard.id,
          properties: {
            id: shipyard.id,
            name: shipyard.name,
            type: "shipyard",
          },
        })),
      };

      mapRef.current.getSource("shipyard-position-data").setData(shipyardPositions);
    }
    if (regions) {
      mapRef.current.getSource("all-region-data").setData(regions);
    }

    if (countries) {
      mapRef.current.getSource("all-country-data").setData(countries);
    }

  }, [shipyards, mapLoaded, tab, regions, countries]); // eslint-disable-line react-hooks/exhaustive-deps

  //set map center
  useEffect(() => {
    if (!mapRef.current) return;
    mapRef.current.setZoom(2);
    mapRef.current.resize();
  }, [mapLoaded]);

  //initialize the map
  useEffect(() => {
    if (mapRef.current) {
      return
    }
    const map = new mapboxgl.Map({
      container: "shipyard-map",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [10, 53.5],
      zoom: 5,
      maxZoom: 18,
    });

    // add navigation control (the +/- zoom buttons)
    map.addControl(
      new mapboxgl.NavigationControl({ showCompass: false }),
      "top-left"
    );
    map.dragRotate.disable();
    map.touchZoomRotate.disableRotation();
    map.on("load", () => {
      addShipyardIconSourceAndLayer(map);
      addMissingImages(map);
      mapRef.current = map
      setMapLoaded(true);
    })
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps


  const clickFunc = (e) => {
    let feature = mapRef.current.queryRenderedFeatures(e.point)[0];
    const type = feature?.properties?.type;
    if (type !== 'shipyard') {
      return;
    }
    let url = window.location.origin;

    url +=
      "/app/shipyards/" + feature?.properties?.id + "-" + _.kebabCase(feature?.properties?.name)
    window.open(url, "_blank");
  };

  useEffect(() => {
    if (!mapLoaded) {
      return
    }

    // Create a popup
    let popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      maxWidth: "none",
    });

    popupRef.current = popup;

    let mouseEnterFunc = (e) => {
      popupRef.current.remove();
      let feature = mapRef.current.queryRenderedFeatures(e.point)[0];
      mapRef.current.getCanvas().style.cursor = "pointer";

      let coordinates = center(feature.geometry).geometry.coordinates.slice();
      let description =
        getShipyardPopupContent({ properties: e.features[0]?.properties, shipyards })



      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      popup.setLngLat(coordinates).setHTML(description).addTo(mapRef.current);
    };

    let mouseLeaveFunc = () => {
      popupRef.current.remove();
      mapRef.current.getCanvas().style.cursor = "";
    };

    [
      "all-region-layer",
      "all-country-layer",
      "shipyard-position-layer",
    ].forEach((layer) => {
      mapRef.current.on("mouseenter", layer, mouseEnterFunc);
      mapRef.current.on("mouseleave", layer, mouseLeaveFunc);
      mapRef.current.on("click", layer, clickFunc);
    });

    return () => {
      popupRef.current.remove();
      [
        "all-region-layer",
        "all-country-layer",
        "shipyard-position-layer",
      ].forEach((layer) => {
        mapRef.current.off("mouseenter", layer, mouseEnterFunc);
        mapRef.current.off("mouseleave", layer, mouseLeaveFunc);
        mapRef.current.off("click", layer, clickFunc);
      });
    }
  }, [shipyards, countries, regions, tab, mapLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className="map-container"
      style={{ position: "relative", width: "100%", height: "100%" }}
    >
      <div
        id="shipyard-map"
        className="map"
        style={{
          width: "100%",
          height: "30rem",
        }}
      />
    </div>
  );
};

export default StatisticsMap;
