import { Form, Select } from "antd";

const MultipleSelect = ({ data: options, name, label, placeholder }) => {
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <Form.Item name={name} label={label}>
      <Select
        mode="multiple"
        allowClear
        style={{ width: "100%" }}
        placeholder={placeholder}
        onChange={handleChange}
      >
        {options.map((i) => {
          return (
            <Select.Option key={i} value={i}>
              {i}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default MultipleSelect;
