import React, { useEffect, useState } from "react";
import { Col, Radio, Row, Select } from "antd";
import usePageQueries from "./queries";
import { useAuth0 } from "@auth0/auth0-react";
import StatisticsMap from "../../components/Map/statistics-map";
import { allMaritimeCountry, allSailingareasGeo, blankGeoJson } from "../../util/geoUtils";
import CountryCharts from "../../components/CountryCharts";
import RegionCharts from "../../components/RegionCharts";

const Option = Select.Option;

const Statistics = () => {
  const { isAuthenticated } = useAuth0();
  const [currentTab, setCurrentTab] = useState('region');
  const [regions, setRegions] = useState(blankGeoJson);
  const [countries, setCountries] = useState(blankGeoJson);
  const [categoryDate, setCategoryDate] = useState('orderDate');
  const [vesselType, setVesselType] = useState('');


  const onChange = (e) => {
    setCurrentTab(e.target.value);
  };

  const {
    shipyards,
    vesselTypes
  } = usePageQueries({ isAuthenticated, tab: currentTab });

  useEffect(() => {
    if (currentTab === 'country') {
      setRegions(blankGeoJson)
      setCountries(allMaritimeCountry)
    } else if (currentTab === 'region') {
      setRegions(allSailingareasGeo)
      setCountries(blankGeoJson)
    } else {
      setRegions(blankGeoJson)
      setCountries(blankGeoJson)
    }
  }, [currentTab]);

  return (
    <>
      <Row>
        <Col span={24}>
          <Radio.Group value={currentTab} onChange={onChange} className="gx-mb-3 full-width">
            {/* <Radio.Button value="sni">Ship Newbuilding Index</Radio.Button> */}
            <Radio.Button value="region">Sailingarea</Radio.Button>
            <Radio.Button value="country">Country</Radio.Button>
          </Radio.Group>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <StatisticsMap shipyards={shipyards} regions={regions} countries={countries} tab={currentTab} />
        </Col>
      </Row>

      <Row className="mt10">
        <Col span={24} style={{ float: 'right' }}>
          <div style={{ float: 'right' }}>

            <Select className="gx-mr-3 gx-mb-3" defaultValue={categoryDate} style={{ width: "200px" }} onChange={(value) => {
              setCategoryDate(value)
            }}>
              <Option value="orderDate">Order Date</Option>
              <Option value="deliveryDate">Delivery Date</Option>
            </Select>
            <Select className="gx-mr-3 gx-mb-3" defaultValue={vesselType} style={{ width: "200px" }} onChange={(value) => {
              setVesselType(value)
            }}>
              <Option value={""}>All vessel types</Option>
              {vesselTypes && vesselTypes.map((type) => (
                <Option value={type.id} key={type.name}>
                  {type.name}
                </Option>
              ))}
            </Select>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {currentTab === 'region' &&
            <RegionCharts categoryDate={categoryDate} vesselType={vesselType} vesselTypes = {vesselTypes} />
          }
          {currentTab === 'country' &&
            <CountryCharts categoryDate={categoryDate} vesselType={vesselType} vesselTypes = {vesselTypes} />
          }
        </Col>
      </Row>
    </>
  );
};

export default Statistics;