import { useQuery } from "react-query";
import request from "../../util/request";
import _ from 'lodash'

const QUERY_KEYS = {
  ORDERER_MAIN: "orderer-main",
  ORDERER_VESSELS: "orderer-vessels",
  ORDERER_GEODATA: "orderer-geodata",
  ORDERER_PERMISSIONS: "orderer-permissions",
  ORDERER_ORDERS: "orderer-orders",
};

const usePageQueries = ({ isAuthenticated, ordererId }) => {
  const {
    data: main,
    isLoading: pageDataLoading,
    isError: mainError,
  } = useQuery(
    QUERY_KEYS.ORDERER_MAIN,
    async () => {
      const response = await request({
        url: `new-ships-api/${
          isAuthenticated ? "user" : "public"
        }/orderer/${ordererId}`,
        method: "GET",
      });

      // clean up api response
      if (response?.galleryImages?.length) {
        response.images = response.galleryImages.map((image) => ({
          url: image.url,
          thumbnail: image.previewUrl,
          id: image.attachmentID,
          alt: "orderer",
        }));
      }

      if (response?.videos?.length) {
        response.videos = response.videos.map((video) => ({
          ...video,
          url: "https://www.youtube.com/watch?v=" + video.url,
        }));
      }
      return response;
    },
    { refetchOnMount: false, refetchOnWindowFocus: false }
  );


  const {
    data: ordersData,
    isLoading: ordersDataLoading,
    isError: ordersDataError,
  } = useQuery(
    QUERY_KEYS.ORDERER_ORDERS,
    async () => {
      const response = await request({
        url: `new-ships-api/${
          isAuthenticated ? "user" : "public"
        }/orderer/${ordererId}/orders`,
        method: "GET",
      });

      return response;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: permissions,
    isLoading: permissionsLoading,
    isError: permissionsError,
  } = useQuery(
    QUERY_KEYS.ORDERER_PERMISSIONS,
    async () => {
      const response = await request({
        url: `new-ships-api/${
          isAuthenticated ? "user" : "public"
        }/content-permissions`,
        method: "GET",
        params: {
          page: "shipyard",
          id: _.isString(ordererId)? 6041 : ordererId,
        },
      });

      return response;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: 30000000,
      refetchOnWindowFocus: false,
      refetch: false,
    }
  );

  return {
    permissions,
    permissionsLoading,
    permissionsError,
    main,
    pageDataLoading,
    mainError,
    ordersData,
    ordersDataLoading,
    ordersDataError,
  };
};

export default usePageQueries;
