import { useMemo } from "react";
import { useIntl } from "react-intl";

const useChartData = ({ ordersData }) => {
  const intl = useIntl();

  const deliveredShipsPieChartData = useMemo(() => {
    if (!ordersData) return;
    const categories = ordersData.reduce((acc, curr) => {
      if (!acc[curr.status.toLowerCase()]) acc[curr.status.toLowerCase()] = 0;
      acc[curr.status.toLowerCase()] += 1;
      return acc;
    }, {});

    return Object.keys(categories).map((key) => {
      return { name: key, value: categories[key] };
    });
  }, [ordersData]);

  const vesselTypesPieChartData = useMemo(() => {
    if (!ordersData) return;

    const categories = ordersData.reduce((acc, curr) => {
      const vesselType = curr.tdVesselType
        .split(" ")
        .slice(0, 2)
        .join(" ");
      if (!acc[vesselType]) acc[vesselType] = 0;
      acc[vesselType] += 1;
      return acc;
    }, {});

    return Object.keys(categories).map((key) => {
      return { name: key, value: categories[key] };
    });
  }, [ordersData, intl])

  const vesselTypesBarChartData = useMemo(() => {
    if (!ordersData) return [];
    const categories = ordersData.reduce((acc, curr) => {
      const vesselType = curr.tdVesselType
        .split(" ")
        .slice(0, 2)
        .join(" ");
      if (!acc[vesselType]) acc[vesselType] = 0;
      acc[vesselType] += 1;
      return acc;
    }, {});

    return Object.keys(categories).map((key) => {
      return { name: key, "Number of Orders": categories[key] };
    });
  }, [ordersData, intl]);

  const deliveredShipsPerYearBarChartData = useMemo(() => {
    if (!ordersData) return [];
    const categories = ordersData
      .filter((i) => i.status.toLowerCase() === "delivered")
      .reduce((acc, curr) => {
        const year = new Date(curr.deliveryDate).getFullYear();
        if (!acc[year]) acc[year] = 0;
        acc[year] += 1;
        return acc;
      }, {});

    return Object.keys(categories).map((key) => {
      return { name: key, "Number of Delivered": categories[key] };
    });
  }, [ordersData]);


  const deliveredAndOrderedShipsPerYearBarChartData = useMemo(() => {
    if (!ordersData) return [];
    let ret = []
    let categories = {};
    let year = null;
    ordersData.forEach(element => {
      switch (element.status.toLowerCase()) {
        case "ordered":
          year = new Date(element.orderDate).getFullYear().toString();
          if (!categories[year]) {
            categories[year] = {}
          }
          categories[year]["ordered"] = (categories[year]["ordered"] || 0) + 1
          if (element.deliveryDate) {
            year = new Date(element.deliveryDate).getFullYear().toString();
            if (!categories[year]) {
              categories[year] = {}
            }
            categories[year]["delivery"] = (categories[year]["delivery"] || 0) + 1
          }
          break;
        case "delivered":
          year = new Date(element.deliveryDate).getFullYear().toString();
          if (!categories[year]) {
            categories[year] = {}
          }
          categories[year]["delivered"] = (categories[year]["delivered"] || 0) + 1
          break;
        default:
          break;
      }
    });

    ret = Object.keys(categories).map((key) => {
      return { year: key, "ordered": categories[key]["ordered"] || 0, "delivered": categories[key]["delivered"] || 0, "delivery": categories[key]["delivery"] || 0 };
    });

    return ret;
  }, [ordersData]);

  const orderedShipsPerYearBarChartData = useMemo(() => {
    if (!ordersData) return [];
    const categories = ordersData
      .filter((i) => i.status.toLowerCase() === "ordered")
      .reduce((acc, curr) => {
        const year = new Date(curr.deliveryDate).getFullYear();
        if (!acc[year]) acc[year] = 0;
        acc[year] += 1;
        return acc;
      }, {});

    return Object.keys(categories).map((key) => {
      return { name: key, "Number of Orders": categories[key] };
    });
  }, [ordersData]);

  const ordersByLengthBarChartData = useMemo(() => {
    if (!ordersData) return [];
    const lengthGroups = {
      "<50m": 0,
      "50m-100m": 0,
      "100m-200m": 0,
      "200m-300m": 0,
      "300m-400m": 0,
    }

    ordersData.forEach((curr) => {
      if (curr.length < 50) {
        lengthGroups["<50m"] += 1;
      }
      if (curr.length >= 50 && curr.length < 100) {
        lengthGroups["50m-100m"] += 1;
      }
      if (curr.length >= 100 && curr.length < 200) {
        lengthGroups["100m-200m"] += 1;
      }
      if (curr.length >= 200 && curr.length < 300) {
        lengthGroups["200m-300m"] += 1;
      }
      if (curr.length >= 300 && curr.length < 400) {
        lengthGroups["300m-400m"] += 1;
      }
    });

    let ret = Object.keys(lengthGroups).map((key) => {
      return { name: key, "Number of Vessels": lengthGroups[key] };
    });

    return ret;
  }, [ordersData]);

  const orderersData = useMemo(() => {
    if (!ordersData) return;
  
    const categories = ordersData.reduce((acc, curr) => {
      const vesselType = curr.orderer?.name;
      if (!acc[vesselType]) acc[vesselType] = 0;
      acc[vesselType] += 1;
      return acc;
    }, {});

    return Object.keys(categories).map((key) => {
      let orderer = ordersData.filter(d=>d.orderer).filter(d => d.orderer?.name === key);
      return { name: key, value: categories[key], orderer: orderer[0]?.orderer };
    });
  }, [ordersData]);

  return {
    deliveredShipsPieChartData,
    vesselTypesBarChartData,
    deliveredShipsPerYearBarChartData,
    orderedShipsPerYearBarChartData,
    deliveredAndOrderedShipsPerYearBarChartData,
    ordersByLengthBarChartData,
    vesselTypesPieChartData,
    orderersData
  };
};

export default useChartData;
