import { FETCH_NEW_BUILDING_ORDERS_SUCCESS, FETCH_VESSEL_BY_IMO_SUCCESS, FETCH_VESSEL_BY_ORDER_ID_SUCCESS, FETCH_VESSEL_TYPES_SUCCESS } from "../actions/Vessel.action";

const initialState = {
  vesselTypes: [],
  orderList: [],
  deliveredList:[],
  deliversList:[],
  publishedList:[],
  current: {}
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_VESSEL_TYPES_SUCCESS:
      return {
        ...state,
        vesselTypes: action.payload
      }
    case FETCH_NEW_BUILDING_ORDERS_SUCCESS:
      return {
        ...state,
        orderList: action.payload.orders,
        deliveredList:action.payload.delivered,
        deliversList:action.payload.delivers,
        publishedList:action.payload.published,
      }
    case FETCH_VESSEL_BY_IMO_SUCCESS:
      return {
        ...state,
        current: action.payload
      }
    case FETCH_VESSEL_BY_ORDER_ID_SUCCESS:
      return {
        ...state,
        current: action.payload
      }
    default:
      return state;
  }
}