import React from "react";
import _ from "lodash";
import { Tag } from "antd";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

const ShipyardHeader = ({ shipyard, linksToShow }) => {

  const intl = useIntl();

  return (
    <>
      {shipyard.picture &&
        <div
          className="gx-product-image"
          style={{
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            height: "400px",
            backgroundImage: `url('${shipyard.picture}')`,
          }}
        ></div>
      }
      <div
        className="gx-profile-banner"
        style={{ marginLeft: "0px", marginRight: "0px" }}
      >
        <div className="gx-profile-container">
          <div className="gx-profile-banner-top">
            <div className="gx-profile-banner-top-left">
              {shipyard.logo &&
                <div className="gx-profile-banner-avatar">
                  <img
                    alt={shipyard.name}
                    src={shipyard.logo}
                    style={{ maxHeight: "3.5rem" }}
                  />
                </div>
              }
              <div className="gx-profile-banner-avatar-info">
                <h2 className="gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">
                  {shipyard.name}
                </h2>
                {shipyard.closedStatus &&
                  <Tag color="red">
                    {intl.formatMessage({ id: `closed_status.${_.snakeCase(shipyard.closedStatus)}` })}
                  </Tag>
                }
                {shipyard?.parentCompany &&
                  <h2 className="gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">
                    Group:
                    <Link className="pl10" to={`/company-groups/${shipyard.parentCompany.id}-${_.kebabCase(shipyard.parentCompany.name)}`} target="_blank" rel="noreferrer" >
                      {shipyard.parentCompany.name}
                    </Link>
                  </h2>
                }
                {shipyard.country &&
                  <p className="gx-mb-0 gx-fs-lg">
                    <i style={{ marginBottom: "-7px" }} title={shipyard.country.name} className={`flag flag-24 gx-mr-2 flag-${_.lowerCase(shipyard.country.a2)}`} />
                    {shipyard.country?.name}
                  </p>
                }
              </div>
            </div>
            <div className="gx-profile-banner-top-right">
              <ul className="gx-follower-list">
                {shipyard.maxLength &&
                  <li>
                    <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">
                      {shipyard.maxLength}m
                    </span>
                    <span className="gx-fs-sm">Max. Length</span>
                  </li>
                }
                {shipyard.maxWidth &&
                  <li>
                    <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">
                      {shipyard.maxWidth}m
                    </span>
                    <span className="gx-fs-sm">Max. Width</span>
                  </li>
                }
                {shipyard.maxDepth &&
                  <li>
                    <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">
                      {shipyard.maxDepth}m
                    </span>
                    <span className="gx-fs-sm">Max. Depth</span>
                  </li>
                }
              </ul>
            </div>
          </div>
          <div className="gx-profile-banner-bottom">
            <div className="gx-tab-list">
              <ul className="gx-navbar-nav">
                {linksToShow.includes("about") && shipyard?.description && (
                  <li>
                    <a href="#about">
                      <span className="gx-link">About</span>
                    </a>
                  </li>
                )}
                {linksToShow.includes("repair") && (
                  <li>
                    <a href="#repair">
                      <span className="gx-link">Repair</span>
                    </a>
                  </li>
                )}
                {linksToShow.includes("location") && (
                  <li>
                    <a href="#location">
                      <span className="gx-link">Location</span>
                    </a>
                  </li>
                )}
                {linksToShow.includes("facilities") && shipyard?.dockList?.length > 0 && (
                  <li>
                    <a href="#facilities">
                      <span className="gx-link">Facilities</span>
                    </a>
                  </li>
                )}
                {linksToShow.includes("portfolio") && (
                  <li>
                    <a href="#portfolio">
                      <span className="gx-link">Portfolio</span>
                    </a>
                  </li>
                )}
                {linksToShow.includes("clients") && (
                  <li>
                    <a href="#clients">
                      <span className="gx-link">Clients</span>
                    </a>
                  </li>
                )}
                {linksToShow.includes("statistics") && (
                  <li>
                    <a href="#statistics">
                      <span className="gx-link">Statistics</span>
                    </a>
                  </li>
                )}
                {linksToShow.includes("orders") && (
                  <li>
                    <a href="#orders">
                      <span className="gx-link">Orders</span>
                    </a>
                  </li>
                )}
                {linksToShow.includes("builtVessels") && (
                  <li>
                    <a href="#builtVessels">
                      <span className="gx-link">Built Vessels</span>
                    </a>
                  </li>
                )}
                {linksToShow.includes("news") && (
                  <li>
                    <a href="#news">
                      <span className="gx-link">News</span>
                    </a>
                  </li>
                )}
                {linksToShow.includes("photos") && shipyard?.gallery?.length > 0 && (
                  <li>
                    <a href="#photos">
                      <span className="gx-link">Photos</span>
                    </a>
                  </li>
                )}
                {linksToShow.includes("videos") && shipyard?.videos?.length > 0 && (
                  <li>
                    <a href="#videos">
                      <span className="gx-link">Videos</span>
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShipyardHeader;
