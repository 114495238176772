import React from "react";
import { Row } from "antd";
import Widget from "components/Widget";

const About = ({ content }) => {
  return (
    <div id="about">
      <Widget title="About" styleName="gx-card-tabs gx-card-profile">
        <Row>
          <div style={{ padding: "1rem 1.5rem" , textAlign: "justify"}}>{content}</div>
        </Row>
      </Widget>
    </div>
  );
};


export default About;
