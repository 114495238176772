import React from "react";

import IntlMessages from "../../util/IntlMessages";

const Dashboard = () => {
  return (
    <div className="gx-page-error-container">
      <div className="gx-page-error-content">
        <h2 className="gx-text-center">
          <IntlMessages id="extraPages.comingSonn" />
        </h2>
      </div>
    </div>
  );
};

export default Dashboard;
