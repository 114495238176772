import React from "react";
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import {
  QueryClient,
  QueryClientProvider,
} from "react-query"
import "assets/vendors/style";
import configureStore, { history } from './appRedux/store';
import App from "./containers/App/index";
import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from "./config";

import Handsontable from 'handsontable/base';
import { registerAllModules } from 'handsontable/registry';

registerAllModules();

const store = configureStore(/* provide initial state if any */);
const queryClient = new QueryClient( { defaultOptions: {
  queries: {
    retry: false,
  },
},})


const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo.slice("/".length) : window.location.pathname
  );
};

// Please see https://auth0.github.io/auth0-react/interfaces/auth0provideroptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  redirectUri: `${window.location.origin}/app`,
  onRedirectCallback,
};

const NextApp = () =>
  <Provider store={store}>
    <Auth0Provider {...providerConfig}>
      <ConnectedRouter history={history}>
        <QueryClientProvider client={queryClient}>
          <App/>
        </QueryClientProvider>
      </ConnectedRouter>
    </Auth0Provider>
  </Provider>;


export default NextApp;
