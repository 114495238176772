import { useQuery } from "react-query";
import request from "../../util/request";

const QUERY_KEYS = {
  SHIPYARD_MAIN: "shipyard-main",
  SHIPYARD_VESSELS: "shipyard-vessels",
  SHIPYARD_NEWS: "shipyard-news",
  SHIPYARD_GEODATA: "shipyard-geodata",
  SHIPYARD_PERMISSIONS: "shipyard-permissions",
  SHIPYARD_ORDERS: "shipyard-orders",
  SHIPYARD_BUILT: "shipyard-built",
};

const usePageQueries = ({ isAuthenticated, shipyardId }) => {
  const {
    data: main,
    isLoading: pageDataLoading,
    isError: mainError,
  } = useQuery(
    QUERY_KEYS.SHIPYARD_MAIN,
    async () => {
      const response = await request({
        url: `new-ships-api/${
          isAuthenticated ? "user" : "public"
        }/shipyards/${shipyardId}`,
        method: "GET",
      });

      // clean up api response
      if (response?.galleryImages?.length) {
        response.images = response.galleryImages.map((image) => ({
          url: image.url,
          thumbnail: image.previewUrl,
          id: image.attachmentID,
          alt: "shipyard",
        }));
      }

      if (response?.videos?.length) {
        response.videos = response.videos.map((video) => ({
          ...video,
          url: "https://www.youtube.com/watch?v=" + video.url,
        }));
      }
      return response;
    },
    { refetchOnMount: false, refetchOnWindowFocus: false }
  );

  const {
    data: vessels,
    isLoading: vesselsLoading,
    isError: vesselsError,
  } = useQuery(
    QUERY_KEYS.SHIPYARD_VESSELS,
    async () => {
      const response = await request({
        url: `new-ships-api/${
          isAuthenticated ? "user" : "public"
        }/shipyards/${shipyardId}/vessels`,
        method: "GET",
      });
      return response;
    },
    { refetchOnMount: false, refetchOnWindowFocus: false }
  );

  const {
    data: news,
  } = useQuery(
    QUERY_KEYS.SHIPYARD_NEWS,
    async () => {
      const response = await request({
        url: `new-ships-api/${
          isAuthenticated ? "user" : "public"
        }/shipyards/${shipyardId}/news`,
        method: "GET",
      });
      return response;
    },
    { refetchOnMount: false, refetchOnWindowFocus: false }
  );

  const {
    data: geoData,
    isLoading: geoDataLoading,
    isError: geoDataError,
  } = useQuery(
    QUERY_KEYS.SHIPYARD_GEODATA,
    async () => {
      const response = await request({
        url: `new-ships-api/${
          isAuthenticated ? "user" : "public"
        }/shipyards/${shipyardId}/geoData`,
        method: "GET",
      });
      return response;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: ordersData,
    isLoading: ordersDataLoading,
    isError: ordersDataError,
  } = useQuery(
    QUERY_KEYS.SHIPYARD_ORDERS,
    async () => {
      const response = await request({
        url: `new-ships-api/${
          isAuthenticated ? "user" : "public"
        }/shipyards/${shipyardId}/orders`,
        method: "GET",
      });

      return response?.data;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: permissions,
    isLoading: permissionsLoading,
    isError: permissionsError,
  } = useQuery(
    QUERY_KEYS.SHIPYARD_PERMISSIONS,
    async () => {
      const response = await request({
        url: `new-ships-api/${
          isAuthenticated ? "user" : "public"
        }/content-permissions`,
        method: "GET",
        params: {
          page: "shipyard",
          id: shipyardId,
        },
      });

      return response;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: 30000000,
      refetchOnWindowFocus: false,
      refetch: false,
    }
  );

  const {
    data: allBuiltData,
  } = useQuery(
    QUERY_KEYS.SHIPYARD_BUILT,
    async () => {
      const response = await request({
        url: `new-ships-api/${
          isAuthenticated ? "user" : "public"
        }/shipyards/${shipyardId}/built-vessels`,
        method: "GET",
      });
      return response;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  return {
    news,
    permissions,
    permissionsLoading,
    permissionsError,
    geoData,
    geoDataLoading,
    geoDataError,
    vessels,
    vesselsLoading,
    vesselsError,
    main,
    pageDataLoading,
    mainError,
    ordersData,
    ordersDataLoading,
    ordersDataError,
    allBuiltData
  };
};

export default usePageQueries;
