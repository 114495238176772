import React, { useCallback} from "react";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Row, Col, Card, Button } from "antd";
const AuthError = () => {
  const location = useLocation();

  const { logout } = useAuth0();

  const goBack = useCallback(() => {
    logout({ returnTo: window.location.origin });
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const error =
    new URLSearchParams(location.search).get("error_description") ||
    "An unknown error occurred Please try again later";

  return (
      <div className="gx-main-content-wrapper">
        <Row>
          <Col span={4}></Col>
          <Col span={16} style={{ height: "30rem", textAlign: "center" }}>
            <Card style={{backgroundColor:"#003366"}}>
              <Row>
                <Col span={24} style={{ fontSize: '1.2rem', marginTop: "1.2rem", color: 'white' }}>
                  {error}
                </Col>
                <Col span={10}></Col>
                <Col style={{ marginTop: "3rem" }}>
                  <Button style={{margin: 0, color: '#003366', textDecoration: 'underline', border: 'none'}} onClick={goBack}>Back to Homepage</Button>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={4}></Col>
        </Row>
      </div>
  );
};

export default AuthError;
