import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import logo from "../Logo";

import {
  destination,
  point,
  transformRotate,
  featureCollection,
  convex,
  center,
} from "@turf/turf";
import "mapbox-gl/dist/mapbox-gl.css";
import CircularProgress from "../CircularProgress";
import {
  createMissingImage,
  getShipyardPopupContent,
  getVesselPopupContent,
  getVesselScale,
} from "./helpers";
import _ from "lodash";
mapboxgl.accessToken =
  "pk.eyJ1IjoiY2hlbi1tYXJpdGltZWRhdGFzeXN0ZW1zLWNvbSIsImEiOiJjajNlNjduMzkwMHBhMzFzMjJnMGlpZmhvIn0.buGEUU37tesHnaWbKZET1A";

function addShipyardIconSourceAndLayer(map) {
  if (!map.getSource("shipyard-position-data")) {
    map.addSource("shipyard-position-data", {
      type: "geojson",
      data: { type: "FeatureCollection", features: [] },
    });
  }

  if (!map.getLayer("shipyard-position-layer")) {
    map.addLayer({
      id: "shipyard-position-layer",
      source: "shipyard-position-data",
      type: "symbol",
      layout: {
        "icon-size": 0.4,
        "icon-image": "shipyard",
        "text-allow-overlap": true,
        "icon-allow-overlap": true,
      },
    });
  }

  //map.moveLayer('shipyard-position-layer', 'shipyard-area-polygon-layer');
}

function addShipyardPolygonSourceAndLayer(map) {
  if (!map.getSource("shipyard-area-polygon-data")) {
    map.addSource("shipyard-area-polygon-data", {
      type: "geojson",
      data: { type: "FeatureCollection", features: [] },
    });
  }

  if (!map.getLayer("shipyard-area-polygon-layer")) {
    map.addLayer({
      id: "shipyard-area-polygon-layer",
      source: "shipyard-area-polygon-data",
      type: "fill",
      layout: {},
      paint: {
        "fill-color": "red",
        "fill-opacity": 0.3,
        "fill-antialias": true,
        "fill-outline-color": "red",
      },
    });
  }
}

function addShipyardDocksSourceAndLayer(map) {
  if (!map.getSource("shipyard-docks-polygon-data")) {
    map.addSource("shipyard-docks-polygon-data", {
      type: "geojson",
      data: { type: "FeatureCollection", features: [] },
    });
  }

  if (!map.getLayer("shipyard-docks-polygon-layer")) {
    map.addLayer({
      id: "shipyard-docks-polygon-layer",
      source: "shipyard-docks-polygon-data",
      type: "fill",
      layout: {},
      paint: {
        "fill-color": "blue",
        "fill-opacity": 0.3,
        "fill-antialias": true,
        "fill-outline-color": "blue",
      },
    });
  }
}

function addVesselPolygonSourceAndLayer(map) {
  if (!map.getSource("vessel-polygon-data")) {
    map.addSource("vessel-polygon-data", {
      type: "geojson",
      data: { type: "FeatureCollection", features: [] },
    });
  }

  if (!map.getLayer("vessel-polygon-layer")) {
    map.addLayer({
      id: "vessel-polygon-layer",
      source: "vessel-polygon-data",
      type: "fill",
      layout: {},
      paint: {
        "fill-color": "#009688",
        "fill-opacity": 0.8,
      },
    });
  }
}

function addVesselPositionSourceAndLayer(map) {
  if (!map.getSource("vessel-position-data")) {
    map.addSource("vessel-position-data", {
      type: "geojson",
      data: { type: "FeatureCollection", features: [] },
    });
  }

  if (!map.getLayer("vessel-position-layer")) {
    map.addLayer({
      id: "vessel-position-layer",
      source: "vessel-position-data",
      type: "symbol",
      layout: {
        "icon-image": [
          "concat",
          "vessel-icon-",
          ["get", "vessel_type"],
          "-",
          ["get", "size"],
          "-",
          ["get", "load_status"],
          "-",
          ["get", "moving"],
        ],
        "icon-rotate": ["get", "heading"],
        "text-allow-overlap": true,
        "icon-allow-overlap": true,
      },
    });
  }
}

function addMouseOverPopups(map) {
  // Create a popup
  let popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
    maxWidth: "none",
  });

  let mouseEnterFunc = (e) => {
    let feature = map.queryRenderedFeatures(e.point)[0];

    map.getCanvas().style.cursor = "pointer";

    let coordinates = center(feature.geometry).geometry.coordinates.slice();
    let description =
      feature.layer.id === "shipyard-position-layer"
        ? getShipyardPopupContent(e.features[0]?.properties)
        : getVesselPopupContent(feature.properties);

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    popup.setLngLat(coordinates).setHTML(description).addTo(map);
  };

  let mouseLeaveFunc = () => {
    map.getCanvas().style.cursor = "";
    popup.remove();
  };

  const clickFunc = (e) => {
    let feature = map.queryRenderedFeatures(e.point)[0];
    const type = feature?.properties?.type;
    let url = window.location.origin;

    if (type === "shipyard") {
      url +=
        "/app/shipyards/" +
        feature?.properties?.id +
        "-" +
        _.kebabCase(feature?.properties?.name);
    } else if (type === "orders") {
      url += "/app/orders/" + feature?.properties?.order_id;
    } else {
      return;
    }

    window.open(url, "_blank");
  };

  [
    "vessel-position-layer",
    "vessel-polygon-layer",
    "shipyard-position-layer",
  ].forEach((layer) => {
    map.on("mouseenter", layer, mouseEnterFunc);
    map.on("mouseleave", layer, mouseLeaveFunc);
    map.on("click", layer, clickFunc);
  });
}

function addMissingImages(map) {
  map.on("styleimagemissing", (e) => {
    const prefix = "vessel-icon-";

    let imageName = e.id; // id of the missing image
    if (imageName.indexOf(prefix) === 0 && !map.hasImage(imageName)) {
      const [vesselType, vesselSize] = imageName // eslint-disable-line no-unused-vars
        .replace(prefix, "")
        .split("-")
        .map((m) => m);

      let scale = getVesselScale(vesselSize);
      const width = Math.ceil(16 * scale);
      const height = Math.ceil(78 * scale);
      let data = createMissingImage(imageName, width, height, prefix, scale);
      map.addImage(imageName, { width, height, data });
    }
  });

  map.loadImage(logo, function (error, image) {
    if (error) {
      throw error;
    }

    if (!map.hasImage("shipyard")) map.addImage("shipyard", image);
    if (!map.hasImage("shipyard-")) map.addImage("shipyard-", image);
  });
}

const MapContainer = ({
  vessels,
  loading,
  geoData,
  type,
  singleVesselData,
  singleShipyardData,
  disabledLayers = [],
  shipyards,
}) => {
  const mapRef = useRef(null);
  const [mapInit, setMapInit] = useState(false);

  //parse and set company areas and docks
  useEffect(() => {
    if (type !== "shipyard" && type !== "company-group") return;
    if (loading || !mapRef.current) return;
    let companyArea = [];
    let companyAreas = [];
    let companyFeatures = [];
    let docks = [];
    let oneDock = [];
    let bounds = new mapboxgl.LngLatBounds();

    geoData?.companyAreasDock?.forEach((area) => {
      companyArea = [];
      area.coordinates.forEach((coor) => {
        companyArea.push([coor.x, coor.y]);
      });
      companyAreas.push({
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: [companyArea],
        },
      });
    });

    if (geoData?.location) {
      bounds.extend([geoData.location.x, geoData.location.y]);
      companyFeatures.push({
        geometry: {
          type: "Point",
          coordinates: [geoData.location.x, geoData.location.y],
        },
        id: "_" + geoData.id,
        properties: {
          id: geoData.id,
          name: geoData.name,
          type: "shipyard",
        },
      });
    }

    geoData?.subCompanies?.forEach((c) => {
      if (c.location) {
        bounds.extend([c.location.x, c.location.y]);
        companyFeatures.push({
          geometry: {
            type: "Point",
            coordinates: [c.location.x, c.location.y],
          },
          id: "_" + c.id,
          properties: {
            id: c.id,
            name: c.name,
            type: "shipyard",
          },
        });
      }
    });

    shipyards?.forEach((c) => {
      if (c.location) {
        bounds.extend([c.location.x, c.location.y]);
        companyFeatures.push({
          geometry: {
            type: "Point",
            coordinates: [c.location.x, c.location.y],
          },
          id: "_" + c.id,
          properties: {
            id: c.id,
            name: c.name,
            type: "shipyard",
          },
        });
      }
    });

    const shipyardPosition = {
      type: "FeatureCollection",
      features: companyFeatures,
    };

    const companyAreaPolygon = {
      type: "FeatureCollection",
      features: companyAreas,
    };

    geoData?.dockList?.forEach((dock) => {
      oneDock = [];
      dock.coordinates.forEach((coor) => {
        oneDock.push([coor.x, coor.y]);
      });
      docks.push({
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: [oneDock],
        },
      });
    });

    const docksPolygon = {
      type: "FeatureCollection",
      features: docks,
    };

    let vesselsPolygonFeatures = [];
    vessels?.features?.forEach(function (feature) {
      if (!feature.properties.length || !feature.properties.width) {
        return;
      }
      bounds.extend(feature.geometry.coordinates);
      let dimA = feature.properties.length / 2.0;
      let dimB = feature.properties.length / 2.0;
      let dimC = feature.properties.width / 2.0;
      let dimD = feature.properties.width / 2.0;
      if (
        feature.properties.a &&
        feature.properties.b &&
        feature.properties.c &&
        feature.properties.d
      ) {
        dimA = feature.properties.a;
        dimB = feature.properties.b;
        dimC = feature.properties.c;
        dimD = feature.properties.d;
      }
      let halfWidth = (dimC + dimD) / 2.0;
      let p1 = destination(
        point(feature.geometry.coordinates),
        dimA - halfWidth,
        0,
        { units: "meters" }
      );
      let p2 = destination(point(feature.geometry.coordinates), dimB, 180, {
        units: "meters",
      });
      let p3 = destination(point(feature.geometry.coordinates), dimC, 270, {
        units: "meters",
      });
      let p4 = destination(point(feature.geometry.coordinates), dimD, 90, {
        units: "meters",
      });
      let p5 = destination(
        point([p3.geometry.coordinates[0], p1.geometry.coordinates[1]]),
        halfWidth,
        0,
        { units: "meters" }
      );
      let p6 = destination(p5.geometry.coordinates, halfWidth, 90, {
        units: "meters",
      });
      let hull = convex(
        featureCollection([
          point([p3.geometry.coordinates[0], p2.geometry.coordinates[1]]),
          point([p4.geometry.coordinates[0], p2.geometry.coordinates[1]]),
          point([p3.geometry.coordinates[0], p1.geometry.coordinates[1]]),
          point([p4.geometry.coordinates[0], p1.geometry.coordinates[1]]),
          point(p6.geometry.coordinates),
        ])
      );
      let heading = 0;
      if (feature.properties.heading) {
        heading = feature.properties.heading;
      } else if (feature.properties.cog) {
        heading = feature.properties.cog;
      }
      vesselsPolygonFeatures.push({
        ...transformRotate(hull, heading, {
          pivot: feature.geometry.coordinates,
        }),
        properties: feature.properties,
      });
    });

    const vesselPolygon = {
      type: "FeatureCollection",
      features: vesselsPolygonFeatures,
    };

    let surveyFeatures = [];
    vessels?.features?.forEach(function (f) {
      let days = 360;
      if (f.properties.next_survey) {
        let forecastDate = Date.parse(f.properties.next_survey);
        let dateDiff = (forecastDate - new Date()) / (1000 * 3600 * 24);
        if (dateDiff > -31 && dateDiff <= 91) {
          days = 90;
        } else if (dateDiff > 91 && dateDiff <= 182) {
          days = 180;
        }
      }
      surveyFeatures.push({
        geometry: f.geometry,
        properties: {
          ...f.properties,
          inSurveyDays: days,
        },
      });
    });

    const vesselPosition = {
      type: "FeatureCollection",
      features: surveyFeatures,
    };

    mapRef.current
      .getSource("shipyard-area-polygon-data")
      .setData(companyAreaPolygon);
    mapRef.current
      .getSource("shipyard-docks-polygon-data")
      .setData(docksPolygon);
    if (mapRef.current.getSource("vessel-polygon-data")) {
      mapRef.current.getSource("vessel-polygon-data").setData(vesselPolygon);
    }
    mapRef.current.getSource("vessel-position-data").setData(vesselPosition);
    mapRef.current
      .getSource("shipyard-position-data")
      .setData(shipyardPosition);

    if (bounds && !bounds.isEmpty()) {
      mapRef.current.fitBounds(bounds, { padding: 50 });
    }
  }, [geoData, loading, mapInit]); // eslint-disable-line react-hooks/exhaustive-deps

  //parse and set single vessel position
  useEffect(() => {
    if (type !== "single-vessel") return;
    if (loading || !mapRef.current) return;
    let bounds = new mapboxgl.LngLatBounds();

    let vesselsPolygonFeatures = [];
    singleVesselData?.geoJson?.features?.forEach(function (feature) {
      if (!feature.properties.length || !feature.properties.width) {
        return;
      }
      let dimA = feature.properties.length / 2.0;
      let dimB = feature.properties.length / 2.0;
      let dimC = feature.properties.width / 2.0;
      let dimD = feature.properties.width / 2.0;
      if (
        feature.properties.a &&
        feature.properties.b &&
        feature.properties.c &&
        feature.properties.d
      ) {
        dimA = feature.properties.a;
        dimB = feature.properties.b;
        dimC = feature.properties.c;
        dimD = feature.properties.d;
      }
      let halfWidth = (dimC + dimD) / 2.0;
      let p1 = destination(
        point(feature.geometry.coordinates),
        dimA - halfWidth,
        0,
        { units: "meters" }
      );
      let p2 = destination(point(feature.geometry.coordinates), dimB, 180, {
        units: "meters",
      });
      let p3 = destination(point(feature.geometry.coordinates), dimC, 270, {
        units: "meters",
      });
      let p4 = destination(point(feature.geometry.coordinates), dimD, 90, {
        units: "meters",
      });
      let p5 = destination(
        point([p3.geometry.coordinates[0], p1.geometry.coordinates[1]]),
        halfWidth,
        0,
        { units: "meters" }
      );
      let p6 = destination(p5.geometry.coordinates, halfWidth, 90, {
        units: "meters",
      });
      let hull = convex(
        featureCollection([
          point([p3.geometry.coordinates[0], p2.geometry.coordinates[1]]),
          point([p4.geometry.coordinates[0], p2.geometry.coordinates[1]]),
          point([p3.geometry.coordinates[0], p1.geometry.coordinates[1]]),
          point([p4.geometry.coordinates[0], p1.geometry.coordinates[1]]),
          point(p6.geometry.coordinates),
        ])
      );
      let heading = 0;
      if (feature.properties.heading) {
        heading = feature.properties.heading;
      } else if (feature.properties.cog) {
        heading = feature.properties.cog;
      }
      vesselsPolygonFeatures.push({
        ...transformRotate(hull, heading, {
          pivot: feature.geometry.coordinates,
        }),
        properties: feature.properties,
      });

      bounds.extend(feature.geometry.coordinates);
    });

    const vesselPolygon = {
      type: "FeatureCollection",
      features: vesselsPolygonFeatures,
    };

    let surveyFeatures = [];
    singleVesselData?.geoJson?.features?.forEach(function (f) {
      let days = 360;
      if (f.properties.next_survey) {
        let forecastDate = Date.parse(f.properties.next_survey);
        let dateDiff = (forecastDate - new Date()) / (1000 * 3600 * 24);
        if (dateDiff > -31 && dateDiff <= 91) {
          days = 90;
        } else if (dateDiff > 91 && dateDiff <= 182) {
          days = 180;
        }
      }
      surveyFeatures.push({
        geometry: f.geometry,
        properties: {
          ...f.properties,
          inSurveyDays: days,
        },
      });
      bounds.extend(f.geometry.coordinates);
    });

    const vesselPosition = {
      type: "FeatureCollection",
      features: surveyFeatures,
    };

    mapRef.current.getSource("vessel-polygon-data").setData(vesselPolygon);
    mapRef.current.getSource("vessel-position-data").setData(vesselPosition);

    if (singleShipyardData && singleShipyardData.location) {
      const shipyardPosition = {
        type: "FeatureCollection",
        features: [
          {
            geometry: {
              type: "Point",
              coordinates: [
                singleShipyardData.location.x,
                singleShipyardData.location.y,
              ],
            },
            id: "_" + singleShipyardData.id,
            properties: {
              id: singleShipyardData.id,
              name: singleShipyardData.name,
              type: "shipyard",
            },
          },
        ],
      };
      bounds.extend([
        singleShipyardData.location.x,
        singleShipyardData.location.y,
      ]);
      mapRef.current
        .getSource("shipyard-position-data")
        .setData(shipyardPosition);
    }
    mapRef.current.fitBounds(bounds, { padding: 50 });
  }, [singleVesselData, loading, mapInit, type, singleShipyardData]);

  //set map center
  useEffect(() => {
    if (loading || !mapRef.current) return;

    if (type === "single-vessel") {
      if (singleVesselData?.position) {
        mapRef.current.setCenter({
          lng: singleVesselData.position.x,
          lat: singleVesselData.position.y,
        });
      }
      mapRef.current.setZoom(5);
    }

    if (type === "company-group") {
    }

    if (type === "shipyard") {
      mapRef.current.setCenter({
        lng: geoData.location.x,
        lat: geoData.location.y,
      });
      mapRef.current.setZoom(14);
    }
    mapRef.current.resize();
  }, [loading, geoData, singleVesselData, type]);

  //initialize the map
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: "shipyard-map",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [10, 53.5],
      zoom: 5,
      maxZoom: 18,
    });

    // add navigation control (the +/- zoom buttons)
    map.addControl(
      new mapboxgl.NavigationControl({ showCompass: false }),
      "top-left"
    );
    map.dragRotate.disable();
    map.touchZoomRotate.disableRotation();

    map.on("load", () => {
      addShipyardPolygonSourceAndLayer(map);
      addShipyardDocksSourceAndLayer(map);
      if (!disabledLayers.includes("vessel-polygons"))
        addVesselPolygonSourceAndLayer(map);
      addVesselPositionSourceAndLayer(map);
      addShipyardIconSourceAndLayer(map);
      addMouseOverPopups(map);
      addMissingImages(map);
      mapRef.current = map;
      setMapInit(true);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className="map-container"
      style={{ position: "relative", width: "100%", height: "100%" }}
    >
      <div
        style={{
          textAlign: "center",
          width: "100%",
          height: "25rem",
          display: !loading ? "none" : "block",
        }}
      >
        <CircularProgress
          style={{
            height: "2rem",
            paddingTop: "6rem",
            paddingBottom: "3rem",
          }}
        />
        <h3 style={{ color: "#bbb" }}>Map data is loading...</h3>
      </div>
      <div
        id="shipyard-map"
        className="map"
        style={{
          width: "100%",
          height: "25rem",
          display: loading ? "none" : "block",
        }}
      />
    </div>
  );
};

export default MapContainer;
