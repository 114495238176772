import { useAuth0 } from "@auth0/auth0-react";
import { Button, Card, Col, Row } from "antd";
import React from "react";

const LoginOrRegister = () => {

  const {
    logout,
  } = useAuth0();

  return (

    <div className="gx-main-content" gutter={[8, 24]}>
      <Row >
        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-d-none gx-d-sm-block">
          <Card style={{ height: '80%' }} className="gx-card" title="new-ships.com / trusteddocks.com Users">
            <p>
              If your already have an valid new-ships.com / trusteddocks.com login
            </p>
            <Button
              type="primary"
              onClick={() => {
                window.location.replace("/app/auth");
              }}
            >
              login
            </Button>
          </Card>
        </Col>

      </Row>
      <Row >
        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-d-none gx-d-lg-block">
          <Card style={{ height: '80%' }} className="gx-card" title="new user">
            <p>
              Click below button to begin a free trail for 10 days. Your account will be manually actived within 24 hours.
              Before the trail period expires, we will contact you with an offer for continnued use.<br /><br />
            </p>
            <Button
              type="primary"
              onClick={() => {
                logout({
                  returnTo: `${window.location.origin}/app?signup=true`,
                });
              }}
            >
              Signup
            </Button>
          </Card>
        </Col>
      </Row>
      <Row >
        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-d-none gx-d-sm-block">
          <Card style={{ height: '80%' }} className="gx-card" title="Contact Us">
            <p>
              If you are interested in prices for our online database now, as well as conditions for API usage, please&nbsp;
              <a href="https://new-ships-discover.trusteddocks.com/contact-us" target="_blank">
                click here to contact us.
              </a>
            </p>
          </Card>
        </Col>
      </Row>
    </div>
  )
};

export default LoginOrRegister;
