import ReactECharts from 'echarts-for-react';
import React, { Fragment } from "react";
import usePageQueries from "./queries";
import { selectedStatisticCountryNames, statisticCountryNames } from '../../util/geoUtils';
import { useAuth0 } from '@auth0/auth0-react';
import { useIntl } from 'react-intl';



const CountryCharts = ({categoryDate, vesselType, vesselTypes}) => {
  const intl = useIntl();

  const getText = (text) => {
    let ret = "Newbuilding " + text + " / "
    if (categoryDate === 'orderDate') {
      ret += "Order Date"
    } else {
      ret += "Delivery Date"
    }
    ret += " / "
    if (vesselType) {
      ret += vesselTypes.filter(t => t.id === vesselType)[0].name
    } else {
      ret += "All Vessel Types"
    }
    return ret;
  }

  const { isAuthenticated } = useAuth0();

  const {
    xAxisData,
    countryData
  } = usePageQueries({ isAuthenticated, categoryDate, vesselType });

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999'
        }
      }
    },
    xAxis: [
      {
        type: 'category',
        data: xAxisData,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: getText("Vessel"),
        axisLabel: {
          formatter: '{value}'
        }
      },
      {
        type: 'value',
        name: getText("Deadweight"),
        axisLabel: {
          formatter: '{value}'
        }
      }
    ],
    series: []
  };


  const labelOption = {
    show: false,
    formatter: '{c}  {name|{a}}',
    fontSize: 16,
    rich: {
      name: {}
    }
  };

  let categoryChartOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      bottom: '20%',
    },
    legend: {
      top: 'bottom',
      bottom: '20%',
      data: statisticCountryNames,
      selected: selectedStatisticCountryNames,
    },
    toolbox: {
      show: true,
      orient: 'vertical',
      left: 'right',
      top: 'center',
      feature: {
        magicType: { show: true, type: ['bar', 'stack'] },
      }
    },
    xAxis: [
      {
        type: 'category',
        axisTick: { show: false },
        data: xAxisData
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
    ]
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
      }}
    >

      {countryData &&
        <ReactECharts style={{ height: "500px", marginTop: "20px" }} option={{
          ...categoryChartOption,
          title: {
            left: 'center',
            text: getText("Vessel"),
          },
          series:
            countryData.filter(f => statisticCountryNames.some(element => {
              return element.toLowerCase() === f.sailingArea.toLowerCase();
            })).map(c => {
              return {
                "name": c.sailingArea,
                "type": 'bar',
                "label": labelOption,
                "emphasis": {
                  "focus": 'series'
                },
                "data": c.worldRepairIndexDateCountVos.map(d => d.countUniqueVessels)
              }
            })
        }
        }
        />
      }

      {countryData &&
        <ReactECharts style={{ height: "500px", marginTop: "20px" }} option={{
          ...categoryChartOption,
          title: {
            left: 'center',
            text: getText("Deadweight"),
          },
          series:
            countryData.filter(f => statisticCountryNames.some(element => {
              return element.toLowerCase() === f.sailingArea.toLowerCase();
            })).map(c => {
              return {
                "name": c.sailingArea,
                "type": 'bar',
                "label": labelOption,
                "emphasis": {
                  "focus": 'series'
                },
                "data": c.worldRepairIndexDateCountVos.map(d => d.countUniqueVesselsDwt)
              }
            })
        }
        }
        />
      }

      {countryData && countryData.filter(f => statisticCountryNames.some(element => {
        return element.toLowerCase() === f.sailingArea.toLowerCase();
      })).sort((r1, r2) => {
        let a = r1.worldRepairIndexDateCountVos[r1.worldRepairIndexDateCountVos.length - 1].countUniqueVessels
        let b = r2.worldRepairIndexDateCountVos[r2.worldRepairIndexDateCountVos.length - 1].countUniqueVessels
        return a.toString().localeCompare(b.toString(), undefined, { 'numeric': true })
      }).reverse().map((r, index) => {
        return (
          <Fragment key={`region_${index}`}>
            <h4 className='gradient-subTitle'>
              {r.sailingArea}
            </h4>
            <div className='row' >
              <div className='col-md-12'>
                <ReactECharts option={
                  {
                    ...option,
                    series: [
                      {
                        name: 'Ship Repair Days',
                        type: 'bar',
                        tooltip: {
                          valueFormatter: function (value) {
                            return value;
                          }
                        },
                        data: r.worldRepairIndexDateCountVos.map(v => v.countUniqueVessels)
                      },
                      {
                        name: 'Ship Repair Deadweight',
                        type: 'bar',
                        yAxisIndex: 1,
                        tooltip: {
                          valueFormatter: function (value) {
                            return value;
                          }
                        },
                        data: r.worldRepairIndexDateCountVos.map(v => v.countUniqueVesselsDwt)
                      }
                    ]
                  }
                } />
              </div>
            </div>
          </Fragment>
        )
      })}
    </div>
  );
}

export default CountryCharts
