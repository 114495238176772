import React from "react";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { LockOutlined } from "@ant-design/icons";

const SubMenu = Menu.SubMenu;

const HorizontalNav = () => {
  const pathname = useSelector(({ common }) => common.pathname);

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  return (
    <Menu
      defaultOpenKeys={[defaultOpenKeys]}
      selectedKeys={[selectedKeys]}
      theme="dark"
      mode="horizontal"
    >
      <Menu.Item key="home">
        <Link to="/home">
          Home
        </Link>
      </Menu.Item>

      <Menu.Item key="news">
        <Link to="/news">
          News
        </Link>
      </Menu.Item>

      <Menu.Item key="search">
        <Link to="/search">
          <LockOutlined className="mr5" />
          Search
        </Link>
      </Menu.Item>

      <Menu.Item key="statistics">
        <Link to="/statistics">
          <LockOutlined className="mr5" />
          Statistics
        </Link>
      </Menu.Item>

      <SubMenu
        key="tracker/"
        title={
          <span>
            <span>
              <LockOutlined className="mr5" />
              Tracker
            </span>
          </span>
        }
      >
        <Menu.Item
          key="tracker/new-build"
          theme="dark"
        >
          <Link to="/tracker/new-build">
            <LockOutlined className="mr5" />
            New Build
          </Link>
        </Menu.Item>
        <Menu.Item
          key="tracker/recycling"
          theme="dark"
        >
          <Link to="/tracker/recycling">
            <LockOutlined className="mr5" />
            Recycling
          </Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        key="/guide"
        title={
          <span>
            <span>
              <LockOutlined className="mr5" />
              Guide
            </span>
          </span>
        }
      >
        <Menu.Item key="guide/construction">
          <Link to="/guide/construction">
            <LockOutlined className="mr5" />
            Construction Guide
          </Link>
        </Menu.Item>
        <Menu.Item key="guide/recycling">
          <Link to="/guide/recycling">
            <LockOutlined className="mr5" />
            Recycling Guide
          </Link>
        </Menu.Item>
      </SubMenu>

      <Menu.Item key="about">
        <a href="https://new-ships.trusteddocks.com/discover/" target={"_blank"}>
          About
        </a>
      </Menu.Item>

    </Menu>
  );
};

HorizontalNav.propTypes = {};

export default HorizontalNav;

