import { useAuth0 } from "@auth0/auth0-react";
import { Tag } from "antd";
import React from "react";
import { useIntl } from "react-intl";

const VesselHeader = ({ vessel }) => {
  const intl = useIntl()
  const { isAuthenticated } = useAuth0();

  return (
    <>
      {vessel?.picture &&
        <div className="gx-product-image" style={{
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          height: "400px",
          backgroundImage: `url('${vessel.picture}')`
        }}>
        </div>
      }
      <div className="gx-profile-banner" style={{ marginLeft: '0px', marginRight: '0px' }}>
        <div className="gx-profile-container">
          <div className="gx-profile-banner-top">
            <div className="gx-profile-banner-avatar-info">
              <h2 className="gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">
                {vessel.name ? vessel.name : ""}
                {(vessel.imo && isAuthenticated) ? `(IMO: ${vessel.imo})` : ""}
                <Tag
                  className="gx-rounded-xs gx-text-uppercase"
                  color="#06BB8A"
                  style={{ display: "table" }}
                >
                  {vessel.status}
                </Tag>
              </h2>
              {vessel.tags &&
                vessel.tags.map(t => {
                  return (
                    <Tag key={`tag_${t.id}`}
                      className="gx-rounded-xs gx-text-uppercase"
                    >{t.name}</Tag>
                  )
                })
              }
            </div>
            <div className="gx-profile-banner-top-right">
              <ul className="gx-follower-list">
                {vessel?.length &&
                  <li>
                    <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">{vessel.length}m</span>
                    <span className="gx-fs-sm">Length</span>
                  </li>
                }
                {vessel?.width &&
                  <li>
                    <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">{vessel.width}m</span>
                    <span className="gx-fs-sm">Width</span>
                  </li>
                }
                {vessel?.draught &&
                  <li>
                    <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">{vessel.draught}m</span>
                    <span className="gx-fs-sm">Draught</span>
                  </li>
                }
              </ul>
            </div>
          </div>
          <div className="gx-profile-banner-bottom">
            <div className="gx-tab-list">
              <ul className="gx-navbar-nav">
                <li>
                  <span className="gx-link">Details</span>
                </li>
                {vessel.vessel &&
                  <li>
                    <a href="#location">
                      <span className="gx-link">Location</span>
                    </a>
                  </li>
                }
                {vessel?.sisters?.length > 0 &&
                  <li>
                    <a href="#sisters">
                      <span className="gx-link">Sister Orders</span>
                    </a>
                  </li>
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default VesselHeader;
