import React from "react";
import {Bar, BarChart as ReBarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

const BarChart = ({ data, title, YAxisLabel }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignContent: "center",
    }}
  >
    <h4
      style={{
        marginRight: "auto",
        marginLeft: "auto",
        color: "rgb(84 84 84 / 62%)",
      }}
    >
      {title}
    </h4>
    <ResponsiveContainer width="100%" height={250}>
      <ReBarChart
        data={data}
        margin={{ top: 10, right: 0, left: -15, bottom: 0 }}
      >
        <XAxis dataKey="name" />
        <YAxis dataKey={YAxisLabel} />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Bar dataKey={YAxisLabel} fill="#FE9E15" />
      </ReBarChart>
    </ResponsiveContainer>
  </div>
);

export default BarChart;
