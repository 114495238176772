import React, { Fragment, useMemo } from "react";
import { Button, Card, Carousel, Col, Row, Table, Tabs, Tag } from "antd";
import About from "../../components/profile/About/index";
import Contact from "../../components/profile/Contact/index";
import Auxiliary from "../../util/Auxiliary";
import ShipyardHeader from "../../components/profile/ShipyardHeader/index";
import { Link, useParams } from "react-router-dom";
import CircularProgress from "../../components/CircularProgress";
import MapContainer from "../../components/Map";
import { useAuth0 } from "@auth0/auth0-react";
import Widget from "../../components/Widget";
import ContentAuthorization from "../ContentAuthorization";
import usePageQueries from "./queries";
import GalleryAndLightbox from "../../components/GalleryAndLightbox";
import moment from "moment";
import { useIntl } from "react-intl";
import _ from "lodash";
import PieChart from "../../components/PieChart";
import BarChart from "../../components/BarChart";
import useChartData from "./chartsData";
import Address from "../../components/profile/Address";
import { RightOutlined } from "@ant-design/icons";
import TinyBarChart from "../../routes/extensions/charts/recharts/bar/Components/TinyBarChart";
import { Helmet } from "react-helmet";

const CompanyGroup = () => {
  const intl = useIntl();
  let { slug } = useParams();
  let shipyardId = slug.split("-")[0];

  const { isAuthenticated } = useAuth0();

  const {
    news,
    permissions,
    permissionsLoading,
    permissionsError,
    geoData,
    geoDataLoading,
    geoDataError,
    vessels,
    vesselsLoading,
    vesselsError,
    main,
    pageDataLoading,
    mainError,
    ordersData,
    ordersDataLoading,
    ordersDataError,
  } = usePageQueries({ isAuthenticated, shipyardId });

  const {
    deliveredShipsPieChartData,
    vesselTypesPieChartData,
    deliveredAndOrderedShipsPerYearBarChartData,
    ordersByLengthBarChartData,
    orderersData
  } = useChartData({ ordersData });

  const linksToShow = useMemo(() => {
    const link = ["about", "location", "facilities", "photos", "videos", "contact"];
    if (ordersData && ordersData.length > 0) link.splice(1, 0, "orders");
    if (ordersData && ordersData.length > 0) link.splice(1, 0, "statistics");
    if (ordersData && ordersData.length > 0) link.splice(1, 0, "portfolio");
    if (ordersData && ordersData.length > 0) link.splice(1, 0, "clients");
    if (main && main.services.filter(s => s.id === 2).length > 0) link.splice(1, 0, "repair");
    return link;
  }, [ordersData, main]);

  const isError =
    mainError ||
    vesselsError ||
    geoDataError ||
    permissionsError ||
    ordersDataError;
  const mapDataLoading =
    vesselsLoading || geoDataLoading || permissionsLoading || ordersDataLoading;

  const pagePermissions = permissions || {};

  if (pageDataLoading) return <CircularProgress />;
  if (isError) return <div>Sorry an Error occurred</div>;

  const PhotoGallery = () => {
    if (!main?.images?.length) return null;
    return (
      <div id="photos">
        <Widget styleName="gx-card-profile">
          <div className="ant-card-head">
            <span className="ant-card-head-title gx-mb-1">Photo Gallery</span>
          </div>
          <div className="gx-pt-md-3">
            <GalleryAndLightbox urlObjects={main?.images} type="image" />
          </div>
        </Widget>
      </div>
    );
  };

  const VideoGallery = () => {
    if (!main?.videos.length) return null;
    return (
      <div id="videos">
        <Widget styleName="gx-card-profile">
          <div className="ant-card-head">
            <span className="ant-card-head-title gx-mb-1">Video Gallery</span>
          </div>
          <div className="gx-pt-md-3">
            <GalleryAndLightbox urlObjects={main?.videos} type="video" />
          </div>
        </Widget>
      </div>
    );
  };

  const Location = () => (
    <Card>
      <div id="location">
        <MapContainer
          type="company-group"
          vessels={vessels}
          geoData={geoData}
          loading={mapDataLoading}
          disabledLayers={["vessel-polygons"]}
        />
      </div>
    </Card>
  );

  const Statistics = () => {
    return (
      <div id="statistics">
        <Widget styleName="gx-card-profile">
          <div className="ant-card-head">
            <span className="ant-card-head-title gx-mb-1">Statistics last 5 years (from {new Date().getFullYear() - 5})</span>
          </div>
          <div className="gx-pt-md-3">
            <Row>
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <PieChart
                  data={deliveredShipsPieChartData}
                  title="Delivery Status"
                />
              </Col>
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <PieChart data={vesselTypesPieChartData} title="Vessel Types" />
              </Col>
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <TinyBarChart
                  data={deliveredAndOrderedShipsPerYearBarChartData}
                  title="Ordered / Delivered / Delivery per Year"
                />
              </Col>
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <BarChart
                  data={ordersByLengthBarChartData}
                  title="Vessel Length"
                  YAxisLabel="Number of Vessels"
                />
              </Col>
            </Row>
          </div>
        </Widget>
      </div>
    );
  }

  const Portfolio = () => {
    return (
      <div id="portfolio">
        <Widget styleName="gx-card-profile">
          <div className="ant-card-head">
            <span className="ant-card-head-title gx-mb-1">Vessel Portfolio</span>
          </div>
          <div className="pt0">
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                {vesselTypesPieChartData.map((type, index) => {
                  return (
                    <Tag key={`portfolio_${index}`}>
                      {type.name}
                    </Tag>
                  )
                })}
              </Col>
            </Row>
          </div>
        </Widget>
      </div>
    );
  }

  const Clients = () => {
    return (
      <div id="clients">
        <Widget styleName="gx-card-profile">
          <div className="ant-card-head">
            <span className="ant-card-head-title gx-mb-1">Clients</span>
          </div>
          <div className="pt0">
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                {orderersData.map((orderer, index) => {
                  return (
                    <Fragment key={`orderers_${index}`}>
                      {orderer.orderer &&
                        <Link to={`/orderers/${orderer.orderer.idStr}-${_.kebabCase(orderer.name)}`} >
                          <Tag>
                            {orderer.name}
                          </Tag>
                        </Link>
                      }
                    </Fragment>
                  )
                })}
              </Col>
            </Row>
          </div>
        </Widget>
      </div>
    );
  }

  const SubCompanies = () => {
    return (
      <div id="clients">
        <Widget styleName="gx-card-profile">
          <div className="ant-card-head">
            <span className="ant-card-head-title gx-mb-1">Shipyard in this Group</span>
          </div>
          <div className="pt0">
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                {main.subCompanies.map((c, index) => {
                  return (
                    <Fragment key={`shipyards_${index}`}>
                      {c &&
                        <Link to={`/shipyards/${c.id}-${_.kebabCase(c.name)}`} >
                          <Tag>
                            {c.name}
                          </Tag>
                        </Link>
                      }
                    </Fragment>
                  )
                })}
              </Col>
            </Row>
          </div>
        </Widget>
      </div>
    );
  }

  const Orders = () => {

    const orderbookColumns = useMemo(
      () => [
        {
          title: "Imo",
          dataIndex: "imo",
          sorter: (a, b) => (a.imo > b.imo ? 1 : -1),
          render: (text, record) => (
            <a
              href={`/app/orders/${record.id}-${_.kebabCase(text)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {text}
            </a>
          ),
        },
        {
          title: "Hull No.",
          dataIndex: "hullNo",
          sorter: (a, b) => (a.hullNo > b.hullNo ? 1 : -1),
        },
        {
          title: "Shipyard",
          dataIndex: "shipyard",
          key: "shipyard",
          render: (text, record) => (
            <span className="gx-link">
              <a
                href={`/app/shipyards/${record.shipyard.id}-${_.kebabCase(
                  record.shipyard.name
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {record.shipyard.name}
              </a>
            </span>
          ),
          sorter: (a, b) =>
            a.shipyard.name
              .toLowerCase()
              .localeCompare(b.shipyard.name.toLowerCase()),
        },
        {
          title: "Orderer",
          dataIndex: "orderer",
          key: "orderer",
          render: (text, record) => (
            <>
              {record.orderer?.ordererUrl && (
                <span className="gx-link">
                  <a
                    href={`/app${record.orderer?.ordererUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {record.orderer?.name}
                  </a>
                </span>
              )}

              {!record.orderer?.ordererUrl && <>{record.orderer?.name}</>}
            </>
          ),
          sorter: (a, b) => a.orderer?.name > b.orderer?.name,
        },
        {
          title: "Vessel Type",
          dataIndex: "tdVesselType",
          render: (text, record) => (
            <a
              href={`/app/orders/${record.id}-${_.kebabCase(text)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              {text}{" "}
            </a>
          ),
          sorter: (a, b) => a.tdVesselType > b.tdVesselType,
        },
        {
          title: "Name",
          dataIndex: "name",
          sorter: (a, b) => (a.name > b.name ? 1 : -1),
          render: (text, record) => (
            <a
              href={`/app/orders/${record.id}-${_.kebabCase(text)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              {text}{" "}
            </a>
          ),
        },
        {
          title: "Length",
          dataIndex: "length",
          key: "length",
          defaultSortOrder: "descend",
          sorter: (a, b) => (a["length"] > b["length"] ? 1 : -1),
        },
        {
          title: "Width",
          dataIndex: "width",
          key: "width",
          sorter: (a, b) => (a.width > b.width ? 1 : -1),
        },
        {
          title: "DWT",
          dataIndex: "deadweight",
          key: "deadweight",
          defaultSortOrder: "descend",
          sorter: (a, b) => (a.deadweight > b.deadweight ? 1 : -1),
        },
        {
          title: "TEU",
          dataIndex: "teu",
          key: "teu",
          sorter: (a, b) => (a.teu > b.teu ? 1 : -1),
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          sorter: (a, b) => (a.status > b.status ? 1 : -1),
        },
        {
          title: "Order Date",
          dataIndex: "orderDate",
          key: "orderDate",
          render: (d) => (d ? moment(d).format("DD.MMM.YYYY") : ""),
          sorter: (a, b) => (moment(a).isAfter(moment(b)) ? 1 : -1),
        },
        {
          title: "Delivery Date",
          dataIndex: "deliveryDate",
          key: "deliveryDate",
          render: (d) => (d ? moment(d).format("DD.MMM.YYYY") : ""),
          sorter: (a, b) => (moment(a).isAfter(moment(b)) ? 1 : -1),
        },
      ],
      []
    );

    return (
      <div id="orders">
        <Widget styleName="gx-card-profile">
          <div className="ant-card-head">
            <span className="ant-card-head-title gx-mb-1">Order Details</span>
          </div>
          <Tabs type="card">
            {
              [
                { name: "ON_ORDER", title: "On Order" },
                { name: "DELIVERED", title: "Delivered" },
                { name: "DELIVER_EXPECTED", title: "Delivery Expected" },
                { name: "LOI", title: "LOI" },
                { name: "OPTION", title: "Option" },
                { name: "RUMOURS", title: "Rumours" },
                { name: "CANCELLED", title: "Cancelled" },
              ].map((status, idx) => {
                const s = status.name
                const t = status.title
                return (
                  <Fragment key={`order_detail_${idx}`}>
                    {ordersData.filter(d => d.tdStatus === s).length > 0 &&
                      <Tabs.TabPane tab={`${t} (${ordersData.filter(d => d.tdStatus === s).length})`} key={s}>
                        <Table
                          className="gx-table-responsive"
                          columns={orderbookColumns}
                          rowKey="id"
                          dataSource={ordersData.filter(d => d.tdStatus === s) || []}
                          sortDirections={["ascend", "descend", "ascend"]}
                          pagination={{ hideOnSinglePage: true }}
                        />
                      </Tabs.TabPane>
                    }
                  </Fragment>
                )
              })
            }
          </Tabs>
        </Widget>
      </div>
    );
  };

  const Facilities = () => {
    const facilitiesColumns = [
      {
        title: "Type",
        dataIndex: "type",
        render: (text, record) => <span>{text.replace("_", " ")}</span>,
      },
      {
        title: "Name",
        dataIndex: "facilityName",
      },
      {
        title: "Lifting Capacity [t]",
        dataIndex: "dwt",
      },
      {
        title: "Draft [m]",
        dataIndex: "height",
      },
      {
        title: "Length [m]",
        dataIndex: "length",
      },
      {
        title: "Width [m]",
        dataIndex: "width",
      },
      {
        title: "Max Crane SWL [t]",
        dataIndex: "swl",
      },
      {
        title: "Number of Cranes",
        dataIndex: "cranesCount",
      },
    ];
    return (
      <Widget styleName="gx-card-profile">
        <div className="ant-card-head">
          <span className="ant-card-head-title gx-mb-1">Facilities</span>
        </div>
        <div>
          <Table
            className="gx-table-responsive"
            columns={facilitiesColumns}
            rowKey="id"
            dataSource={main.dockList || []}
            pagination={{ hideOnSinglePage: true }}
          />
        </div>
      </Widget>
    );
  };

  const Repair = () => {
    return (
      <div id="repair">
        <Widget styleName="gx-card-profile">
          <div className="ant-card-head">
            <span className="ant-card-head-title gx-mb-1">Repair</span>
          </div>
          <div className="pt0">
            <Row>
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                {`${main.name} also has a repair division.`}
                <br /> <br />
                <a target="_blank" rel="noopener noreferrer" href={`https://www.trusteddocks.com/shipyards/${main.id}-${_.kebabCase(main.name)}`}>
                  <Button type="primary">
                    view repair page by trusteddocks.com<RightOutlined />
                  </Button>
                </a>
              </Col>
            </Row>
          </div>
        </Widget>
      </div>
    )
  }

  const News = () => {
    return (
      <div id="news">
        <Widget styleName="gx-card-profile">
          <div className="ant-card-head">
            <span className="ant-card-head-title gx-mb-1">News</span>
          </div>
          <div className="pt0">
            <Carousel autoplay={true}>
              {news &&
                news.map((n, idx) => {
                  return (
                    <Fragment key={`news_${idx}`}>
                      <div className="gx-media gx-align-items-center">
                        <div className="gx-media-body">
                          <h2 className="gx-mb-1">
                            {n.title}
                          </h2>
                          <p className="gx-text-grey">
                            {`${moment(n.creationDate).format("DD.MMM.YYYY")} (${moment(n.creationDate).fromNow()})`} - {n.user?.name}
                          </p>
                          <p className="gx-text-grey">
                            {n.tagsArray?.map((t, idx) => {
                              return (
                                <Tag key={`tag_${idx}`}>
                                  {t}
                                </Tag>
                              )
                            })
                            }
                          </p>
                          <p className="gx-text-grey">
                            {n.relatedItems?.map((c, idx) => {
                              return (
                                <Fragment key={`item_${idx}`} >
                                  {c.type === 'shipyard' &&
                                    <Link to={`/shipyards/${c.id}-${_.kebabCase(c.name)}`} target="_blank" rel="noopener noreferrer">
                                      <Tag>
                                        {c.name}
                                      </Tag>
                                    </Link>
                                  }
                                </Fragment>
                              )
                            })
                            }
                          </p>
                        </div>
                      </div>
                      <p className="gx-mb-4">
                        <span dangerouslySetInnerHTML={{ __html: n.content }} />
                      </p>
                    </Fragment>
                  )
                })}
            </Carousel>
          </div>
        </Widget>
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>{`GROUP - ${main.name}`}</title>
      </Helmet>
      <Auxiliary>
        <ShipyardHeader shipyard={main} linksToShow={linksToShow} />
        <div className="gx-profile-content">
          <Row>
            <Col xl={18} lg={14} md={14} sm={24} xs={24}>

              {main.description &&
                <ContentAuthorization
                  sectionId="shipyard-company_main_details"
                  permission={pagePermissions.nso_company_description}
                >
                  <About content={main.description} />
                </ContentAuthorization>
              }
              {linksToShow.includes("repair") &&
                <Repair />
              }

              <ContentAuthorization
                sectionId="shipyard-company_map_location"
                permission={pagePermissions.company_map_location}
              >
                <Location />
              </ContentAuthorization>

              {main.dockList?.length > 0 &&
                <ContentAuthorization
                  sectionId="shipyard-company_map_location"
                  permission={pagePermissions.nso_company_facilities}
                >
                  <Facilities />
                </ContentAuthorization>
              }
              {ordersData && ordersData.length > 0 && (
                <ContentAuthorization
                  sectionId="orderer-company_contacts"
                  permission={pagePermissions.nso_company_portfolio}
                >
                  <Portfolio />
                </ContentAuthorization>
              )}
              {ordersData && ordersData.length > 0 && (
                <ContentAuthorization
                  sectionId="orderer-company_contacts"
                  permission={pagePermissions.nso_company_clients}
                >
                  <Clients />
                </ContentAuthorization>
              )}

              {main && main.subCompanies?.length > 0 && (
                <ContentAuthorization
                  sectionId="orderer-company_contacts"
                  permission={{ show: true, mask: isAuthenticated ? false : true }}
                >
                  <SubCompanies />
                </ContentAuthorization>
              )}


              {ordersData && ordersData.length > 0 && (
                <ContentAuthorization
                  sectionId="shipyard-company_contacts"
                  permission={{ show: true, mask: isAuthenticated ? false : true }}
                >
                  <Statistics />
                </ContentAuthorization>
              )}

              {ordersData && ordersData.length > 0 && (
                <ContentAuthorization
                  sectionId="shipyard-company_contacts"
                  permission={{ show: true, mask: isAuthenticated ? false : true }}
                >
                  <Orders />
                </ContentAuthorization>
              )}

              <ContentAuthorization
                sectionId="shipyard-company_main_picture"
                permission={pagePermissions.company_main_picture}
              >
                <PhotoGallery />
              </ContentAuthorization>

              <VideoGallery />

              {linksToShow.includes("news") &&
                <ContentAuthorization
                  sectionId="shipyard-company_main_picture"
                  permission={pagePermissions.nso_company_news}
                >
                  <News />
                </ContentAuthorization>
              }
            </Col>
            <Col xl={6} lg={10} md={10} sm={24} xs={24}>
              <Address address={main.address} email={main.email} />
              <ContentAuthorization
                sectionId="shipyard-company_contacts"
                permission={pagePermissions.company_contacts}
              >
                <Contact contactList={main.contacts} />
              </ContentAuthorization>
            </Col>
          </Row>
        </div>
      </Auxiliary>
    </>
  );
};
export default CompanyGroup;
