import { Col, Form, Select } from "antd";
import { useCallback, useEffect, useState } from "react";
const { Option } = Select;

const TwoStepSelect = ({
  data,
  onStepTwoSelect,
  onStepOneSelect,
  stepOneLabel,
  stepTwoLabel,
  stepOnePlaceholder,
  stepTwoPlaceholder,
  stepOneName,
  stepTwoName,
  setFieldsValue,
  index,
  clearObserver
}) => {
  if (!onStepTwoSelect) onStepTwoSelect = () => {};
  if (!onStepOneSelect) onStepOneSelect = () => {};

  const stepOneOptions = Object.keys(data);
  const [selectedStepOne, setSelectedStepOne] = useState("All Continents");
  const [selectedStepTwo, setSelectedStepTwo] = useState("");

  //custom clear functionality
  const clear = useCallback(() => {
    setSelectedStepOne("");
    setSelectedStepTwo("");
    setFieldsValue({
      [stepOneName]: "All Continents",
      [stepTwoName]: "",
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    clearObserver[index] = clear
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const stepTwoOptions = selectedStepOne ? data[selectedStepOne] : [];

  const handleStepOneChange = (value) => {
    setSelectedStepOne(value);
    //call external hook
    setFieldsValue({
      [stepOneName]: value,
      [stepTwoName]: "",
    });

    setSelectedStepTwo(null)
  };

  const handleStepTwoChange = (value) => {
    setSelectedStepTwo(value)
    //call external hook
    setFieldsValue({
      [stepTwoName]: value,
    });
  };

  return (
    <>
      <Col lg={12} md={24} sm={24} xs={24}>
        <Form.Item label={stepOneLabel} name={stepOneName}>
          <Select
            label={stepOneLabel}
            placeholder={stepOnePlaceholder}
            value={selectedStepOne}
            style={{
              width: "100%",
            }}
            onChange={handleStepOneChange}
          >
            {stepOneOptions.map((item) => {
              return <Option key={item}>{item}</Option>;
            })}
          </Select>
        </Form.Item>
      </Col>
      <Col lg={12} md={24} sm={24} xs={24}>
        <Form.Item label={stepTwoLabel} name={stepTwoName}>
          <Select
            label={stepTwoLabel}
            value={selectedStepTwo}
            placeholder={stepTwoPlaceholder}
            style={{
              width: "100%",
            }}
            onSelect={handleStepTwoChange}
          >
            <Option value=""> All Countries </Option>
            {stepTwoOptions.map((item) => {
              const { label, value, id } = item;
              return (
                <Option key={id} value={value}>
                  {label}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default TwoStepSelect;
