import React, { useMemo } from "react";
import { Card, Col, Collapse, Row, Table } from "antd";
import Auxiliary from "../../util/Auxiliary";
import VesselHeader from "../../components/profile/VesselHeader/index";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import request from "../../util/request";
import Widget from "../../components/Widget/index";
import MapContainer from "../../components/Map";
import _ from "lodash";
import usePageQueries from "./queries";
import { useAuth0 } from "@auth0/auth0-react";
import ContentAuthorization from "../ContentAuthorization";
import CircularProgress from "../../components/CircularProgress";
import moment from "moment";
import { Helmet } from "react-helmet";

const Order = () => {

  let { slug } = useParams();
  let orderId = slug.split("-")[0];

  const { isAuthenticated } = useAuth0();
  const {
    permissions,
    pageDataLoading,
  } = usePageQueries({ isAuthenticated, orderId });

  const {
    data: order,
    isError,
  } = useQuery("order", async () => {
    const response = await request({
      url: `new-ships-api/${isAuthenticated ? "user" : "public"}/order/${orderId}`,
      method: "GET",
    });
    return response;
  });

  const pagePermissions = permissions || {};

  if (pageDataLoading) return <CircularProgress />;
  if (isError) return <div>Sorry an Error occurred</div>;

  const Location = () => {
    const singleVesselData = {
      ...order?.vessel,
      geoJson: order?.vessel?.geoJson ? JSON.parse(order?.vessel?.geoJson) : {},
    }

    return (
      <Card>
        <div id="location">
          <MapContainer
            type="single-vessel"
            singleVesselData={singleVesselData}
            singleShipyardData={order?.shipyard}
            loading={false}
          />
        </div>
      </Card>
    );
  };

  const About = () => {
    const Panel = Collapse.Panel;
    return (
      <Widget styleName="gx-card-profile">
        <div className="ant-card-head">
          <span className="ant-card-head-title gx-mb-1">Details</span>
        </div>

        <div className="gx-pb-md-3">
          <div className="gx-media gx-featured-item">
            <div className="gx-media-body gx-featured-content">
              <div className="gx-featured-content-left">
                <div className="ant-row-flex gx-flex-column">
                  {order.name &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">Name</span>:{" "}
                      <span className="gx-media-body gx-ml-1">
                        {order.name || "-"}
                      </span>
                    </div>
                  }
                  {order.imo && isAuthenticated &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">IMO</span>:{" "}
                      <span className="gx-media-body gx-ml-1">{order.imo}</span>
                    </div>
                  }
                  {order.hullNo &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">Hull No</span>:{" "}
                      <span className="gx-media-body gx-ml-1">
                        {order.hullNo}
                      </span>
                    </div>
                  }
                  {order.length &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">Length</span>:{" "}
                      <span className="gx-media-body gx-ml-1">
                        {order.length} m
                      </span>
                    </div>
                  }
                  {order.width &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">Width</span>:{" "}
                      <span className="gx-media-body gx-ml-1">{order.width} m</span>
                    </div>
                  }
                  {order.depth &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">Depth</span>:{" "}
                      <span className="gx-media-body gx-ml-1">
                        {order.depth} m
                      </span>
                    </div>
                  }
                  {order.draught &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">Draught</span>:{" "}
                      <span className="gx-media-body gx-ml-1">
                        {order.draught} m
                      </span>
                    </div>
                  }
                  {order.teu &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">TEU</span>:{" "}
                      <span className="gx-media-body gx-ml-1">{order.teu}</span>
                    </div>
                  }
                  {order.deadweight &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">DeadWeight</span>:{" "}
                      <span className="gx-media-body gx-ml-1">
                        {order.deadweight}
                      </span>
                    </div>
                  }
                  {order.grossTonnage &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">Gross Tonnage</span>:{" "}
                      <span className="gx-media-body gx-ml-1">
                        {order.grossTonnage}
                      </span>
                    </div>
                  }
                  {order.bollardPul &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">Bollard Pul</span>:{" "}
                      <span className="gx-media-body gx-ml-1">
                        {order.bollardPul}
                      </span>
                    </div>
                  }
                  {order.capacityBale &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">Capacity Bale</span>:{" "}
                      <span className="gx-media-body gx-ml-1">
                        {order.capacityBale}
                      </span>
                    </div>
                  }
                  {order.capacityGrain &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">Capacity Grain</span>:{" "}
                      <span className="gx-media-body gx-ml-1">
                        {order.capacityGrain}
                      </span>
                    </div>
                  }
                  {order.capacityLiquid &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">Capacity Liquid</span>:{" "}
                      <span className="gx-media-body gx-ml-1">
                        {order.capacityLiquid}
                      </span>
                    </div>
                  }
                  {order.cargoHandling &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">Cargo Handling</span>:{" "}
                      <span className="gx-media-body gx-ml-1">
                        {order.cargoHandling}
                      </span>
                    </div>
                  }
                  {order.cars &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">Cars</span>:{" "}
                      <span className="gx-media-body gx-ml-1">
                        {order.cars}
                      </span>
                    </div>
                  }
                  {order.classSociaty &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">Class Sociaty</span>:{" "}
                      <span className="gx-media-body gx-ml-1">
                        {order.classSociaty}
                      </span>
                    </div>
                  }
                  {order.iceClass &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">Ice Class</span>:{" "}
                      <span className="gx-media-body gx-ml-1">
                        {order.iceClass}
                      </span>
                    </div>
                  }
                  {order.laneMeter &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">Lane Meter</span>:{" "}
                      <span className="gx-media-body gx-ml-1">
                        {order.laneMeter}
                      </span>
                    </div>
                  }
                  {order.machineryDesignName &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">Machinery Design Name</span>:{" "}
                      <span className="gx-media-body gx-ml-1">
                        {order.machineryDesignName}
                      </span>
                    </div>
                  }

                  {order.machineryTypeName &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">Machinery Type Name</span>:{" "}
                      <span className="gx-media-body gx-ml-1">
                        {order.machineryTypeName}
                      </span>
                    </div>
                  }
                  {order.machineryPower &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">Machinery Power</span>:{" "}
                      <span className="gx-media-body gx-ml-1">
                        {order.machineryPower} kw
                      </span>
                    </div>
                  }
                  {order.passenger &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">Passenger</span>:{" "}
                      <span className="gx-media-body gx-ml-1">
                        {order.passenger}
                      </span>
                    </div>
                  }
                  {order.reeferplugs &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">Reeferplugs</span>:{" "}
                      <span className="gx-media-body gx-ml-1">
                        {order.reeferplugs}
                      </span>
                    </div>
                  }
                  {order.serviceSpeed &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">Service Speed</span>:{" "}
                      <span className="gx-media-body gx-ml-1">
                        {order.serviceSpeed}
                      </span>
                    </div>
                  }
                  {order.note &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">Note</span>:{" "}
                      <span className="gx-media-body gx-ml-1">
                        {order.note}
                      </span>
                    </div>
                  }
                  {order.trailer &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">Trailer</span>:{" "}
                      <span className="gx-media-body gx-ml-1">
                        {order.trailer}
                      </span>
                    </div>
                  }
                  {order.typeOfDrive &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">Type of Drive</span>:{" "}
                      <span className="gx-media-body gx-ml-1">
                        {order.typeOfDrive}
                      </span>
                    </div>
                  }
                  {order.price &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">Price</span>:{" "}
                      <span className="gx-media-body gx-ml-1">
                        {order.price} TUSD
                      </span>
                    </div>
                  }
                  {order.chartererName &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">Charterer</span>:{" "}
                      <span className="gx-media-body gx-ml-1">
                        {order.chartererName}
                      </span>
                    </div>
                  }
                  {order.financierName &&
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <span className="gx-ml-1">Financier</span>:{" "}
                      <span className="gx-media-body gx-ml-1">
                        {order.financierName}
                      </span>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>

          {order.details && Object.keys(order.details).length > 0 &&
            <Collapse accordion defaultActiveKey={"d_0"}>
              {Object.keys(order.details).map((k, index) => {
                return (
                  <Panel header={k} key={`d_${index}`}>
                    <table className="gx-table-responsive">
                      <tbody>
                        {order.details[k].map((v, idx) => {
                          return (
                            <tr key={`tr_${index}_${idx}`}>
                              <td>{_.startCase(v.detailKey)}</td>
                              <td>{v.detailValue}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </Panel>
                )
              })}
            </Collapse>
          }
        </div>
      </Widget>
    )
  }

  const Sisters = () => {
    const orderbookColumns = useMemo(
      () => [
        {
          title: "Imo",
          dataIndex: "imo",
          sorter: (a, b) => (a.imo > b.imo ? 1 : -1),
          render: (text, record) => (
            <a
              href={`/app/orders/${record.id}-${_.kebabCase(text)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {text}
            </a>
          ),
        },
        {
          title: "Hull No.",
          dataIndex: "hullNo",
          sorter: (a, b) => (a.hullNo > b.hullNo ? 1 : -1),
        },
        {
          title: "Orderer",
          dataIndex: "orderer",
          key: "orderer",
          render: (text, record) => (
            <>
              {record.orderer?.ordererUrl && (
                <span className="gx-link">
                  <a
                    href={`/app${record.orderer?.ordererUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {record.orderer?.name}
                  </a>
                </span>
              )}

              {!record.orderer?.ordererUrl && <>{record.orderer?.name}</>}
            </>
          ),
          sorter: (a, b) => a.orderer?.name > b.orderer?.name,
        },
        {
          title: "Vessel Type",
          dataIndex: "tdVesselType",
          render: (text, record) => (
            <a
              href={`/app/orders/${record.id}-${_.kebabCase(text)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              {text}{" "}
            </a>
          ),
          sorter: (a, b) => a.tdVesselType > b.tdVesselType,
        },
        {
          title: "Name",
          dataIndex: "name",
          sorter: (a, b) => (a.name > b.name ? 1 : -1),
          render: (text, record) => (
            <a
              href={`/app/orders/${record.id}-${_.kebabCase(text)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              {text}{" "}
            </a>
          ),
        },
        {
          title: "Length",
          dataIndex: "length",
          key: "length",
          defaultSortOrder: "descend",
          sorter: (a, b) => (a["length"] > b["length"] ? 1 : -1),
        },
        {
          title: "Width",
          dataIndex: "width",
          key: "width",
          sorter: (a, b) => (a.width > b.width ? 1 : -1),
        },
        {
          title: "DWT",
          dataIndex: "deadweight",
          key: "deadweight",
          defaultSortOrder: "descend",
          sorter: (a, b) => (a.deadweight > b.deadweight ? 1 : -1),
        },
        {
          title: "TEU",
          dataIndex: "teu",
          key: "teu",
          sorter: (a, b) => (a.teu > b.teu ? 1 : -1),
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          sorter: (a, b) => (a.status > b.status ? 1 : -1),
        },
        {
          title: "Order Date",
          dataIndex: "orderDate",
          key: "orderDate",
          render: (d) => (d ? moment(d).format("DD.MMM.YYYY") : ""),
          sorter: (a, b) => (moment(a).isAfter(moment(b)) ? 1 : -1),
        },
        {
          title: "Delivery Date",
          dataIndex: "deliveryDate",
          key: "deliveryDate",
          render: (d) => (d ? moment(d).format("DD.MMM.YYYY") : ""),
          sorter: (a, b) => (moment(a).isAfter(moment(b)) ? 1 : -1),
        },
      ],
      []
    );

    return (
      <div id="#sisters">
        <Widget styleName="gx-card-profile">
          <div className="ant-card-head">
            <span className="ant-card-head-title gx-mb-1">Sister Orders</span>
          </div>
          <Table
            className="gx-table-responsive"
            columns={orderbookColumns}
            rowKey="id"
            dataSource={order.sisters || []}
            sortDirections={["ascend", "descend", "ascend"]}
            pagination={{ hideOnSinglePage: true }}
          />
        </Widget>
      </div>
    );
  };



  const Shipyard = ({ shipyard, title }) => {
    return (
      <Widget styleName="gx-card-profile">
        <div className="ant-card-head">
          <span className="ant-card-head-title gx-mb-1">{title}</span>
        </div>
        <div className="gx-pb-md-3">
          <div className="">
            {shipyard?.logo &&
              <img
                className="gx-rounded-lg"
                src={shipyard.logo}
                alt={shipyard.name}
              />
            }
            <div className="gx-media-body gx-featured-content">
              <div className="gx-featured-content-left">
                <a
                  href={`/app/shipyards/${shipyard.id}-${_.kebabCase(shipyard.name)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h3 className="gx-text-primary gx-mt-sm-auto gx-mb-0 gx-pointer">{shipyard.name}</h3>
                </a>
              </div>
              <div className="gx-featured-content-right gx-profile-content-right">
                <a href={shipyard.url} target="_blank" rel="noreferrer">
                  <p className="gx-text-primary gx-text-truncate gx-mt-sm-auto gx-mb-0 gx-pointer">
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Widget>
    )
  };

  const Orderer = () => {
    return (
      <Widget styleName="gx-card-profile">
        <div className="ant-card-head">
          <span className="ant-card-head-title gx-mb-1">Ship Orderer</span>
        </div>
        <ContentAuthorization
          sectionId="orderer-company_contacts"
          permission={{ show: true, mask: isAuthenticated ? false : true }}
        >

          <div className="gx-pb-md-3">
            <div className="">
              {order?.orderer?.logo &&
                <img
                  className="gx-rounded-lg"
                  src={order.orderer.logo}
                  alt={order.orderer.name}
                />
              }
              {order?.orderer &&
                <div className="gx-media-body gx-featured-content">
                  <div className="gx-featured-content-left">
                    <a
                      href={`/app/orderers/${order.orderer.idStr}-${_.kebabCase(order.orderer.name)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h3 className="gx-text-primary gx-mt-sm-auto gx-mb-0 gx-pointer">{order.orderer.name}</h3>
                    </a>
                    <div className="ant-row-flex">
                      <div className="gx-media gx-text-grey gx-mb-1">
                        <span className="gx-media-body gx-ml-1"></span>
                      </div>
                    </div>
                  </div>
                  <div className="gx-featured-content-right gx-profile-content-right">
                    <p className="gx-text-primary gx-text-truncate gx-mt-sm-auto gx-mb-0 gx-pointer">
                    </p>
                  </div>
                </div>
              }
            </div>
          </div>
        </ContentAuthorization>
      </Widget>
    )
  };

  return (
    <>
      <Helmet>
        <title>{`Vessel Order - ${order?.name}`}</title>
      </Helmet>
      {order && (
        <Auxiliary>
          <VesselHeader vessel={order} />
          <div className="gx-profile-content">
            <Row>
              <Col xl={16} lg={14} md={14} sm={24} xs={24}>
                <Row>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <ContentAuthorization
                      permission={pagePermissions.nso_order_details}
                    >
                      <About />
                    </ContentAuthorization>
                  </Col>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    {order?.vessel?.position &&
                      <ContentAuthorization
                        permission={pagePermissions.nso_order_position}
                      >
                        <Location />
                      </ContentAuthorization>
                    }
                  </Col>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    {order?.sisters?.length > 0 &&
                      <ContentAuthorization
                        permission={pagePermissions.nso_order_vessel_details}
                      >
                        <Sisters />
                      </ContentAuthorization>
                    }
                  </Col>
                </Row>
              </Col>

              <Col xl={8} lg={10} md={10} sm={24} xs={24}>
                {order?.orderer &&
                  < Row >
                    <Col xl={24} lg={24} md={24} sm={12} xs={24}>
                      <Orderer />
                    </Col>
                  </Row>
                }
                {order?.shipyard &&
                  <Row>
                    <Col xl={24} lg={24} md={24} sm={12} xs={24}>
                      <Shipyard shipyard={order.shipyard} title="Ship Builder" />
                    </Col>
                  </Row>
                }
                {order?.shipyardInvolved &&
                  <Row>
                    <Col xl={24} lg={24} md={24} sm={12} xs={24}>
                      <Shipyard shipyard={order.shipyardInvolved} title="Shipyard Involved" />
                    </Col>
                  </Row>
                }
              </Col>
            </Row>
          </div>
        </Auxiliary >
      )}
    </>
  );
};

export default Order;
