import { useEffect } from "react";
import { useQuery } from "react-query";
import request from "../util/request";

const QUERY_KEYS = {
  COUNTRIES: "countries",
};

const usePageQueries = ({ isAuthenticated, params }) => {
  const queryKey = [QUERY_KEYS.COUNTRIES, params];
  const { data: data, refetch } = useQuery(
    queryKey,
    async () => {
      const response = await request({
        url: `api/v1/orders`,
        // params: params,
        method: "GET",
      });
      return response;
    },
    { refetchOnMount: false, refetchOnWindowFocus: false }
  );

  useEffect(() => {
    refetch();
  }, [refetch, params]);

  return {
    data,
  };
};

export default usePageQueries;
