import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import request from "../../util/request";

const QUERY_KEYS = {
  COUNTRIES: "countries",
};

const usePageQueries = ({ isAuthenticated, categoryDate, vesselType }) => {

  const {
    data: countryData,
    refetch
  } = useQuery(
    QUERY_KEYS.COUNTRIES,
    async () => {
      const response = await request({
        url: `new-ships-api/${isAuthenticated ? "user" : "public"
          }/statistics/countries?category=${categoryDate}&vesselType=${vesselType}`,
        method: "GET",
      });
      return response;
    },
    { refetchOnMount: false, refetchOnWindowFocus: false }
  );

  const xAxisData = useMemo(() => {
    if (!countryData) return;
    let ret = new Set()
    countryData.forEach(d => {
      let ref = d.worldRepairIndexDateCountVos;
      for (let j = 0, len = ref.length; j < len; j++) {
        let vesselVisits = ref[j];
        ret.add(vesselVisits.yearWeek);
      }
    });
    return Array.from(ret).sort()
  }, [countryData]);

  useEffect(() => { refetch() }, [categoryDate, vesselType, refetch])

  return {
    xAxisData,
    countryData
  };
};

export default usePageQueries;
