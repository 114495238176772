import React from "react";
import FsLightbox from "fslightbox-react";

const GalleryAndLightbox = ({ urlObjects, type }) => {
  const [lightboxIsOpen, setLightboxIsOpen] = React.useState(false);
  const [selectedUrl, setSelectedUrl] = React.useState(null);

  const content = urlObjects || [];

  return (
    <>
      <div className="gx-pt-2">
        <ul className="gx-fnd-list gx-mb-0">
          {content.map((urlObject, index) =>
            <li className="gx-mb-2" key={index}>
              <div className="gx-user-fnd">
              <img
                key={index}
                src={urlObject.thumbnail}
                alt={urlObject.alt}
                style={{ objectFit: type === "video" ? "none" : "", cursor: "pointer" }}
                onClick={() => {
                  setSelectedUrl(urlObject.url);
                  setLightboxIsOpen(true);
                }}/>
              </div>
            </li>
          )
          }
        </ul>
      </div>
      {lightboxIsOpen && (
        <FsLightbox
          onClose={() => setLightboxIsOpen(false)}
          openOnMount={true}
          sources={[selectedUrl]}
        />
      )}
    </>
  );
};

export default GalleryAndLightbox;
