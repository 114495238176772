import {
  Button,
  Form,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Divider,
  Card,
  Cascader,
} from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { useRef, useEffect, useState } from "react";
import { HotTable } from "@handsontable/react";
import Widget from "components/Widget/index";
import { useAuth0 } from "@auth0/auth0-react";
import request from "../../util/request";
import { tableColumns, shipTypesSimple } from "../../shared/const";
import { useMutation, useQuery } from "react-query";

const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const AddShipForm = () => {
  const tableRef = useRef(null);

  const { isAuthenticated } = useAuth0();
  const [selectedShipyard, setSelectedShipyard] = useState(null);
  const [selectedVesselType, setSelectedVesselType] = useState(null);
  const [selectedShip, setSelectedShip] = useState(null);
  const [params, setParams] = useState({
    sortColumn: "id",
    sortOrder: "asc",
    per_page: 1000,
  });

  const { data: shipyardList } = useQuery(
    "shipyardList",
    async () => {
      const response = await request({
        url: `api/v1/companies/shipyards`,
        method: "GET",
      });
      return response;
    },
    { refetchOnMount: false, refetchOnWindowFocus: false }
  );

  const { data: shipyardOrders } = useQuery(
    ["shipyardOrders", selectedShipyard, selectedVesselType],
    async () => {
      let url = `api/v1/orders?limit=1000`;
  
      if (selectedShipyard) {
        url += `&shipyard_id=${selectedShipyard}`;
      }
  
      if (selectedVesselType) {
        url += `&vessel_type=${selectedVesselType}`;
      }
  
      const response = await request({
        url: url,
        method: "GET",
      });
      
      return response;
    },
    { refetchOnMount: false, refetchOnWindowFocus: false }
  );
  
  const addOrderMutation = useMutation({
    mutationKey: "addOrder",
    mutationFn: async (values) => {
      const response = await request({
        url: `api/v1/orders/`,
        method: "POST",
        data: values,
      });
      return response;
    },
  });

  const vesselTableData = shipyardOrders || [];

  console.log("vesselTableData", selectedShipyard, selectedVesselType, vesselTableData);

  useEffect(() => {
    if (vesselTableData) {
      tableRef?.current?.hotInstance.loadData(vesselTableData);
    }
  }, [vesselTableData]);

  const onFinish = (values) => {
    console.log("Success:", values);
    addOrderMutation.mutate({
      vessel_name: values.shipName,
      shipyard_id: values.shipyard,
      orderer_id: values.shipyard,
      vessel_length: values.shipLength,
      vessel_width: values.shipWidth,
      vessel_imo: values.imo,
      order_data: [],
    });
  };

  return (
    <div>
      <Row justify="center" align="middle">
        <Col span={6}>
          <Form>
            <Form.Item
              label="Shipyard"
              name="shipyard"
            >
              <Select
                defaultValue=""
                options={shipyardList?.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                allowClear
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(value) =>
                  Number(value)
                    ? setSelectedShipyard(Number(value))
                    : setSelectedShipyard(false)
                }
                showSearch
              />
            </Form.Item>

            <Form.Item
              label="Type"
              name="shipType"
            >
              <Cascader
                defaultValue=""
                options={shipTypesSimple}
                onChange={(value) => {
                  console.log(value);
                  setSelectedVesselType(value);
                }}  
              />
            </Form.Item>
          </Form>
        </Col>

        <Col span={18}>
          <Widget styleName="mb0">
            <HotTable
              ref={tableRef}
              filters={true}
              contextMenu={true}
              dropdownMenu={true}
              height="340px"
              rowHeaders={true}
              columnSorting={true}
              beforeColumnSort={function (
                currentSortConfig,
                destinationSortConfigs
              ) {}}
              afterColumnSort={function (
                currentSortConfig,
                destinationSortConfigs
              ) {
                if (
                  params.sortColumn ===
                  tableColumns[destinationSortConfigs[0]?.column]?.data
                ) {
                  setParams({
                    ...params,
                    sortColumn:
                      tableColumns[destinationSortConfigs[0]?.column]?.data,
                    sortOrder: params.sortOrder === "asc" ? "desc" : "asc",
                  });
                } else {
                  setParams({
                    ...params,
                    sortColumn:
                      tableColumns[destinationSortConfigs[0]?.column]?.data,
                    sortOrder: destinationSortConfigs[0]?.sortOrder,
                  });
                }
              }}
              autoWrapRow={true}
              autoWrapCol={true}
              licenseKey="non-commercial-and-evaluation" // for non-commercial use only
              columns={tableColumns}
              afterSelectRows={(from) => {
                console.log(
                  tableRef.current.hotInstance.getSourceDataAtRow(from.row)
                );
                setSelectedShip(
                  tableRef.current.hotInstance.getSourceDataAtRow(from.row)
                );
                // console.log(tableRef.current?.getSourceDataAtRow(from));
              }}
              afterChange={function (change, source) {
                console.log(
                  "11111",
                  change,
                  source,
                  tableRef.current?.props?.data
                );

                if (source === "Autofill.fill") {
                  console.log("22222", change);
                  const [row, prop, oldVal, newVal] = change[0];
                  console.log("3333", row, prop, oldVal, newVal);
                  console.log("444", tableRef.current?.getSourceDataAtRow(row));
                }
              }}
            />
          </Widget>
        </Col>
      </Row>
      <Divider />

      {selectedShip ? (
        <>
          <Row>
            <Col span={24}>
              <Widget>
                <Card>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <h1>{selectedShip?.vessel_name}</h1>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        type="primary"
                        onClick={() => setSelectedShip(null)}
                      >
                        Hide
                      </Button>
                      <Button
                        type="primary"
                        icon={<CopyOutlined />}
                        onClick={() => setSelectedShip(null)}
                      >
                        Copy Data
                      </Button>
                      <Button danger onClick={() => setSelectedShip(null)}>
                        Delete
                      </Button>
                    </div>
                  </div>
                  <p>
                    Length:{" "}
                    {selectedShip?.vessel_length + " m" || "Not specified"}
                  </p>
                  <p>
                    Width:{" "}
                    {selectedShip?.vessel_width + " m" || "Not specified"}
                  </p>
                  <p>Status: {selectedShip?.status || "Not specified"}</p>
                  <p>Class: {selectedShip?.class_sociaty || "Not specified"}</p>
                  <p>
                    Shipyard: {selectedShip?.shipyard_name || "Not specified"}
                  </p>
                  <p>Hull: {selectedShip?.hull_no || "Not specified"}</p>
                </Card>
              </Widget>
            </Col>
          </Row>
          <Divider />
        </>
      ) : null}
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row>
          <Col span={8}>
            <Form.Item
              label="Name"
              name="shipName"
              rules={[
                {
                  required: true,
                  message: "Please specify the ship`s name.",
                },
              ]}
            >
              <Input disabled={!selectedShipyard} />
            </Form.Item>
            <Form.Item
              label="IMO"
              name="imo"
              rules={[
                {
                  required: true,
                  message: "Please specify the ship`s IMO number.",
                },
              ]}
            >
              <Input disabled={!selectedShipyard} />
            </Form.Item>

            <Form.Item
              label="MMSI"
              name="mmsi"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input disabled={!selectedShipyard} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Length"
              name="shipLength"
              rules={[
                {
                  required: true,
                  message: "Please specify the ship`s length.",
                },
              ]}
            >
              <Input disabled={!selectedShipyard} />
            </Form.Item>

            <Form.Item
              label="Width"
              name="shipWidth"
              rules={[
                {
                  required: true,
                  message: "Please specify the ship`s width.",
                },
              ]}
            >
              <Input disabled={!selectedShipyard} />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={10}>
            <Form.Item
              label="Number of Ships"
              name="numberOfShips"
              extra={
                <p style={{ marginTop: "8px" }}>
                  Select a number of entries you want to create. You can edit
                  each entry details after creation.
                </p>
              }
            >
              <InputNumber
                disabled={!selectedShipyard}
                min={1}
                defaultValue={1}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <Button
                  disabled={!selectedShipyard}
                  type="primary"
                  htmlType="submit"
                >
                  Add new ship
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddShipForm;
