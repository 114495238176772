import { apiRequest } from "../actions/api.action";
import { getHost } from "../../util/host";
import {
  FETCH_SHIPYARD_BY_ID,
  FETCH_SHIPYARD_BY_ID_FAILURE,
  FETCH_SHIPYARD_BY_ID_SUCCESS,
} from "../actions/Shipyard.action";

export const shipyardFlow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === FETCH_SHIPYARD_BY_ID) {
      let url = `${getHost()}/new-ships-api/public/shipyards/${
        action.payload.id
      }`;

      dispatch(
        apiRequest(
          "GET",
          url,
          null,
          FETCH_SHIPYARD_BY_ID_SUCCESS,
          FETCH_SHIPYARD_BY_ID_FAILURE
        )
      );
    }
  };

export const shipyardMdl = [shipyardFlow];
