import { FETCH_SHIPYARD_BY_ID_SUCCESS } from "../actions/Shipyard.action";

const initialState = {
  current: {},
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SHIPYARD_BY_ID_SUCCESS:
      return {
        ...state,
        current: action.payload
      }
    default:
      return state;
  }
}