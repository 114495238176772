import { Button, Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import Widget from "../../components/Widget";

const RegisterSuccess = () => {
  return (
    <div className="gx-main-content">
      <Row>
        <Col offset={4} xl={16} className="gx-d-none gx-d-sm-block">
          <Widget styleName="gx-bg-dark-primary">
            <div className="gx-flex-row gx-justify-content-center gx-mb-3 gx-mb-md-4">
              <img
                alt=""
                src="/app/assets/images/logo-white.png"
                style={{ height: "60px" }}
              />
            </div>
            <div className="gx-text-center">
              <h2 className="h3 gx-mb-3 gx-text-white">Registration pending</h2>
              <p className="gx-text-white gx-mb-3">It is necessary to review your registration to ensure the integrity of new-ships platform.</p>
              <p className="gx-text-white gx-mb-3">You will receive an email when the review has been completed.</p>
              <Link to="/home">
                <Button size="large" className="gx-btn-secondary gx-mt-md-5 gx-mb-1">go to home page</Button>
              </Link>
            </div>
          </Widget>
        </Col>
      </Row>
    </div>
  )
};

export default RegisterSuccess;
