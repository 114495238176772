import { FETCH_NEW_BUILDING_SHIPYARDS_SUCCESS, FETCH_NEW_BUILDING_SHIPYARD_STAT_SUCCESS } from "../actions";
import { FETCH_NEW_BUILDING_ORDERERS_SUCCESS, FETCH_VESSELS_FOR_HOME_SUCCESS } from "../actions/Vessel.action";

const initialState = {
  list: [],
  stat: [],
  orderers: [],
  vessels:{}
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_NEW_BUILDING_SHIPYARDS_SUCCESS:
      return {
        ...state,
        list: action.payload
      }
    case FETCH_NEW_BUILDING_SHIPYARD_STAT_SUCCESS:
      return {
        ...state,
        stat: action.payload
      }
    case FETCH_NEW_BUILDING_ORDERERS_SUCCESS:
      return {
        ...state,
        orderers: action.payload
      }
    case FETCH_VESSELS_FOR_HOME_SUCCESS:
      return {
        ...state,
        vessels: action.payload
      }
    default:
      return state;
  }
}