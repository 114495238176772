import React from "react";
import {Cell, Pie, PieChart as RePieCharts, ResponsiveContainer} from "recharts";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  name,
  index
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.3;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${name}`}
    </text>
  );
};

const PieChart = ({data, title}) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
        }}
      >
        <h4
          style={{
            marginRight: "auto",
            marginLeft: "auto",
            color: "rgb(84 84 84 / 62%)",
          }}
        >
          {title}
        </h4>
        <ResponsiveContainer width="100%" height={350}>
          <RePieCharts>
            <Pie
              dataKey="value"
              data={data}
              labelLine={true}
              label={renderCustomizedLabel}
              outerRadius={90}
              fill="#8884d8"
            >
              {data.map((entry, index) => (
                <Cell key={index} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </RePieCharts>
        </ResponsiveContainer>
      </div>
    );
  }

export default PieChart
