import { FETCH_TRANSLATION_SUCCESS } from "../actions/translation.action";

const initialState = {
  en: {
  },
  cn: {
  },
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TRANSLATION_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state;
  }
}