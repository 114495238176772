import { apiRequest } from "../actions/api.action";
import {
  FETCH_NEW_BUILDING_SHIPYARDS,
  FETCH_NEW_BUILDING_SHIPYARDS_FAILURE,
  FETCH_NEW_BUILDING_SHIPYARDS_SUCCESS,
  FETCH_NEW_BUILDING_SHIPYARD_STAT,
  FETCH_NEW_BUILDING_SHIPYARD_STAT_FAILURE,
  FETCH_NEW_BUILDING_SHIPYARD_STAT_SUCCESS,
} from "../actions";
import { getHost } from "../../util/host";

export const mapFlow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === FETCH_NEW_BUILDING_SHIPYARDS) {
      const boundingBox = action.payload.boundingBox;
      let url = `${getHost()}/new-ships-api/${
        action.payload.isAuthenticated ? "user" : "public"
      }/recommendation?vesselType=${action.payload.vesselType}`;
      if (boundingBox?._sw?.lng) {
        url = `${getHost()}/new-ships-api/${
          action.payload.isAuthenticated ? "user" : "public"
        }/recommendation?vesselType=${action.payload.vesselType}&left=${
          boundingBox._sw.lng
        }&right=${boundingBox._ne.lng}&top=${boundingBox._ne.lat}&bottom=${
          boundingBox._sw.lat
        }&currentTab=${action.payload.currentTab}&newsIds=${
          action.payload.newsIds
        }`;
      }
      dispatch(
        apiRequest(
          "GET",
          url,
          null,
          FETCH_NEW_BUILDING_SHIPYARDS_SUCCESS,
          FETCH_NEW_BUILDING_SHIPYARDS_FAILURE
        )
      );
    }

    if (action.type === FETCH_NEW_BUILDING_SHIPYARD_STAT) {
      let url = `${getHost()}/new-ships-api/public/recommendation/${
        action.payload.shipyardId
      }/stat`;
      dispatch(
        apiRequest(
          "GET",
          url,
          null,
          FETCH_NEW_BUILDING_SHIPYARD_STAT_SUCCESS,
          FETCH_NEW_BUILDING_SHIPYARD_STAT_FAILURE
        )
      );
    }
  };

export const mapMdl = [mapFlow];
