import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "antd";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

const ContentAuthorization = ({ permission, children, sectionId, text, showButton = true }) => {

  const { isAuthenticated } = useAuth0();

  const [style, setStyle] = useState({
    display: "none",
    position: "absolute",
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "auto",
    marginBottom: "auto",
    width: 'fit-content'
  });
  
  const { show, mask } = permission || {show:true, mask:true};

  let showText = text || isAuthenticated ? "Need Addon" : "Login / Register"

  if (!show) return null;
  if (mask)
    return (
      <div
        style={{ position: "relative", cursor: "pointer" }} title={showText}
        onMouseEnter={(e) => {
          setStyle((prevStyle) => ({ ...prevStyle, display: "block" }));
        }}
        onMouseLeave={(e) => {
          setStyle((prevStyle) => ({ ...style, display: "none" }));
        }}
      >
        <div style={{ userSelect: "none", filter: `blur(5px)` }}>
          {children}
        </div>
        {showButton &&
          <Link to="/auth">
            <Button type="primary" style={style}>
              {showText}
            </Button>
          </Link>
        }
      </div>
    );
  if (!mask) return <Fragment>{children}</Fragment>;
};

export default ContentAuthorization;
