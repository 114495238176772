import React from "react";
import Widget from "components/Widget";

const MyCompany = ({ company }) => {
  return (
    <div id="contact">
      <Widget styleName="gx-card-profile-sm">
        <span style={{ fontWeight: 600 }}>
          {company.url &&
            <a href={`/app${company.url}`}>
              {company.name}
            </a>
          }
          {!company.url &&
            <>
              {company.name}
            </>
          }
        </span>
        {company.description &&
          <p style={{ textOverflow: "ellipsis", height: "4.5em", lineHeight: "1.5em", overflow: "hidden" }} >{company.description}</p>
        }
        {
          company.address?.street &&
          <div className="gx-media gx-align-items-center gx-flex-nowrap">
            <div className="gx-mr-3">
              <i className={`icon icon-company gx-fs-xxl gx-text-grey`} />
            </div>
            <div className="gx-media-body">
              <p className="gx-mb-0 gx-fs-sm">
                {company.address.street} {company.address.streetNumber ? company.address.streetNumber : ''}
                <br />
                {company.address.zip ? company.address.zip : ''} {company.address.city ? company.address.city : ''}
                <br />
                {company.address.country ? company.address.country.name : ''}
              </p>
            </div>
          </div>
        }
        {
          company.address?.phone &&
          <div className="gx-media gx-align-items-center gx-flex-nowrap">
            <div className="gx-mr-3">
              <i className={`icon icon-phone gx-fs-xxl gx-text-grey`} />
            </div>
            <div className="gx-media-body">
              <p className="gx-mb-0 gx-fs-sm">{company.address.phone}</p>
            </div>
          </div>
        }
        {
          company.address?.web &&
          <div className="gx-media gx-align-items-center gx-flex-nowrap">
            <div className="gx-mr-3">
              <i className={`icon icon-navigation gx-fs-xxl gx-text-grey`} />
            </div>
            <div className="gx-media-body">
              <p className="gx-mb-0 gx-fs-sm">{company.address.web}</p>
            </div>
          </div>
        }
      </Widget>
    </div>
  );
};

export default MyCompany;
