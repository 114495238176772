import React, { useMemo } from "react";
import { Button, Card, Col, Row, Table } from "antd";
import Auxiliary from "../../util/Auxiliary";
import MapContainer from "../../components/Map";
import { useAuth0 } from "@auth0/auth0-react";
import Widget from "../../components/Widget";
import ContentAuthorization from "../ContentAuthorization";
import usePageQueries from "./queries";
import moment from "moment";
import { useIntl } from "react-intl";
import _ from "lodash";
import { DownloadOutlined, LinkOutlined } from "@ant-design/icons";

const NewBuildTracker = () => {
  const intl = useIntl();

  const { isAuthenticated } = useAuth0();

  const {
    permissionsError,
    vessels,
    shipyards,
    ordersData,
    ordersDataError,
  } = usePageQueries({ isAuthenticated });

  const isError =
    permissionsError ||
    ordersDataError;

  if (isError) return <div>Sorry an Error occurred</div>;

  const Location = () => (
    <Card>
      <div id="location">
        <MapContainer
          type="shipyard"
          vessels={vessels}
          shipyards={shipyards}
        />
      </div>
    </Card>
  );


  const Orders = () => {
    const orderbookColumns = useMemo(
      () => [
        {
          title: "Imo",
          dataIndex: "imo",
          sorter: (a, b) => (a.imo > b.imo ? 1 : -1),
          render: (text, record) => (
            <a
              href={`/app/orders/${record.id}-${_.kebabCase(text)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {text}
            </a>
          ),
        },
        {
          title: "Shipyard",
          dataIndex: "shipyard",
          key: "shipyard",
          render: (text, record) => (
            <span className="gx-link">
              <a
                href={`/app/shipyards/${record.shipyard.id}-${_.kebabCase(
                  record.shipyard.name
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {record.shipyard.name}
              </a>
            </span>
          ),
          sorter: (a, b) =>
            a.shipyard.name
              .toLowerCase()
              .localeCompare(b.shipyard.name.toLowerCase()),
        },
        {
          title: "Orderer",
          dataIndex: "orderer",
          key: "orderer",
          render: (text, record) => (
            <>
              {record.orderer?.ordererUrl && (
                <span className="gx-link">
                  <a
                    href={`/app${record.orderer?.ordererUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {record.orderer?.name}
                  </a>
                </span>
              )}

              {!record.orderer?.ordererUrl && <>{record.orderer?.name}</>}
            </>
          ),
          sorter: (a, b) => a.orderer?.name > b.orderer?.name,
        },
        {
          title: "Vessel Type",
          dataIndex: "tdVesselType",
          render: (text, record) => (
            <a
              href={`/app/orders/${record.id}-${_.kebabCase(text)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              { text }{" "}
            </a>
          ),
          sorter: (a, b) => a.tdVesselType > b.tdVesselType,
        },
        {
          title: "Name",
          dataIndex: "name",
          sorter: (a, b) => (a.name > b.name ? 1 : -1),
          render: (text, record) => (
            <a
              href={`/app/orders/${record.id}-${_.kebabCase(text)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              {text}{" "}
            </a>
          ),
        },
        {
          title: "Length",
          dataIndex: "length",
          key: "length",
          defaultSortOrder: "descend",
          sorter: (a, b) => (a["length"] > b["length"] ? 1 : -1),
        },
        {
          title: "Order Date",
          dataIndex: "orderDate",
          key: "orderDate",
          render: (d) => (d ? moment(d).format("DD.MMM.YYYY") : ""),
          sorter: (a, b) => (moment(a).isAfter(moment(b)) ? 1 : -1),
        },
        {
          title: "Delivery Date",
          dataIndex: "deliveryDate",
          key: "deliveryDate",
          render: (d) => (d ? moment(d).format("DD.MMM.YYYY") : ""),
          sorter: (a, b) => (moment(a).isAfter(moment(b)) ? 1 : -1),
        },
        {
          title: "",
          dataIndex: "name",
          render: (text, record) => (
            <a
              href={`/app/orders/${record.id}-${_.kebabCase(text)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button type="primary" icon={<LinkOutlined />}>Order Details</Button>
            </a>
          ),
        },
      ],
      []
    );

    return (
      <div id="orders">
        <Widget styleName="gx-card-profile">
          <div className="ant-card-head">
            <span className="ant-card-head-title gx-mb-1">Last Launches / Sea Trials</span>
          </div>
          {ordersData.filter(d => d.tdStatus === 'DELIVER_EXPECTED').length > 0 &&
            <Table
              className="gx-table-responsive"
              columns={orderbookColumns}
              rowKey="id"
              dataSource={ordersData.filter(d => d.tdStatus === 'DELIVER_EXPECTED') || []}
              sortDirections={["ascend", "descend", "ascend"]}
              pagination={{ hideOnSinglePage: true }}
            />
          }
        </Widget>
      </div>
    );
  };

  return (
    <>
      <Auxiliary>
        <div className="gx-profile-content">
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Location />

              {ordersData && ordersData.length > 0 && (
                <ContentAuthorization
                  sectionId="shipyard-company_contacts"
                  permission={{ show: true, mask: isAuthenticated ? false : true }}
                >
                  <Orders />
                </ContentAuthorization>
              )}
            </Col>
          </Row>
        </div>
      </Auxiliary>
    </>
  );
};
export default NewBuildTracker;
