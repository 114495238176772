import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import URLSearchParams from "url-search-params";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";
import { useAuth0 } from "@auth0/auth0-react";
import AppLocale from "lngProvider";

import MainApp from "./MainApp";
import SignIn from "../SignIn";
import {
  onLayoutTypeChange,
  onNavStyleChange,
  setThemeType,
} from "appRedux/actions/Setting";
import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK,
} from "../../constants/ThemeSetting";
import { fetchTranslation } from "../../appRedux/actions/translation.action";
import Home from "../../routes/Home";
import CircularProgress from "../../components/CircularProgress";
import AuthError from "../AuthError";
import SignUp from "../Signup";
import Order from "../Order";
import Shipyard from "../Shipyard";
import CompanyGroup from "../CompanyGroup";
import Orderer from "../Orderer";
import Vessel from "../Vessel";
import Wrapper from "./Wrapper";
import request from "../../util/request";
import { useQuery } from "react-query";
import Search from "../Search";
import LoginOrRegister from "../LoginOrRegister";
import RegisterSuccess from "../RegisterSuccess";
import News from "../News";
import NewsDetails from "../../components/NewsDetails";

const setLayoutType = (layoutType) => {
  if (layoutType === LAYOUT_TYPE_FULL) {
    document.body.classList.remove("boxed-layout");
    document.body.classList.remove("framed-layout");
    document.body.classList.add("full-layout");
  } else if (layoutType === LAYOUT_TYPE_BOXED) {
    document.body.classList.remove("full-layout");
    document.body.classList.remove("framed-layout");
    document.body.classList.add("boxed-layout");
  } else if (layoutType === LAYOUT_TYPE_FRAMED) {
    document.body.classList.remove("boxed-layout");
    document.body.classList.remove("full-layout");
    document.body.classList.add("framed-layout");
  }
};

const setNavStyle = (navStyle) => {
  if (
    navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
    navStyle === NAV_STYLE_DARK_HORIZONTAL ||
    navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
    navStyle === NAV_STYLE_ABOVE_HEADER ||
    navStyle === NAV_STYLE_BELOW_HEADER
  ) {
    document.body.classList.add("full-scroll");
    document.body.classList.add("horizontal-layout");
  } else {
    document.body.classList.remove("full-scroll");
    document.body.classList.remove("horizontal-layout");
  }
};

let styleSheetLink = document.createElement("link");
styleSheetLink.type = "text/css";
styleSheetLink.rel = "stylesheet";
document.body.appendChild(styleSheetLink);

const App = () => {
  const locale = useSelector(({ settings }) => settings.locale);
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const layoutType = useSelector(({ settings }) => settings.layoutType);
  const themeColor = useSelector(({ settings }) => settings.themeColor);
  const themeType = useSelector(({ settings }) => settings.themeType);
  const isDirectionRTL = useSelector(({ settings }) => settings.isDirectionRTL);
  const translation = useSelector(({ translation }) => translation);
  const redirectLocation = useRef(null);

  const { isLoading, getAccessTokenSilently, isAuthenticated } = useAuth0();

  let [accessToken, setAccessToken] = useState(null);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (!isAuthenticated) return false;
    getAccessTokenSilently().then((token) => {
      localStorage.setItem("apiToken", token);
      setAccessToken(token);
    });
  }, [isAuthenticated, getAccessTokenSilently]);

  const loadingAuthentication = useMemo(() => {
    if (!isAuthenticated) {
      setUserInfo(null);
    }
    if (isLoading) return true;
    if (!isAuthenticated) return false;
    if (!accessToken) return true;
    return false;
  }, [isLoading, isAuthenticated, accessToken]);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (isDirectionRTL) {
      document.documentElement.classList.add("rtl");
      document.documentElement.setAttribute("data-direction", "rtl");
    } else {
      document.documentElement.classList.remove("rtl");
      document.documentElement.setAttribute("data-direction", "ltr");
    }

    if (themeColor) {
      styleSheetLink.href = `/css/${themeColor}.min.css`;
    }
  }, [themeColor, isDirectionRTL]);

  useEffect(() => {
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add("dark-theme");
      styleSheetLink.href = "/css/dark_theme.min.css";
    } else if (document.body.classList.contains("dark-theme")) {
      document.body.classList.remove("dark-theme");
      styleSheetLink.href = "";
    }
  }, [themeType]);

  useEffect(() => {
    if (locale) document.documentElement.lang = locale.locale;
  }, [locale]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.has("theme")) {
      dispatch(setThemeType(params.get("theme")));
    }
    if (params.has("nav-style")) {
      dispatch(onNavStyleChange(params.get("nav-style")));
    }
    if (params.has("layout-type")) {
      dispatch(onLayoutTypeChange(params.get("layout-type")));
    }
  }, [location.search, dispatch, location.pathname]);

  useEffect(() => {
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  }, [layoutType, navStyle]);

  useEffect(() => {
  }, [dispatch]);




  const currentAppLocale = AppLocale[locale.locale];

  const AuthenticatedRoutes = (
    <ConfigProvider
      locale={currentAppLocale.antd}
      direction={isDirectionRTL ? "rtl" : "ltr"}
    >
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={translation[currentAppLocale.locale]}
        onError={(err) => {
          if (err.code === "MISSING_TRANSLATION") {
            return;
          }
          throw err;
        }}
      >
        <Switch>
          <Route path="/">
            <MainApp />
          </Route>
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  );

  // keep track of page changes
  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname === "/auth" ||
      location.pathname === "/login-or-register"
    )
      return;
    localStorage.setItem("redirect", location.pathname);
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //schedule a redirect if coming back from login
    if (location.search.includes("?code=")) {
      redirectLocation.current = localStorage.getItem("redirect");
    }

    //execute a redirect after login is done
    if (isAuthenticated && redirectLocation.current) {
      history.replace(redirectLocation.current);
      redirectLocation.current = null;
    }
  }, [location, isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loadingAuthentication) return <CircularProgress />;

  let authenticationRoute = (
    <Route exact path="/">
      <SignIn />
    </Route>
  );

  if (isAuthenticated) return AuthenticatedRoutes;

  if (location.search.includes("?error=")) {
    authenticationRoute = (
      <Route exact path="/">
        <AuthError />
      </Route>
    );
  }

  const publicRoutes = (
    <ConfigProvider
      locale={currentAppLocale.antd}
      direction={isDirectionRTL ? "rtl" : "ltr"}
    >
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={translation[currentAppLocale.locale]}
        onError={(err) => {
          if (err.code === "MISSING_TRANSLATION") {
            return;
          }
          throw err;
        }}
      >
        <Wrapper>
          <div className="gx-main-content-wrapper no-trans">
            <Switch>
              <Route exact path="/auth" component={SignIn} />
              {/* <Route exact path="/home" component={Home} /> */}
              <Route
                exact
                path="/login-or-register"
                component={LoginOrRegister}
              />
              <Route
                exact
                path="/register-success"
                component={RegisterSuccess}
              />

              {authenticationRoute}
              <Route exact path="*">
                <SignIn />
              </Route>
            </Switch>
          </div>
        </Wrapper>
      </IntlProvider>
    </ConfigProvider>
  );

  return publicRoutes;
};

export default memo(App);
