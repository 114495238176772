import moment from "moment";
import _ from "lodash";
import React, { Fragment, useMemo } from "react";
import { Table } from "antd";
import Widget from "../../components/Widget";

const BuiltVessels = ({ vessels }) => {
  const orderbookColumns = useMemo(
    () => [
      {
        title: "Imo",
        dataIndex: "imo",
        sorter: (a, b) => (a.imo > b.imo ? 1 : -1),
        render: (text, record) => (
          <a
            href={`/app/orders/${record.id}-${_.kebabCase(text)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {text}
          </a>
        ),
      },
      {
        title: "Orderer",
        dataIndex: "orderer",
        key: "orderer",
        render: (text, record) => (
          <>
            {record.orderer?.ordererUrl && (
              <span className="gx-link">
                <a
                  href={`/app${record.orderer?.ordererUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {record.orderer?.name}
                </a>
              </span>
            )}

            {!record.orderer?.ordererUrl && <>{record.orderer?.name}</>}
          </>
        ),
        sorter: (a, b) => a.orderer?.name > b.orderer?.name,
      },
      {
        title: "Vessel Type",
        dataIndex: "tdVesselType",
        render: (text, record) => (
          <a
            href={`/app/orders/${record.id}-${_.kebabCase(text)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {text}{" "}
          </a>
        ),
        sorter: (a, b) => a.tdVesselType > b.tdVesselType,
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => (a.name > b.name ? 1 : -1),
        render: (text, record) => (
          <a
            href={`/app/orders/${record.id}-${_.kebabCase(text)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {text}{" "}
          </a>
        ),
      },
      {
        title: "Length",
        dataIndex: "length",
        key: "length",
        defaultSortOrder: "descend",
        sorter: (a, b) => (a["length"] > b["length"] ? 1 : -1),
      },
      {
        title: "Width",
        dataIndex: "width",
        key: "width",
        sorter: (a, b) => (a.width > b.width ? 1 : -1),
      },
      {
        title: "DWT",
        dataIndex: "deadweight",
        key: "deadweight",
        defaultSortOrder: "descend",
        sorter: (a, b) => (a.deadweight > b.deadweight ? 1 : -1),
      },
      {
        title: "TEU",
        dataIndex: "teu",
        key: "teu",
        sorter: (a, b) => (a.teu > b.teu ? 1 : -1),
      },
      {
        title: "Delivery Date",
        dataIndex: "deliveryDate",
        key: "deliveryDate",
        render: (d) => (d ? moment(d).format("DD.MMM.YYYY") : ""),
        sorter: (a, b) => (moment(a).isAfter(moment(b)) ? 1 : -1),
      },
    ],
    []
  );

  return (
    <div id="built-vessels">
      <Widget styleName="gx-card-profile">
        <div className="ant-card-head">
          <span className="ant-card-head-title gx-mb-1">Vessels built by this shipyard</span>
        </div>
        {
          [
            { name: "DELIVERED", title: "Delivered" },
          ].map((status, idx) => {
            const s = status.name
            const t = status.title
            return (
              <Fragment key={`order_detail_${idx}`}>
                {vessels.filter(d => d.tdStatus === s).length > 0 &&
                  <Table
                    className="gx-table-responsive"
                    columns={orderbookColumns}
                    rowKey="id"
                    dataSource={vessels.filter(d => d.tdStatus === s) || []}
                    sortDirections={["ascend", "descend", "ascend"]}
                    pagination={{ hideOnSinglePage: true }}
                  />
                }
              </Fragment>
            )
          })
        }
      </Widget>
    </div>
  )

}
export default BuiltVessels;