import React from "react";
import Widget from "components/Widget";

const Contact = ({ contactList }) => {
  return (
    <div id="contact">
      {contactList.length > 0 &&
        <Widget title="Contacts" styleName="gx-card-profile-sm">
          {contactList.map((data, index) => (
            <div key={index} style={{ marginTop: index ? "2rem" : '' }}>
              <span style={{ fontWeight: 600 }}>{data.name}</span>
              {data.email &&
                <div className="gx-media gx-align-items-center gx-flex-nowrap ">
                  <div className="gx-mr-3">
                    <i className={`icon icon-email gx-fs-xxl gx-text-grey`} />
                  </div>
                  <div className="gx-media-body">
                    <p className="gx-mb-0 gx-fs-sm">{data.email}</p>
                  </div>
                </div>
              }
              {data.phone &&
                <div className="gx-media gx-align-items-center gx-flex-nowrap">
                  <div className="gx-mr-3">
                    <i className={`icon icon-phone gx-fs-xxl gx-text-grey`} />
                  </div>
                  <div className="gx-media-body">
                    <p className="gx-mb-0 gx-fs-sm">{data.phone}</p>
                  </div>
                </div>
              }
              {data.mobile &&
                <div
                  className="gx-media gx-align-items-center gx-flex-nowrap"
                >
                  <div className="gx-mr-3">
                    <i className={`icon icon-phone gx-fs-xxl gx-text-grey`} />
                  </div>
                  <div className="gx-media-body">
                    <p className="gx-mb-0 gx-fs-sm">{data.mobile}</p>
                  </div>
                </div>
              }
            </div>
          ))}
        </Widget>
      }
    </div>
  );
};

export default Contact;
