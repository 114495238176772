export const FETCH_NEW_BUILDING_SHIPYARDS = "fetch new building shipyards"
export const FETCH_NEW_BUILDING_SHIPYARDS_SUCCESS = "fetch new building shipyards success"
export const FETCH_NEW_BUILDING_SHIPYARDS_FAILURE = "fetch new building shipyards failure"

export const FETCH_NEW_BUILDING_SHIPYARD_STAT = "fetch new building shipyard stat"
export const FETCH_NEW_BUILDING_SHIPYARD_STAT_SUCCESS = "fetch new building shipyard stat success"
export const FETCH_NEW_BUILDING_SHIPYARD_STAT_FAILURE = "fetch new building shipyard stat failure"

export const fetchNewBuildingShipyards = (data) => ({
  type: FETCH_NEW_BUILDING_SHIPYARDS,
  payload: data
});

export const fetchNewBuildingShipyardStat = (data) => ({
  type: FETCH_NEW_BUILDING_SHIPYARD_STAT,
  payload: data
});






