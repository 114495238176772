import React from "react";

import Widget from "components/Widget/index";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const RelatedItemsCard = ({ items }) => {
  return (
    <div>
      {items &&
        items.map((item, index) => {
          if (item.id !== null)
            return (
              <Widget
                key={index}
                styleName="gx-card-full gx-card-widget-high-border-radius"
              >
                <div
                  className={
                    item.picture !== null ? "" : "gx-card-image-container"
                  }
                >
                  {item.picture === null ? (
                    <img
                      alt="example"
                      className="filter-green"
                      src="https://www.trusteddocks.com/img/blank_img.svg"
                    />
                  ) : item.picture.startsWith("http") ? (
                    <img alt="example" src={item.picture} />
                  ) : item.picture.startsWith("content") ? (
                    <img
                      alt="shipyard"
                      src={`https://d1shccof2rzrfp.cloudfront.net/${item.picture}`}
                    />
                  ) : (
                    <img
                      alt="example"
                      className="filter-green"
                      src="https://www.trusteddocks.com/img/blank_img.svg"
                    />
                  )}
                </div>

                <div className="gx-p-3">
                  <p>{item.type}</p>
                  <Link to={item.url} target="_blank">
                    <h2 className="gx-mb-2">{item.name}</h2>
                  </Link>
                </div>
              </Widget>
            );
        })}
    </div>
  );
};

export default RelatedItemsCard;
