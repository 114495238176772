import { apiRequest } from "../actions/api.action"
import { getHost } from "../../util/host";
import { POST_USER_COMPANY_DATA, POST_USER_COMPANY_DATA_FAILURE, POST_USER_COMPANY_DATA_SUCCESS } from "../actions/Auth";

export const authFlow = ({ dispatch }) => next => action => {
  next(action)
  if (action.type === POST_USER_COMPANY_DATA) {
    let url = `${getHost()}/new-ships-api/user/user-info`
    dispatch(apiRequest('POST', url, action.payload, POST_USER_COMPANY_DATA_SUCCESS, POST_USER_COMPANY_DATA_FAILURE));
  }
  
};

export const authMdl = [authFlow];