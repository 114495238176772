import { useQuery } from "react-query";
import request from "../../util/request";
import _ from 'lodash'

const QUERY_KEYS = {
  ORDERER_MAIN: "orderer-main",
  ORDERER_VESSELS: "orderer-vessels",
  ORDERER_GEODATA: "orderer-geodata",
  ORDERER_PERMISSIONS: "orderer-permissions",
  ORDERER_ORDERS: "orderer-orders",
};

const usePageQueries = ({ isAuthenticated, orderId }) => {
  const {
    data: permissions,
    isLoading: permissionsLoading,
    isError: permissionsError,
  } = useQuery(
    QUERY_KEYS.ORDERER_PERMISSIONS,
    async () => {
      const response = await request({
        url: `new-ships-api/${
          isAuthenticated ? "user" : "public"
        }/content-permissions`,
        method: "GET",
        params: {
          page: "nso_order",
          id: _.isString(orderId)? 6041 : orderId,
        },
      });

      return response;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: 30000000,
      refetchOnWindowFocus: false,
      refetch: false,
    }
  );

  return {
    permissions,
    permissionsLoading,
    permissionsError,
  };
};

export default usePageQueries;
