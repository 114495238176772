import { MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Card, Checkbox, Col, Divider, Form, Row, Select, Steps } from "antd";
import FormItem from "antd/lib/form/FormItem";
import Input from "antd/lib/input/Input";
import { values } from "lodash";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { postUserCompanyData } from "../../appRedux/actions/Auth";
import SearchWithAutoComplete from "../../components/SearchWithAutoComplete";

import request from "../../util/request";
import { allCountries } from "../../util/country";

const steps = [{
  title: 'Please fillout your company info',
  key: "company"
}, {
  title: 'Please fillout your personal info',
  key: "person"
}, {
  title: 'Send request',
  key: "request"
}];

const EmailConfirmation = () => {
  const userCompanyUpdated = useSelector(({ auth }) => auth.userCompanyUpdated);

  useEffect(() => {
    if (userCompanyUpdated) {
      window.location.replace("/app/register-success");
    }
  }, [userCompanyUpdated]);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [checkCompany, setCheckCompany] = useState(true);

  const [data, setData] = useState({});

  const { isAuthenticated } = useAuth0();
  const [current, setCurrent] = useState(0)

  const next = () => {
    setCurrent(current + 1)
  }

  //ensure user creation
  const { refetch } = useQuery(
    "USER_DETAILS",
    async () => {
      const response = await request({
        url: "new-ships-api/user/user-info",
        method: "GET",
      });
      return response;
    },
    { enabled: false }
  );

  //ensure user creation
  useEffect(() => {
    if (!isAuthenticated) return null;
    refetch();
  }, [isAuthenticated, refetch]);

  return (
    <Card className="gx-card" title="Request a commercial licence">
      <p>You need to purchase a commercial licence to get full accees to new-ships.com</p>
      <Steps current={current}>
        {steps.map(item => <Steps.Step key={item.title} title={item.title} />)}
      </Steps>
      <div className="steps-content" style={{ minHeight: "200px", padding: "40px" }}>
        <Form
          form={form}
          initialValues={{ remember: false, companyId: null, }}
          name="basic"
          onValuesChange={() => {
          }}
          // onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0">

          {steps[current].key === 'company' &&
            <>
              <Row>
                <Col md={8} className="full-width-search">
                  <FormItem name="companyId" hidden={true}>
                    <Input
                    />
                  </FormItem>
                  <SearchWithAutoComplete types={['SHIPYARD', 'SHIPMANAGER', 'EQUIPMENT_MANUFACTURER', 'SERVICE_SUPPLIER']} placeholder="Search Shipyard | Shipmanager | Equipment Manufacturer | Service Supplier" value={values.companyName}
                    onChange={() => {
                    }}
                    onSelect={(e) => {
                      form.setFieldsValue({ companyId: e?.split("/")[2]?.split("-")[0] })
                      setCheckCompany(false);
                    }}
                  />
                </Col>
              </Row>

              <Divider orientation="left" orientationMargin="20px">If your company not founded, please fillout following form.</Divider>

              <Row>
                <Col md={8} >
                  <FormItem rules={[{ required: checkCompany, message: 'Please input your company type.' }]} name="companyType">
                    <Select
                      placeholder="Company type"
                    >
                      <Select.Option value="SHIPYARD">Shipyard</Select.Option>
                      <Select.Option value="SHIPMANAGER">Shipmanager</Select.Option>
                      <Select.Option value="EQUIPMENT_MANUFACTURER">Equipment Manufacturer</Select.Option>
                      <Select.Option value="SERVICE_SUPPLIER">Service Supplier</Select.Option>
                      <Select.Option value="OTHERS">Others</Select.Option>
                    </Select>
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col md={8} >
                  <FormItem rules={[{ required: checkCompany, message: 'Please input your company name.' }]} name="company">
                    <Input
                      placeholder="Company name" />
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col md={8} >
                  <FormItem rules={[{ required: checkCompany, message: 'Please input your company address.' }]} name="companyAddress">
                    <Input
                      placeholder="Company Address" />
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col md={4} >
                  <FormItem rules={[{ required: checkCompany, message: 'Please input your company zip.' }]} name="companyZip">
                    <Input
                      placeholder="Company zip" />
                  </FormItem>
                </Col>
                <Col md={4} >
                  <FormItem rules={[{ required: checkCompany, message: 'Please input your company city.' }]} name="companyCity">
                    <Input
                      placeholder="Company city" />
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col md={8} >
                  <FormItem rules={[{ required: checkCompany, message: 'Please input your company country.' }]} name="companyCountryId">
                    <Select
                      placeholder="Company country"
                    >
                      <Select.Option value="">please choose country</Select.Option>
                      {allCountries.sort((a, b) => a.name.localeCompare(b.name)).map((c, idx) => {
                        return (
                          <Select.Option value={c.id} key={`country_${idx}`}>{c.name}</Select.Option>
                        )
                      })}
                    </Select>
                  </FormItem>
                </Col>
              </Row>
            </>
          }
          {steps[current].key === 'person' &&
            <>
              <Row>
                <Col md={8} >
                  <FormItem rules={[{ required: true, message: 'Please input your copmany position.' }]} name="position">
                    <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="Position" />
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col md={8} >
                  <FormItem rules={[{ required: true, message: 'Please input your lastname.' }]} name="lastName">
                    <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="Lastname" />
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col md={8} >
                  <FormItem rules={[{ required: true, message: 'Please input your firstName.' }]} name="firstName">
                    <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="Firstname" />
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col md={8} >
                  <FormItem rules={[{ required: true, message: 'Please input your working E-mail.' }]} name="email">
                    <Input prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="Working email" />
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col md={8} >
                  <FormItem rules={[{ required: true, message: 'Please input your phone number.' }]} name="phone">
                    <Input prefix={<PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="Phone" />
                  </FormItem>
                </Col>
              </Row>
            </>
          }
          {steps[current].key === 'request' &&
            <>
              <Row>
                <Col md={8} >
                  <FormItem
                    rules={[
                      {
                        validator: (_, value) =>
                          value ? Promise.resolve() : Promise.reject(new Error('Please accept condition.')),
                      },
                    ]}
                    name="remember" valuePropName="checked">
                    <Checkbox>By clicking this button our commercial team will get in contact and send you an commercial offer</Checkbox>
                  </FormItem>
                </Col>
              </Row>
            </>
          }
        </Form>
      </div>
      <div className="steps-action">
        {current < steps.length - 1 &&
          <Button type="primary" onClick={async () => {
            let checked = false;
            switch (current) {
              case 0:
                try {
                  await form.validateFields();
                  checked = true
                  setData({
                    ...data,
                    ...form.getFieldsValue()
                  })
                } catch (errors) {
                }
                break;
              case 1:
                try {
                  await form.validateFields();
                  checked = true
                  setData({
                    ...data,
                    ...form.getFieldsValue()
                  })
                } catch (errors) {
                }
                break;
              default:
                break;
            }
            if (checked) {
              next()
            }
          }}>Next</Button>
        }

        {current === steps.length - 1 &&
          <Button type="primary" htmlType="submit" onClick={async () => {
            try {
              await form.validateFields();
              dispatch(postUserCompanyData(data))
            } catch (errors) {
            }
          }}>
            Request commercial licence
          </Button>
        }
      </div>
    </Card>
  );
};

export default EmailConfirmation;
