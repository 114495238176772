export const FETCH_TRANSLATION = "fetch translation"
export const FETCH_TRANSLATION_SUCCESS = "fetch translation success"
export const FETCH_TRANSLATION_FAILURE = "fetch translation failure"

export const fetchTranslation = (data) => ({
  type: FETCH_TRANSLATION,
  payload: data
});




