import { Button, Card, Col, Form, Input, Radio, Row, Select, Table } from "antd";
import React, { Fragment, useMemo, useState } from "react";
import _ from "lodash";
import { allWeekdays } from "../Map/helpers";
import moment from "moment";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

const UserSearches = ({ tableVariables, saveUserSearch, userSearches, deleteByCompanyMailingId, setTableVariables }) => {

  const [form] = Form.useForm();
  const { Option } = Select;
  const [expand, setExpand] = useState(false);

  const columns = useMemo(
    () => [
      {
        title: "Created at",
        dataIndex: "creationDate",
        sorter: (a, b) => (a.creationDate > b.creationDate ? 1 : -1),
        render: (text, record) => (
          <>
            {moment(record.creationDate).format("DD.MMM.YYYY")}
          </>
        ),
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => (a.name > b.name ? 1 : -1),
        render: (text, record) => (
          <>
            <a href="!#" onClick={(e) => {
              e.preventDefault()
              setTableVariables({
                ...tableVariables,
                filters: JSON.parse(record.filter)
              })
            }}>{record.name}</a>
          </>
        ),
      },
      {
        title: "Dataset",
        dataIndex: "increment",
        render: (text, record) => (
          <>
            {record.increment ? "Increment Data" : "Full Data"}
          </>
        ),
      },
      {
        title: "Send Date",
        dataIndex: "weekdays",
        render: (text, record) => (
          <>
            {record.weekdays.sort((a, b) => a > b).map((w, index) => {
              return (
                <Fragment key={`week_${index}`}>
                  {index == 0 ? "" : ", "}
                  {allWeekdays.values[w]}
                </Fragment>
              )
            })}
          </>
        ),
      },
      {
        title: "Send Time",
        dataIndex: "sendTime",
        render: (text, record) => (
          <>
            {record.sendTime}
          </>
        ),
      },
      {
        title: "",
        dataIndex: "action",
        render: (text, record) => (
          <>
            <a href="!#" onClick={(e) => {
              e.preventDefault()
              setTableVariables({
                ...tableVariables,
                filters: JSON.parse(record.filter)
              })
            }}>search</a>
            <a className="ml20" href="!#" onClick={(e) => {
              e.preventDefault()
              deleteByCompanyMailingId.mutate(record.id)
            }}>remove</a>
          </>
        ),
      },
    ],
    []
  );

  return (
    <Card className="gx-card pb10" title="User Searches"
      bodyStyle={{ paddingBottom: "0px" }}
      extra={
        <a
          href="#/"
          onClick={() => {
            setExpand(!expand);
          }}
        >
          {expand ? <><UpOutlined /> hide</> : <><DownOutlined /> show</>}
        </a>
      }>
      {userSearches?.length > 0 && expand &&
        <Table
          className="gx-table-responsive mb20"
          columns={columns}
          rowKey="id"
          dataSource={userSearches?.filter(h => h.mailingType === "NEW_SHIPS_SEARCH") || []}
          sortDirections={["ascend", "descend", "ascend"]}
          pagination={{ hideOnSinglePage: true, defaultPageSize: 5 }}
        />
      }
      {expand &&
        <Form form={form}
          initialValues={{ increment: true, sendTime: "0:00", weekdays: [1, 2, 3, 4, 5, 6, 7] }}
          onFinish={() => {
            saveUserSearch.mutate({
              values: {
                ...form.getFieldsValue(),
                weekdays: _.join(form.getFieldsValue().weekdays, ",")
              },
              filters: tableVariables?.filters
            })
          }}>
          <Row>
            <Col md={8}>
              <Form.Item name="name" label="Search Name" rules={[{ required: true }]}>
                <Input placeholder="Search Name" />
              </Form.Item>
            </Col>

            <Col md={8}>
              <Form.Item
                name="sendTime"
                label="Mail send time(UTC)"
              >
                <Select >
                  {_.range(24).map((h, index) => (
                    <Fragment key={`h_${index}`}>
                      {["00", "15", "30", "45"].map((m, ind) => (
                        <Option value={`${h}:${m}`} key={`h_${index}_min_${ind}`}>
                          {h}:{m}
                        </Option>
                      ))}
                    </Fragment>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item
                name="weekdays"
                label="Mail send Weekday"
              >
                <Select mode="multiple">
                  <Option value={1} >Monday</Option>
                  <Option value={2} >Tuesday</Option>
                  <Option value={3} >Wendsday</Option>
                  <Option value={4} >Thusday</Option>
                  <Option value={5} >Friday</Option>
                  <Option value={6} >Saturday</Option>
                  <Option value={7} >Sonnday</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item
                name="increment"
                label="Datasets"
              >
                <Radio.Group>
                  <Radio value={true} >Increment Data</Radio>
                  <Radio value={false} >Full Data(max. 100 datasets)</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Button type="primary" htmlType="submit"
            disabled={_.isEmpty(tableVariables.filters)}
          >
            Save
          </Button>
        </Form>
      }
    </Card>
  )
};
export default UserSearches;

