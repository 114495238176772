export const tableColumns = [
  // {
  //   title: "ID",
  //   data: "id",
  //   type: "numeric",
  //   editor: false,
  //   readOnly: true,
  // },
  {
    title: "Shipyard",
    data: "shipyard.name",
  },
  {
    title: "IMO",
    data: "vessel_imo",
    type: "numeric",
  },
  {
    title: "Name",
    data: "vessel_name",
  },
  {
    title: "Type",
    data: "vessel_type",
  },
  {
    title: "Length",
    data: "vessel_length",
    type: "numeric",
    numericFormat: {
      pattern: "00.00",
    },
  },
  {
    title: "Width",
    data: "vessel_width",
    type: "numeric",
  },
  {
    title: "Depth",
    data: "vessel_depth",
  },
  {
    title: "DWT",
    data: "vessel_deadweight",
  },
  {
    title: "TEU",
    data: "vessel_teu",
  },
  {
    title: "Main Engine",
    data: "main_engine",
  },
  {
    title: "Order Status",
    data: "order_status",
  },
  {
    title: "Order Date",
    data: "order_date",
  },
  {
    title: "Delivery Date",
    data: "delivery_date",
  },
  {
    title: "Updated At",
    data: "updated_at",
  },
  {
    title: "Updated By",
    data: "updated_user_email",
  },
];

export const shipTypesSimple = [
  { value: "All Vessel Types", label: "All Vessel Types" },
  { value: "Container ships", label: "Container ships" },
  { value: "Naval Ships", label: "Naval Ships" },
  { value: "Heavy-lift/Project Cargo Vessels", label: "Heavy-lift/Project Cargo Vessels" },
  { value: "Passenger Ships", label: "Passenger Ships" },
  { value: "Offshore Vessel", label: "Offshore Vessel" },
  { value: "Tugs", label: "Tugs" },
  { value: "Bulk carriers", label: "Bulk carriers" },
  { value: "Crude Carriers", label: "Crude Carriers" },
  { value: "Chemical Carriers", label: "Chemical Carriers" },
  { value: "Product Carriers", label: "Product Carriers" },
  { value: "LPG Carriers", label: "LPG Carriers" },
  { value: "LNG Carriers", label: "LNG Carriers" },
  { value: "Service Vessels", label: "Service Vessels" },
  { value: "RORO Cargo Ships", label: "RORO Cargo Ships" },
  { value: "Dredger", label: "Dredger" },
  { value: "Megayachts", label: "Megayachts" },
  { value: "Tankers", label: "Tankers" },
  { value: "Gas Tankers", label: "Gas Tankers" },
  { value: "Multipurpose Vessels (MPP)", label: "Multipurpose Vessels (MPP)" },
  { value: "PCC (Pure Car Carrier) and PTC (Pure Truck Carrier)", label: "PCC (Pure Car Carrier) and PTC (Pure Truck Carrier)" },
  { value: "Fishing Vessel", label: "Fishing Vessel" },
  { value: "Floating Production Storage and Offloading (FPSO)", label: "Floating Production Storage and Offloading (FPSO)" },
  { value: "General Cargo Ships", label: "General Cargo Ships" },
  { value: "Specialized Cargo Ships", label: "Specialized Cargo Ships" },
];

export const shipTypesFull = [
  { value: "All Vessel Types", label: "All Vessel Types" },
  { value: "Container ships", label: "Container ships" },
  { value: "Naval Ships", label: "Naval Ships" },
  { value: "Heavy-lift/Project Cargo Vessels", label: "Heavy-lift/Project Cargo Vessels" },
  { value: "Passenger Ships", label: "Passenger Ships", children: [
    { value: "Expedition Cruise Ship", label: "Expedition Cruise Ship" },
    { value: "Cruise Vessels", label: "Cruise Vessels" },
    { value: "catamaran ferry", label: "catamaran ferry" }
  ] },
  { value: "Offshore Vessel", label: "Offshore Vessel", children: [
    { value: "Cable Layer Vessel", label: "Cable Layer Vessel" },
    { value: "Crew transfer vessel", label: "Crew transfer vessel" }
  ] },
  { value: "Tugs", label: "Tugs", children: [
    { value: "Anchor Handling Tug (AHTS)", label: "Anchor Handling Tug (AHTS)" }
  ] },
  { value: "Bulk carriers", label: "Bulk carriers", children: [
    { value: "Wood Carrier", label: "Wood Carrier" },
    { value: "Cement Carrier", label: "Cement Carrier" },
    { value: "Coal Bulker", label: "Coal Bulker" },
    { value: "Ore Bulk Carrier", label: "Ore Bulk Carrier" }
  ]},
  { value: "Crude Carriers", label: "Crude Carriers" },
  { value: "Chemical Carriers", label: "Chemical Carriers" },
  { value: "Product Carriers", label: "Product Carriers" },
  { value: "LPG Carriers", label: "LPG Carriers" },
  { value: "LNG Carriers", label: "LNG Carriers" },
  { value: "Service Vessels", label: "Service Vessels", children: [
    { value: "Pilot Vessel", label: "Pilot Vessel" },
    { value: "Windfarm Service Vessel", label: "Windfarm Service Vessel" },
    { value: "Hospital Ship", label: "Hospital Ship" },
    { value: "Buoy tender", label: "Buoy tender" },
    { value: "icebreaker", label: "icebreaker" }
  ]},
  { value: "RORO Cargo Ships", label: "RORO Cargo Ships" },
  { value: "Dredger", label: "Dredger" },
  { value: "Megayachts", label: "Megayachts" },
  { value: "Tankers", label: "Tankers", children: [
    { value: "Bitumen Carriers", label: "Bitumen Carriers" },
    { value: "Crude Oil Tanker", label: "Crude Oil Tanker" },
    { value: "Fruit Juice Carrier", label: "Fruit Juice Carrier" }
  ]},
  { value: "Gas Tankers", label: "Gas Tankers", children:[
    { value: "CNG Tankers", label: "CNG Tankers" },
    { value: "Gas Processing Vessel", label: "Gas Processing Vessel" }
  ] },
  { value: "Multipurpose Vessels (MPP)", label: "Multipurpose Vessels (MPP)" },
  { value: "PCC (Pure Car Carrier) and PTC (Pure Truck Carrier)", label: "PCC (Pure Car Carrier) and PTC (Pure Truck Carrier)" },
  { value: "Fishing Vessel", label: "Fishing Vessel" },
  { value: "Floating Production Storage and Offloading (FPSO)", label: "Floating Production Storage and Offloading (FPSO)" },
  { value: "General Cargo Ships", label: "General Cargo Ships" },
  { value: "Specialized Cargo Ships", label: "Specialized Cargo Ships" },
];