import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Widget from "components/Widget/index";
import _ from "lodash";
import { HotTable } from "@handsontable/react";

import { useAuth0 } from "@auth0/auth0-react";
import usePageQueries from "../../hooks/usePageQueries";
import { Pagination, Collapse, Divider } from "antd";
import AddShipForm from "components/AddShipForm/index";
import { tableColumns } from "../../shared/const";

export const Home = () => {
  const dispatch = useDispatch();

  const { isAuthenticated } = useAuth0();
  const [params, setParams] = useState({
    sortColumn: "id",
    sortOrder: "asc",
    per_page: 20,
  });

  const { data } = usePageQueries({ isAuthenticated, params });

  const vesselTableData = data || [];

  const tableRef = useRef(null);

  useEffect(() => {
    if (vesselTableData) {
      tableRef?.current?.hotInstance.loadData(vesselTableData);
    }
  }, [vesselTableData]);

  const showTotal = (total) => `Total ${total} items`;

  const { Panel } = Collapse;

  return (
    <div>
      <Collapse>
        <Panel header="Add new ship form" key="1">
          <AddShipForm />
        </Panel>
      </Collapse>
      <Divider />
      <Pagination
        current={params.current_page || 1}
        pageSize={params.per_page || 20}
        onChange={(page, pageSize) => {
          setParams({
            ...params,
            current_page: page,
            per_page: pageSize,
          });
        }}
        total={vesselTableData.total}
        showTotal={showTotal}
      />
      <Divider />
      <Widget styleName="mb0">
        <HotTable
          ref={tableRef}
          filters={true}
          contextMenu={true}
          dropdownMenu={true}
          rowHeaders={true}
          columnSorting={true}
          beforeColumnSort={function (
            currentSortConfig,
            destinationSortConfigs
          ) {}}
          afterColumnSort={function (
            currentSortConfig,
            destinationSortConfigs
          ) {
            if (
              params.sortColumn ===
              tableColumns[destinationSortConfigs[0]?.column]?.data
            ) {
              setParams({
                ...params,
                sortColumn:
                  tableColumns[destinationSortConfigs[0]?.column]?.data,
                sortOrder: params.sortOrder === "asc" ? "desc" : "asc",
              });
            } else {
              setParams({
                ...params,
                sortColumn:
                  tableColumns[destinationSortConfigs[0]?.column]?.data,
                sortOrder: destinationSortConfigs[0]?.sortOrder,
              });
            }
          }}
          height="auto"
          autoWrapRow={true}
          autoWrapCol={true}
          licenseKey="non-commercial-and-evaluation" // for non-commercial use only
          columns={tableColumns}
          afterChange={function (change, source) {
            console.log("11111", change, source, tableRef.current?.props?.data);

            if (source === "Autofill.fill") {
              console.log("22222", change);
              const [row, prop, oldVal, newVal] = change[0];
              console.log("3333", row, prop, oldVal, newVal);
              console.log("444", tableRef.current?.getSourceDataAtRow(row));
            }
          }}
        />
      </Widget>
      <Divider />
      <Pagination
        pageSize={params.per_page || 20}
        current={params.current_page || 1}
        onChange={(page, pageSize) => {
          setParams({
            ...params,
            current_page: page,
            per_page: pageSize,
          });
        }}
        total={vesselTableData.total}
        showTotal={showTotal}
      />
    </div>
  );
};

export default Home;
