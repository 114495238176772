import { useAuth0 } from "@auth0/auth0-react";
import { Alert, Card, Col, Radio, Row, Table, Tag } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import CircularProgress from "../../components/CircularProgress";
import Widget from "../../components/Widget";
import usePageQueries from "./queries";
import _ from "lodash";
import { useLocation } from "react-router-dom";

const ConstructionGuide = () => {

  const { isAuthenticated } = useAuth0();

  const location = useLocation();

  const [currentTag, setCurrentTag] = useState(null);
  const [loader, setLoader] = useState(false);

  const [buildersTableVariables, setBuildersTableVariables] = React.useState({
    pagination: { current: 1, pageSize: 10 },
    sort: {
      order: "descend",
      field: "onOrder",
    },
  });

  const {
    tags,
    builders,
    refetch
  } = usePageQueries({ isAuthenticated, buildersTableVariables, currentTag });


  useEffect(() => {
    if (tags) {
      const params = new URLSearchParams(location.search);
      if (params.has("tagGroupId")) {
        const pId= params.get("tagGroupId")
        setCurrentTag(tags.find(t => t.id.toString() === pId))
      } else {
        setCurrentTag(tags[0])
      }
    }
  }, [tags])

  const handleChange = (e) => {
    const value = e.target.value;
    setCurrentTag(e.target.value)
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 1500);
  };

  const handleBuildersTableChange = (pagination, filters, sort, extra) => {
    setBuildersTableVariables({
      ...buildersTableVariables,
      sort: {
        ...buildersTableVariables.sort,
        ...sort,
      },
      pagination: {
        ...buildersTableVariables.pagination,
        ...pagination,
      },
    });
  };

  const shipyardColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => (
        <span className="gx-link">
          {record.country &&
            <span style={{ border: "2px solid #038FDE", backgroundColor: "#038FDE" }}>
              <i
                style={{ marginBottom: "-7px" }}
                title={record.country?.name}
                className={`flag flag-24 flag-${_.lowerCase(
                  record.country?.a2
                )}`}
              />
            </span>
          }
          {!record.country &&
            <span style={{ border: "2px solid #038FDE", backgroundColor: "#038FDE" }}>
              <i
                style={{ marginBottom: "-7px" }}
                title={record.country?.name}
                className={`flag flag-24 flag-unknown`}
              />
            </span>
          }
          <a className="ml10"
            href={`/app${record.url}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {text}
          </a>
          {record.premium && (
            <img
              className="pl10"
              style={{ height: "30px" }}
              src="/app/assets/images/premium_user_small.png"
              alt="premium user"
            />
          )}
        </span>
      ),
      sorter: (a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      }
    },
    {
      title: "On Order",
      dataIndex: "onOrder",
      key: "onOrder",
      sorter: (a, b) => (a.onOrder || 0) - (b.onOrder || 0),
      defaultSortOrder: "decend",
      render: (text, record, index) => {
        return text === 0? "": text
      }
    },
    {
      title: "Delivered last 6 month",
      dataIndex: "delivered_6",
      key: "delivered_6",
      sorter: (a, b) => (a.delivered_6 || 0) - (b.delivered_6 || 0),
      render: (text, record, index) => {
        return text === 0? "": text
      }
    },
    {
      title: "Delivered last 12 month",
      dataIndex: "delivered_12",
      key: "delivered_12",
      sorter: (a, b) => (a.delivered_12 || 0) - (b.delivered_12 || 0),
      render: (text, record, index) => {
        return text === 0? "": text
      }
    },
    {
      title: "Delivered last 24 month",
      dataIndex: "delivered_24",
      key: "delivered_24",
      sorter: (a, b) => (a.delivered_24 || 0) - (b.delivered_24 || 0),
      render: (text, record, index) => {
        return text === 0? "": text
      }
    },
    {
      title: "Delivery Expected",
      dataIndex: "inBuilt",
      key: "inBuilt",
      sorter: (a, b) => {
        return (a.inBuilt || 0) - (b.inBuilt || 0);
      },
      render: (text, record, index) => {
        return text === 0? "": text
      }
    },
    {
      title: "Delivered",
      dataIndex: "delivered",
      key: "delivered",
      sorter: (a, b) => {
        return (a.delivered || 0) - (b.delivered || 0);
      },
      render: (text, record, index) => {
        return text === 0? "": text
      }
    },
  ];

  return (

    <Row>
      <Col span={24}>
        <Widget>
          <div className="ant-row-flex gx-justify-content-between gx-mb-3 gx-dash-search">
            {currentTag &&
              <Radio.Group className="gx-radio-group-link gx-radio-group-link-news" defaultValue={currentTag}
                onChange={handleChange}>
                {tags &&
                  tags.map((t, index) => {
                    return (
                      <Radio.Button key={`tag_${t.id}`} value={t} className="gx-mb-1">{t.name}</Radio.Button>
                    )
                  })
                }
              </Radio.Group>
            }
          </div>

          {loader && <CircularProgress className="gx-loader-400" />}
          {!loader && currentTag &&
            <Fragment>
              {currentTag.children?.length > 0 &&
                currentTag.children.map((child, index) => {
                  return (
                    <Tag key={`chiile_tag_${child.id}`} >
                      {child.name}
                    </Tag>
                  )
                })
              }
              <Row>
                <Col span={24}>
                  <p className="gx-mb-4">
                    <span dangerouslySetInnerHTML={{ __html: currentTag.description }} />
                  </p>
                </Col>
              </Row>
            </Fragment>
          }

          {builders?.total > 0 &&
            <>
              <Alert message={`Shipyards building ${currentTag.name}`} type="info" />
              <Table
                className="gx-table-responsive"
                columns={shipyardColumns}
                rowKey="id"
                dataSource={builders?.data}
                sortDirections={["ascend", "descend", "ascend"]}
                // onChange={handleBuildersTableChange}
                pagination={{
                  showSizeChanger: false,
                  total: builders?.total,
                  defaultPageSize: buildersTableVariables.pagination.pageSize,
                }}
              />
            </>
          }

        </Widget>
      </Col>
    </Row>

  )
};

export default ConstructionGuide;
