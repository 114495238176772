import React from "react";
import Widget from "components/Widget";

const Address = ({ address, email }) => {
  return (
    <div id="contact">
      <Widget title="Address" styleName="gx-card-profile-sm">
        <div>
          {address?.street &&
            <div className="gx-media gx-align-items-center gx-flex-nowrap">
              <div className="gx-mr-3">
                <i className={`icon icon-home gx-fs-xxl gx-text-grey`} />
              </div>
              <div className="gx-media-body">
                <p className="gx-mb-0 gx-fs-sm">
                  {address.street} <br />
                  {address.zip || ''} - {address.city || ''} <br />
                  {address.country?.name || ''}
                </p>
              </div>
            </div>
          }

          {address?.web &&
            <div className="gx-media gx-align-items-center gx-flex-nowrap">
              <div className="gx-mr-3">
                <i className={`icon icon-navigation gx-fs-xxl gx-text-grey`} />
              </div>
              <div className="gx-media-body">
                <p className="gx-mb-0 gx-fs-sm">
                  <a href={address.web} target="_blank" rel="noreferrer nofollow">
                    {address.web}
                  </a>
                </p>
              </div>
            </div>
          }
          {email &&
            <div className="gx-media gx-align-items-center gx-flex-nowrap ">
              <div className="gx-mr-3">
                <i className={`icon icon-email gx-fs-xxl gx-text-grey`} />
              </div>
              <div className="gx-media-body">
                <p className="gx-mb-0 gx-fs-sm">{email}</p>
              </div>
            </div>
          }
          {address?.phone &&
            <div className="gx-media gx-align-items-center gx-flex-nowrap">
              <div className="gx-mr-3">
                <i className={`icon icon-phone gx-fs-xxl gx-text-grey`} />
              </div>
              <div className="gx-media-body">
                <p className="gx-mb-0 gx-fs-sm">{address.phone}</p>
              </div>
            </div>
          }
          {address?.mobile &&
            <div
              className="gx-media gx-align-items-center gx-flex-nowrap"
            >
              <div className="gx-mr-3">
                <i className={`icon icon-phone gx-fs-xxl gx-text-grey`} />
              </div>
              <div className="gx-media-body">
                <p className="gx-mb-0 gx-fs-sm">{address.mobile}</p>
              </div>
            </div>
          }
        </div>
      </Widget>
    </div>
  );
};

export default Address;
