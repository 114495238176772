import { apiRequest } from "../actions/api.action"
import { getHost } from "../../util/host";
import { FETCH_TRANSLATION, FETCH_TRANSLATION_FAILURE, FETCH_TRANSLATION_SUCCESS } from "../actions/translation.action";

export const translationFlow = ({ dispatch }) => next => action => {
  next(action)
  if (action.type === FETCH_TRANSLATION) {
    let url = `${getHost()}/new-ships-api/public/translation`
    dispatch(apiRequest("GET", url, null, FETCH_TRANSLATION_SUCCESS, FETCH_TRANSLATION_FAILURE));
  }

};

export const translationMdl = [translationFlow];