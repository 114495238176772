import { apiRequest } from "../actions/api.action"
import { getHost } from "../../util/host";
import { FETCH_NEW_BUILDING_ORDERERS, FETCH_NEW_BUILDING_ORDERERS_FAILURE, FETCH_NEW_BUILDING_ORDERERS_SUCCESS, FETCH_NEW_BUILDING_ORDERS, FETCH_NEW_BUILDING_ORDERS_FAILURE, FETCH_NEW_BUILDING_ORDERS_SUCCESS, FETCH_VESSELS_FOR_HOME, FETCH_VESSELS_FOR_HOME_FAILURE, FETCH_VESSELS_FOR_HOME_SUCCESS, FETCH_VESSEL_BY_IMO, FETCH_VESSEL_BY_IMO_FAILURE, FETCH_VESSEL_BY_IMO_SUCCESS, FETCH_VESSEL_BY_ORDER_ID, FETCH_VESSEL_BY_ORDER_ID_FAILURE, FETCH_VESSEL_BY_ORDER_ID_SUCCESS, FETCH_VESSEL_TYPES, FETCH_VESSEL_TYPES_FAILURE, FETCH_VESSEL_TYPES_SUCCESS } from "../actions/Vessel.action";

export const vesselFlow = ({ dispatch }) => next => action => {
  next(action)
  if (action.type === FETCH_VESSEL_TYPES) {
    let url = `${getHost()}/new-ships-api/public/vessel/types`
    dispatch(apiRequest('GET', url, null, FETCH_VESSEL_TYPES_SUCCESS, FETCH_VESSEL_TYPES_FAILURE));
  }

  if (action.type === FETCH_NEW_BUILDING_ORDERS) {
    let url = `${getHost()}/new-ships-api/${action.payload.isAuthenticated ? "user": "public"}/order?vesselType=${action.payload.vesselType}&left=${action.payload.boundingBox?._sw.lng}&right=${action.payload.boundingBox?._ne.lng}&top=${action.payload.boundingBox?._ne.lat}&bottom=${action.payload.boundingBox?._sw.lat}`
    dispatch(apiRequest('GET', url, null, FETCH_NEW_BUILDING_ORDERS_SUCCESS, FETCH_NEW_BUILDING_ORDERS_FAILURE));
  }

  if (action.type === FETCH_NEW_BUILDING_ORDERERS) {
    let url = `${getHost()}/new-ships-api/public/orderer`
    dispatch(apiRequest('GET', url, null, FETCH_NEW_BUILDING_ORDERERS_SUCCESS, FETCH_NEW_BUILDING_ORDERERS_FAILURE));
  }

  if (action.type === FETCH_VESSEL_BY_IMO) {
    let url = `${getHost()}/new-ships-api/public/vessel/${action.payload.imo}`
    dispatch(apiRequest('GET', url, null, FETCH_VESSEL_BY_IMO_SUCCESS, FETCH_VESSEL_BY_IMO_FAILURE));
  }

  if (action.type === FETCH_VESSEL_BY_ORDER_ID) {
    let url = `${getHost()}/new-ships-api/public/order/${action.payload.id}`
    dispatch(apiRequest('GET', url, null, FETCH_VESSEL_BY_ORDER_ID_SUCCESS, FETCH_VESSEL_BY_ORDER_ID_FAILURE));
  }

  if (action.type === FETCH_VESSELS_FOR_HOME) {
    let url = `${getHost()}/new-ships-api/public/order/positions?vesselType=${action.payload.vesselType}`
    if (action.payload.boundingBox?._sw?.lng) {
      url = `${getHost()}/new-ships-api/public/order/positions?vesselType=${action.payload.vesselType}&left=${action.payload.boundingBox?._sw.lng}&right=${action.payload.boundingBox?._ne.lng}&top=${action.payload.boundingBox?._ne.lat}&bottom=${action.payload.boundingBox?._sw.lat}&currentTab=${action.payload.currentTab}`
    }
    dispatch(apiRequest('GET', url, null, FETCH_VESSELS_FOR_HOME_SUCCESS, FETCH_VESSELS_FOR_HOME_FAILURE));
  }
  
};

export const vesselMdl = [vesselFlow];