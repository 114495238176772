export const FETCH_VESSEL_TYPES = "fetch vessel types"
export const FETCH_VESSEL_TYPES_SUCCESS = "fetch vessel types success"
export const FETCH_VESSEL_TYPES_FAILURE = "fetch vessel types failure"

export const FETCH_NEW_BUILDING_ORDERS = "fetch new building orders"
export const FETCH_NEW_BUILDING_ORDERS_SUCCESS = "fetch new building orders success"
export const FETCH_NEW_BUILDING_ORDERS_FAILURE = "fetch new building orders failure"

export const FETCH_NEW_BUILDING_ORDERERS = "fetch new building orderers"
export const FETCH_NEW_BUILDING_ORDERERS_SUCCESS = "fetch new building orderers success"
export const FETCH_NEW_BUILDING_ORDERERS_FAILURE = "fetch new building orderers failure"

export const FETCH_VESSEL_BY_IMO = "fetch vessel by imo"
export const FETCH_VESSEL_BY_IMO_SUCCESS = "fetch vessel by imo success"
export const FETCH_VESSEL_BY_IMO_FAILURE = "fetch vessel by imo failure"

export const FETCH_VESSEL_BY_ORDER_ID = "fetch vessel by order id"
export const FETCH_VESSEL_BY_ORDER_ID_SUCCESS = "fetch vessel by order id success"
export const FETCH_VESSEL_BY_ORDER_ID_FAILURE = "fetch vessel by order id failure"

export const FETCH_VESSELS_FOR_HOME = "fetch vessels for home"
export const FETCH_VESSELS_FOR_HOME_SUCCESS = "fetch vessels for home success"
export const FETCH_VESSELS_FOR_HOME_FAILURE = "fetch vessels for home failure"

export const fetchVesselTypes = (data) => ({
  type: FETCH_VESSEL_TYPES,
  payload: data
});

export const fetchNewBuildingOrders = (data) => ({
  type: FETCH_NEW_BUILDING_ORDERS,
  payload: data
});

export const fetchVesselByImo = (data) => ({
  type: FETCH_VESSEL_BY_IMO,
  payload: data
});

export const fetchVesselByOrderId = (data) => ({
  type: FETCH_VESSEL_BY_ORDER_ID,
  payload: data
});

export const fetchNewBuildingOrderers = (data) => ({
  type: FETCH_NEW_BUILDING_ORDERERS,
  payload: data
});

export const fetchVesselsForHome = (data) => ({
  type: FETCH_VESSELS_FOR_HOME,
  payload: data
});









