import React, { Fragment, useMemo } from "react";
import { Carousel, Col, Row, Tag } from "antd";
import About from "../../components/profile/About/index";
import Contact from "../../components/profile/Contact/index";
import Auxiliary from "../../util/Auxiliary";
import ShipyardHeader from "../../components/profile/ShipyardHeader/index";
import { Link, useParams } from "react-router-dom";
import CircularProgress from "../../components/CircularProgress";
import { useAuth0 } from "@auth0/auth0-react";
import Widget from "../../components/Widget";
import ContentAuthorization from "../ContentAuthorization";
import usePageQueries from "./queries";
import GalleryAndLightbox from "../../components/GalleryAndLightbox";
import moment from "moment";
import _ from "lodash";
import Address from "../../components/profile/Address";
import { Helmet } from "react-helmet";

const Shipmanager = () => {
  let { slug } = useParams();
  let shipyardId = slug.split("-")[0];

  const { isAuthenticated } = useAuth0();

  const {
    news,
    permissions,
    permissionsError,
    geoDataError,
    vesselsError,
    main,
    pageDataLoading,
    mainError,
    ordersData,
    ordersDataError  } = usePageQueries({ isAuthenticated, shipyardId });


  const linksToShow = useMemo(() => {
    const link = ["about", "location", "facilities", "photos", "videos", "contact"];
    if (ordersData && ordersData.length > 0) link.splice(1, 0, "orders");
    if (ordersData && ordersData.length > 0) link.splice(1, 0, "statistics");
    if (ordersData && ordersData.length > 0) link.splice(1, 0, "portfolio");
    if (ordersData && ordersData.length > 0) link.splice(1, 0, "clients");
    if (main && main.services.filter(s => s.id === 2).length > 0) link.splice(1, 0, "repair");
    if (news && news.length > 0) link.splice(1, 0, "news");
    return link;
  }, [ordersData, main, news]);

  const isError =
    mainError ||
    vesselsError ||
    geoDataError ||
    permissionsError ||
    ordersDataError;

  const pagePermissions = permissions || {};

  if (pageDataLoading) return <CircularProgress />;
  if (isError) return <div>Sorry an Error occurred</div>;


  const PhotoGallery = () => {
    if (!main?.images?.length) return null;
    return (
      <div id="photos">
        <Widget styleName="gx-card-profile">
          <div className="ant-card-head">
            <span className="ant-card-head-title gx-mb-1">Photo Gallery</span>
          </div>
          <div className="gx-pt-md-3">
            <GalleryAndLightbox urlObjects={main?.images} type="image" />
          </div>
        </Widget>
      </div>
    );
  };

  const VideoGallery = () => {
    if (!main?.videos.length) return null;
    return (
      <div id="videos">
        <Widget styleName="gx-card-profile">
          <div className="ant-card-head">
            <span className="ant-card-head-title gx-mb-1">Video Gallery</span>
          </div>
          <div className="gx-pt-md-3">
            <GalleryAndLightbox urlObjects={main?.videos} type="video" />
          </div>
        </Widget>
      </div>
    );
  };





  const News = () => {
    return (
      <div id="news">
        <Widget styleName="gx-card-profile">
          <div className="ant-card-head">
            <span className="ant-card-head-title gx-mb-1">News</span>
          </div>
          <div className="pt0">
            <Carousel autoplay={true}>
              {news &&
                news.map((n, idx) => {
                  return (
                    <Fragment key={`news_${idx}`}>
                      <div className="gx-media gx-align-items-center">
                        <div className="gx-media-body">
                          <h2 className="gx-mb-1">
                            {n.title}
                          </h2>
                          <p className="gx-text-grey">
                            {`${moment(n.creationDate).format("DD.MMM.YYYY")} (${moment(n.creationDate).fromNow()})`} - {n.user?.name}
                          </p>
                          <p className="gx-text-grey">
                            {n.tagsArray?.map((t, idx) => {
                              return (
                                <Tag key={`tag_${idx}`}>
                                  {t}
                                </Tag>
                              )
                            })
                            }
                          </p>
                          <p className="gx-text-grey">
                            {n.relatedItems?.map((c, idx) => {
                              return (
                                <Fragment key={`item_${idx}`} >
                                  {c.type === 'shipyard' &&
                                    <Link to={`/shipyards/${c.id}-${_.kebabCase(c.name)}`} target="_blank" rel="noopener noreferrer">
                                      <Tag>
                                        {c.name}
                                      </Tag>
                                    </Link>
                                  }
                                </Fragment>
                              )
                            })
                            }
                          </p>
                        </div>
                      </div>
                      <p className="gx-mb-4">
                        <span dangerouslySetInnerHTML={{ __html: n.content }} />
                      </p>
                    </Fragment>
                  )
                })}
            </Carousel>
          </div>
        </Widget>
      </div>
    )
  }




  return (
    <>
      <Helmet>
        <title>{`SHIPMANAGER - ${main.name}`}</title>
      </Helmet>
      <Auxiliary>
        <ShipyardHeader shipyard={main} linksToShow={linksToShow} />
        <div className="gx-profile-content">
          <Row>
            <Col xl={18} lg={14} md={14} sm={24} xs={24}>

              {main.description &&
                <ContentAuthorization
                  sectionId="shipyard-company_main_details"
                  permission={pagePermissions.nso_company_description}
                >
                  <About content={main.description} />
                </ContentAuthorization>
              }


              <ContentAuthorization
                sectionId="shipyard-company_main_picture"
                permission={pagePermissions.company_main_picture}
              >
                <PhotoGallery />
              </ContentAuthorization>

              <VideoGallery />

              {linksToShow.includes("news") &&
                <ContentAuthorization
                  sectionId="shipyard-company_main_picture"
                  permission={pagePermissions.nso_company_news}
                >
                  <News />
                </ContentAuthorization>
              }

            </Col>
            <Col xl={6} lg={10} md={10} sm={24} xs={24}>
              {main.address &&
                <ContentAuthorization
                  sectionId="shipyard-company_contacts"
                  permission={pagePermissions.company_contacts}
                >
                  <Address address={main.address} email={main.email} />
                </ContentAuthorization>
              }
              <ContentAuthorization
                sectionId="shipyard-company_contacts"
                permission={pagePermissions.company_contacts}
              >
                <Contact contactList={main.contacts} />
              </ContentAuthorization>
            </Col>
          </Row>
        </div>
      </Auxiliary>
    </>
  );
};
export default Shipmanager;
