import React from "react";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";


const TinyBarChart = ({ data, title }) => (

  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignContent: "center",
    }}
  >
    <h4
      style={{
        marginRight: "auto",
        marginLeft: "auto",
        color: "rgb(84 84 84 / 62%)",
      }}
    >
      {title}
    </h4>
    <ResponsiveContainer width="100%" height={250}>
      <BarChart data={data}
        margin={{ top: 10, right: 0, left: -15, bottom: 0 }}>
        <XAxis dataKey="year" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Bar dataKey="ordered" fill="#003366" />
        <Bar dataKey="delivered" fill="#FE9E15" />
        <Bar dataKey="delivery" fill="#038FDE" />
      </BarChart>
    </ResponsiveContainer>
  </div>
);

export default TinyBarChart;
