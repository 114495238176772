import React from "react";
import { Col, Row } from "antd";
import About from "../../components/profile/About/index";
import Auxiliary from "../../util/Auxiliary";
import VesselHeader from "../../components/profile/VesselHeader/index";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import request from "../../util/request";
import CircularProgress from "../../components/CircularProgress";

const Vessel = () => {
  let { slug } = useParams();
  let imo = slug.split("-")[0];

  const {
    data: vessel,
    isLoading,
    isError,
  } = useQuery("order", async () => {
    const response = await request({
      url: `new-ships-api/public/vessel/${imo}`,
      method: "GET",
    });
    return response;
  });

  if (isLoading) return <CircularProgress />;
  if (isError) return <div>Sorry an Error occurred</div>;

  return (
    <>
      {vessel && (
          <Auxiliary>
            <VesselHeader vessel={vessel} />
            <div className="gx-profile-content">
              <Row>
                <Col xl={18} lg={14} md={14} sm={24} xs={24}>
                  <About />
                </Col>

                <Col xl={6} lg={10} md={10} sm={24} xs={24}>
                  <Row>
                    <Col xl={24} lg={24} md={24} sm={12} xs={24}>
                      <img src={vessel.vesselPhotoUrl} alt="" />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Auxiliary>
      )}
    </>
  );
};

export default Vessel;
