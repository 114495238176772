import { useQuery } from "react-query";
import request from "../../util/request";

const QUERY_KEYS = {
  SHIPYARDS: "shipyards",
  VESSEL_TYPE: "vessel_type"
};

const usePageQueries = ({ isAuthenticated, tab }) => {

  const {
    data: shipyards,
  } = useQuery(
    QUERY_KEYS.SHIPYARDS,
    async () => {
      const response = await request({
        url: `new-ships-api/${isAuthenticated ? "user" : "public"
          }/statistics/shipyards`,
        method: "GET",
      });
      return response;
    },
    {refetchOnMount: false, refetchOnWindowFocus: false}
  );


  const {
    data: vesselTypes,
  } = useQuery(
    QUERY_KEYS.VESSEL_TYPE,
    async () => {
      const response = await request({
        url: "new-ships-api/public/vessel/types",
        method: "GET",
      });

      return response;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: 30000000,
      refetchOnWindowFocus: false,
      refetch: false,
    }
  );

  return { shipyards , vesselTypes };
};

export default usePageQueries;
