import React, { useState } from "react";

import Widget from "components/Widget";
import { Button, Card, Col, Form, Input, Modal, Row, Select } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { values } from "lodash";
import { allCountries } from "../../../util/country";

const Contact2 = ({ userInfo, postUserInfo }) => {

  const [visible, setVisible] = useState(false);
  const showModal = () => {
    setVisible(true);
  };
  const handleCancel = () => {
    setVisible(false);
  };

  const [form] = Form.useForm();

  return (
    <div id="contact">
      <Widget title="About" styleName="gx-card-profile-sm">

        <div className="gx-profile-banner-bottom">
          <span className="gx-link gx-profile-setting" onClick={showModal}>
            <i className="icon icon-setting gx-fs-lg gx-mr-2 gx-mr-sm-3 gx-d-inline-flex gx-vertical-align-middle" />
            <span className="gx-d-inline-flex gx-vertical-align-middle gx-ml-1 gx-ml-sm-0">edit</span>
          </span>
        </div>
        <span style={{ fontWeight: 600 }}>{userInfo.title ? userInfo.title : ""} {userInfo.name}</span>

        {userInfo.company &&
          <div className="gx-media gx-align-items-center gx-flex-nowrap">
            <div className="gx-mr-3">
              <i className={`icon icon-home gx-fs-xxl gx-text-grey`} />
            </div>
            <div className="gx-media-body">
              <p className="gx-mb-0 gx-fs-sm">{userInfo.company}</p>
              {userInfo.address &&
                <p className="gx-mb-0 gx-fs-sm">{userInfo.address}</p>
              }
            </div>
          </div>
        }

        {userInfo.email &&
          <div className="gx-media gx-align-items-center gx-flex-nowrap ">
            <div className="gx-mr-3">
              <i className={`icon icon-email gx-fs-xxl gx-text-grey`} />
            </div>
            <div className="gx-media-body">
              <p className="gx-mb-0 gx-fs-sm">{userInfo.email}</p>
            </div>
          </div>
        }
      </Widget>


      <Modal
        forceRender
        visible={visible}
        title="User Info"
        onCancel={handleCancel}
        width={1000}
        footer={[
          <Button key="back" onClick={handleCancel}>Close</Button>,
        ]}
      >
        <Form
          form={form}
          initialValues={userInfo}
          onFinish={() => {
            postUserInfo.mutate({
              ...form.getFieldsValue(),
            })
          }}
        >
          <Card title="User Info">
            <Row>
              <Col md={8} className="full-width-search">
                <FormItem rules={[{ required: true }]} name="title" label="Title" >
                  <Select
                    placeholder="Title"
                  >
                    <Select.Option value="Prof.">Prof.</Select.Option>
                    <Select.Option value="Dr.">Dr.</Select.Option>
                    <Select.Option value="Mr.">Mr.</Select.Option>
                    <Select.Option value="Ms.">Ms.</Select.Option>
                  </Select>
                </FormItem>
              </Col>
              <Col md={8} className="full-width-search">
                <FormItem name="firstName" label="First name" rules={[{ required: true }]}>
                  <Input
                  />
                </FormItem>
              </Col>
              <Col md={8} className="full-width-search">
                <FormItem name="lastName" label="Last name" rules={[{ required: true }]}>
                  <Input
                  />
                </FormItem>
              </Col>
            </Row>
          </Card>
          <Card title="Current Company">
            <Row>
              <Col md={12} className="full-width-search">
                <FormItem name="company" label="Company name">
                  <Input
                  />
                </FormItem>
              </Col>
              <Col md={12} className="full-width-search">
                <FormItem name="address" label="Address">
                  <Input
                  />
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col md={8} className="full-width-search">
                <FormItem name="zip" label="ZIP">
                  <Input
                  />
                </FormItem>
              </Col>
              <Col md={8} className="full-width-search">
                <FormItem name="city" label="City / Location">
                  <Input
                  />
                </FormItem>
              </Col>
              <Col md={8} >
                <FormItem name="countryId" label="Country" >
                  <Select
                    placeholder="Company country"
                  >
                    <Select.Option value="">please choose country</Select.Option>
                    {allCountries.sort((a, b) => a.name.localeCompare(b.name)).map((c, idx) => {
                      return (
                        <Select.Option value={c.id} key={`country_${idx}`}>{c.name}</Select.Option>
                      )
                    })}
                  </Select>
                </FormItem>
              </Col>
            </Row>
          </Card>
          <Card title="Communication Channel">
            <Row>
              <Col md={8} className="full-width-search">
                <FormItem name="linkedInProfile" label="LinkedIn Profile">
                  <Input
                  />
                </FormItem>
              </Col>
              <Col md={8} className="full-width-search">
                <FormItem name="phone" label="Phone">
                  <Input
                  />
                </FormItem>
              </Col>
              <Col md={8} className="full-width-search">
                <FormItem name="whatsapp" label="Whatsapp">
                  <Input
                  />
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col md={8} className="full-width-search">
                <FormItem name="Wechat" label="Wechat">
                  <Input
                  />
                </FormItem>
              </Col>
              <Col md={8} className="full-width-search">
                <FormItem name="telegram" label="Telegram">
                  <Input
                  />
                </FormItem>
              </Col>
            </Row>
          </Card>
          <Row>
            <Button type="primary" htmlType="submit" >Update</Button>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default Contact2;
