import { useMutation, useQuery, useQueryClient } from "react-query";
import request from "../../util/request";

const QUERY_KEYS = {
  USER_INFO: "user-info",
  COMPANIES: "companies",
  WORKINGHISTORIES: "working-history"
};

const usePageQueries = ({ isAuthenticated }) => {

  const queryClient = useQueryClient()

  const {
    data: userInfo,
  } = useQuery(
    QUERY_KEYS.USER_INFO,
    async () => {
      const response = await request({
        url: `new-ships-api/user/user-info`,
        method: "GET",
      });
      return response;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: companies,
  } = useQuery(
    QUERY_KEYS.COMPANIES,
    async () => {
      const response = await request({
        url: `new-ships-api/user/companies`,
        method: "GET",
      });
      return response;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: workingHistory,
  } = useQuery(
    QUERY_KEYS.WORKINGHISTORIES,
    async () => {
      const response = await request({
        url: `new-ships-api/user/working-history`,
        method: "GET",
      });
      return response;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const postHistory = useMutation("register", async (values) => {
    const response = await request({
      url: `new-ships-api/user/working-history`,
      data: {
        ...values,
      },
      method: "POST",
    });
    return response;
  }, {
    onSuccess: (e) => {
      queryClient.invalidateQueries()
    }
  }
  );


  const deleteByHistoryId = useMutation("delete", async (values) => {
    const response = await request({
      url: `new-ships-api/user/working-history/${values}`,
      data: {
        ...values,
      },
      method: "DELETE",
    });
    return response;
  }, {
    onSuccess: (e) => {
      queryClient.invalidateQueries()
    }
  }
  );

  const postUserInfo = useMutation("post-user", async (values) => {
    const response = await request({
      url: `new-ships-api/user/update`,
      data: {
        ...values,
      },
      method: "PUT",
    });
    return response;
  }, {
    onSuccess: (e) => {
      queryClient.invalidateQueries()
    }
  }
  );

  return {
    userInfo,
    companies,
    workingHistory,
    postHistory,
    deleteByHistoryId,
    postUserInfo
  };
};

export default usePageQueries;
