import React, { useState } from "react";
import { Pagination } from "antd";
import CircularProgress from "../../components/CircularProgress";
import { useAuth0 } from "@auth0/auth0-react";
import { usePageQueries } from "./queries";
import _ from "lodash";

import NewsCard from "../../components/Widgets/NewsCard.js";

const News = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <>
xxx
    </>
  );
};
export default News;
