import { useAuth0 } from "@auth0/auth0-react";
import { Col, Row } from "antd";
import React from "react";
import Contact2 from "../../components/profile/Contact2";
import WorkingHistory from "../../components/profile/WorkingHistory"
import MyCompany from "../../components/profile/MyCompany";
import ProfileHeader from "../../components/profile/ProfileHeader";
import Auxiliary from "../../util/Auxiliary";


import usePageQueries from "./queries";

const Profile = () => {
  const { isAuthenticated } = useAuth0();
  const {
    userInfo,
    companies,
    workingHistory,
    postHistory,
    deleteByHistoryId,
    postUserInfo,
  } = usePageQueries({ isAuthenticated });

  return (
    <>
      {userInfo &&
        <Auxiliary>
          <ProfileHeader userInfo={userInfo} />
          <div className="gx-profile-content">
            <Row>
              <Col xl={16} lg={14} md={14} sm={24} xs={24}>
                <Contact2 userInfo={userInfo} postUserInfo={postUserInfo} />
                {workingHistory &&
                  <WorkingHistory histories={workingHistory} postHistory={postHistory} deleteByHistoryId = {deleteByHistoryId}/>
                }
              </Col>
              <Col xl={8} lg={10} md={10} sm={24} xs={24}>
                {companies?.map((c, index) => {
                  return (
                    <MyCompany key={`compayn_${index}`} company={c} />
                  )
                })
                }
              </Col>
            </Row>
          </div>
        </Auxiliary>
      }
    </>
  );
};

export default Profile;
