export const allCountries = [
  { "id": 224, "name": "UKRAINE" },
  { "id": 29, "name": "BOLIVIA" },
  { "id": 22, "name": "BARBADOS" },
  { "id": 62, "name": "DJIBOUTI" },
  { "id": 78, "name": "FRENCH POLYNESIA" },
  { "id": 4, "name": "AFGHANISTAN" },
  { "id": 12, "name": "ANTIGUA AND BARBUDA" },
  { "id": 10, "name": "ANGUILLA" },
  { "id": 5, "name": "ALBANIA" },
  { "id": 14, "name": "ARMENIA" },
  { "id": 9, "name": "ANGOLA" },
  { "id": 11, "name": "ANTARCTICA" },
  { "id": 13, "name": "ARGENTINA" },
  { "id": 7, "name": "AMERICAN SAMOA" },
  { "id": 17, "name": "AUSTRIA" },
  { "id": 15, "name": "ARUBA" },
  { "id": 3, "name": "AALAND ISLANDS" },
  { "id": 30, "name": "BOSNIA AND HERZEGOWINA" },
  { "id": 21, "name": "BANGLADESH" },
  { "id": 24, "name": "BELGIUM" },
  { "id": 37, "name": "BURKINA FASO" },
  { "id": 36, "name": "BULGARIA" },
  { "id": 20, "name": "BAHRAIN" },
  { "id": 26, "name": "BENIN" },
  { "id": 27, "name": "BERMUDA" },
  { "id": 35, "name": "BRUNEI DARUSSALAM" },
  { "id": 19, "name": "BAHAMAS" },
  { "id": 28, "name": "BHUTAN" },
  { "id": 32, "name": "BOUVET ISLAND" },
  { "id": 23, "name": "BELARUS" },
  { "id": 25, "name": "BELIZE" },
  { "id": 49, "name": "COCOS (KEELING) ISLANDS" },
  { "id": 44, "name": "CENTRAL AFRICAN REPUBLIC" },
  { "id": 53, "name": "CONGO, Republic of" },
  { "id": 56, "name": "IVORY COAST" },
  { "id": 54, "name": "COOK ISLANDS" },
  { "id": 46, "name": "CHILE" },
  { "id": 50, "name": "COLOMBIA" },
  { "id": 58, "name": "CUBA" },
  { "id": 42, "name": "CAPE VERDE" },
  { "id": 59, "name": "CYPRUS" },
  { "id": 60, "name": "CZECH REPUBLIC" },
  { "id": 63, "name": "DOMINICA" },
  { "id": 64, "name": "DOMINICAN REPUBLIC" },
  { "id": 6, "name": "ALGERIA" },
  { "id": 70, "name": "ESTONIA" },
  { "id": 66, "name": "EGYPT" },
  { "id": 200, "name": "SPAIN" },
  { "id": 71, "name": "ETHIOPIA" },
  { "id": 75, "name": "FINLAND" },
  { "id": 74, "name": "FIJI" },
  { "id": 72, "name": "FALKLAND ISLANDS (MALVINAS)" },
  { "id": 73, "name": "FAROE ISLANDS" },
  { "id": 80, "name": "GABON" },
  { "id": 226, "name": "UNITED KINGDOM" },
  { "id": 82, "name": "GEORGIA" },
  { "id": 77, "name": "FRENCH GUIANA" },
  { "id": 83, "name": "GHANA" },
  { "id": 84, "name": "GIBRALTAR" },
  { "id": 86, "name": "GREENLAND" },
  { "id": 81, "name": "GAMBIA" },
  { "id": 91, "name": "GUINEA" },
  { "id": 88, "name": "GUADELOUPE" },
  { "id": 68, "name": "EQUATORIAL GUINEA" },
  { "id": 92, "name": "GUINEA-BISSAU" },
  { "id": 97, "name": "HONG KONG" },
  { "id": 57, "name": "CROATIA " },
  { "id": 94, "name": "HAITI" },
  { "id": 98, "name": "HUNGARY" },
  { "id": 104, "name": "IRELAND" },
  { "id": 105, "name": "ISRAEL" },
  { "id": 244, "name": "isle of man" },
  { "id": 107, "name": "JAMAICA" },
  { "id": 109, "name": "JORDAN" },
  { "id": 116, "name": "KYRGYZSTAN" },
  { "id": 39, "name": "CAMBODIA" },
  { "id": 112, "name": "KIRIBATI" },
  { "id": 51, "name": "COMOROS" },
  { "id": 113, "name": "North Korea" },
  { "id": 115, "name": "KUWAIT" },
  { "id": 43, "name": "CAYMAN ISLANDS" },
  { "id": 110, "name": "KAZAKHSTAN" },
  { "id": 117, "name": "LAO PEOPLE'S DEMOCRATIC REPUBLIC" },
  { "id": 119, "name": "LEBANON" },
  { "id": 123, "name": "LIECHTENSTEIN" },
  { "id": 125, "name": "LUXEMBOURG" },
  { "id": 118, "name": "LATVIA" },
  { "id": 127, "name": "MACEDONIA" },
  { "id": 206, "name": "SWEDEN" },
  { "id": 45, "name": "CHAD" },
  { "id": 79, "name": "FRENCH SOUTHERN TERRITORIES" },
  { "id": 209, "name": "Taiwan, China" },
  { "id": 227, "name": "UNITED STATES" },
  { "id": 242, "name": "Curaçao" },
  { "id": 128, "name": "MADAGASCAR" },
  { "id": 185, "name": "SAMOA" },
  { "id": 171, "name": "PITCAIRN" },
  { "id": 182, "name": "SAINT LUCIA" },
  { "id": 8, "name": "ANDORRA" },
  { "id": 225, "name": "UNITED ARAB EMIRATES" },
  { "id": 18, "name": "AZERBAIJAN" },
  { "id": 38, "name": "BURUNDI" },
  { "id": 31, "name": "BOTSWANA" },
  { "id": 52, "name": "CONGO" },
  { "id": 40, "name": "CAMEROON" },
  { "id": 55, "name": "COSTA RICA" },
  { "id": 48, "name": "CHRISTMAS ISLAND" },
  { "id": 61, "name": "DENMARK" },
  { "id": 65, "name": "ECUADOR" },
  { "id": 238, "name": "WESTERN SAHARA" },
  { "id": 69, "name": "ERITREA" },
  { "id": 140, "name": "MICRONESIA, FEDERATED STATES OF" },
  { "id": 87, "name": "GRENADA" },
  { "id": 199, "name": "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS" },
  { "id": 90, "name": "GUATEMALA" },
  { "id": 89, "name": "GUAM" },
  { "id": 93, "name": "GUYANA" },
  { "id": 95, "name": "HEARD AND MC DONALD ISLANDS" },
  { "id": 96, "name": "HONDURAS" },
  { "id": 34, "name": "BRITISH INDIAN OCEAN TERRITORY" },
  { "id": 103, "name": "IRAQ" },
  { "id": 102, "name": "IRAN " },
  { "id": 111, "name": "KENYA" },
  { "id": 181, "name": "SAINT KITTS AND NEVIS" },
  { "id": 201, "name": "SRI LANKA" },
  { "id": 121, "name": "LIBERIA" },
  { "id": 120, "name": "LESOTHO" },
  { "id": 124, "name": "LITHUANIA" },
  { "id": 122, "name": "LIBYAN ARAB JAMAHIRIYA" },
  { "id": 145, "name": "MOROCCO" },
  { "id": 141, "name": "MOLDOVA, REPUBLIC OF" },
  { "id": 134, "name": "MARSHALL ISLANDS" },
  { "id": 132, "name": "MALI" },
  { "id": 147, "name": "MYANMAR" },
  { "id": 126, "name": "MACAU" },
  { "id": 160, "name": "NORTHERN MARIANA ISLANDS" },
  { "id": 135, "name": "MARTINIQUE" },
  { "id": 144, "name": "MONTSERRAT" },
  { "id": 131, "name": "MALDIVES" },
  { "id": 129, "name": "MALAWI" },
  { "id": 139, "name": "MEXICO" },
  { "id": 146, "name": "MOZAMBIQUE" },
  { "id": 148, "name": "NAMIBIA" },
  { "id": 156, "name": "NIGER" },
  { "id": 159, "name": "NORFOLK ISLAND" },
  { "id": 157, "name": "NIGERIA" },
  { "id": 155, "name": "NICARAGUA" },
  { "id": 150, "name": "NEPAL" },
  { "id": 149, "name": "NAURU" },
  { "id": 158, "name": "NIUE" },
  { "id": 154, "name": "NEW ZEALAND" },
  { "id": 162, "name": "OMAN" },
  { "id": 166, "name": "PANAMA" },
  { "id": 169, "name": "PERU" },
  { "id": 163, "name": "PAKISTAN" },
  { "id": 174, "name": "PUERTO RICO" },
  { "id": 165, "name": "PALESTINIAN TERRITORY" },
  { "id": 173, "name": "PORTUGAL" },
  { "id": 168, "name": "PARAGUAY" },
  { "id": 175, "name": "QATAR" },
  { "id": 176, "name": "REUNION" },
  { "id": 177, "name": "ROMANIA" },
  { "id": 179, "name": "RWANDA" },
  { "id": 188, "name": "SAUDI ARABIA" },
  { "id": 191, "name": "SEYCHELLES" },
  { "id": 202, "name": "SUDAN" },
  { "id": 180, "name": "SAINT HELENA" },
  { "id": 189, "name": "SENEGAL" },
  { "id": 197, "name": "SOMALIA" },
  { "id": 67, "name": "EL SALVADOR" },
  { "id": 205, "name": "SWAZILAND" },
  { "id": 214, "name": "TOGO" },
  { "id": 210, "name": "TAJIKISTAN" },
  { "id": 220, "name": "TURKMENISTAN" },
  { "id": 218, "name": "TUNISIA" },
  { "id": 216, "name": "TONGA" },
  { "id": 211, "name": "TANZANIA" },
  { "id": 223, "name": "UGANDA" },
  { "id": 229, "name": "URUGUAY" },
  { "id": 230, "name": "UZBEKISTAN" },
  { "id": 184, "name": "SAINT VINCENT AND THE GRENADINES" },
  { "id": 233, "name": "VENEZUELA" },
  { "id": 237, "name": "WALLIS AND FUTUNA ISLANDS" },
  { "id": 239, "name": "YEMEN" },
  { "id": 138, "name": "MAYOTTE" },
  { "id": 240, "name": "ZAMBIA" },
  { "id": 241, "name": "ZIMBABWE" },
  { "id": 16, "name": "AUSTRALIA" },
  { "id": 33, "name": "BRAZIL" },
  { "id": 41, "name": "CANADA" },
  { "id": 47, "name": "CHINA" },
  { "id": 1, "name": "Germany" },
  { "id": 76, "name": "FRANCE" },
  { "id": 85, "name": "GREECE" },
  { "id": 101, "name": "INDONESIA" },
  { "id": 100, "name": "INDIA" },
  { "id": 99, "name": "ICELAND" },
  { "id": 106, "name": "ITALY" },
  { "id": 108, "name": "JAPAN" },
  { "id": 114, "name": "South Korea" },
  { "id": 243, "name": "MONTENEGRO" },
  { "id": 130, "name": "MALAYSIA" },
  { "id": 151, "name": "NETHERLANDS" },
  { "id": 161, "name": "NORWAY" },
  { "id": 172, "name": "POLAND" },
  { "id": 183, "name": "SAINT PIERRE AND MIQUELON" },
  { "id": 178, "name": "RUSSIAN FEDERATION" },
  { "id": 193, "name": "SINGAPORE" },
  { "id": 192, "name": "SIERRA LEONE" },
  { "id": 219, "name": "TURKEY" },
  { "id": 217, "name": "TRINIDAD AND TOBAGO" },
  { "id": 198, "name": "SOUTH AFRICA" },
  { "id": 207, "name": "SWITZERLAND" },
  { "id": 142, "name": "MONACO" },
  { "id": 143, "name": "MONGOLIA" },
  { "id": 136, "name": "MAURITANIA" },
  { "id": 133, "name": "MALTA" },
  { "id": 137, "name": "MAURITIUS" },
  { "id": 153, "name": "NEW CALEDONIA" },
  { "id": 167, "name": "PAPUA NEW GUINEA" },
  { "id": 170, "name": "PHILIPPINES" },
  { "id": 164, "name": "PALAU" },
  { "id": 196, "name": "SOLOMON ISLANDS" },
  { "id": 195, "name": "SLOVENIA" },
  { "id": 204, "name": "SVALBARD AND JAN MAYEN ISLANDS" },
  { "id": 194, "name": "SLOVAKIA" },
  { "id": 186, "name": "SAN MARINO" },
  { "id": 203, "name": "SURINAME" },
  { "id": 187, "name": "SAO TOME AND PRINCIPE" },
  { "id": 208, "name": "SYRIAN ARAB REPUBLIC" },
  { "id": 221, "name": "TURKS AND CAICOS ISLANDS" },
  { "id": 212, "name": "THAILAND" },
  { "id": 215, "name": "TOKELAU" },
  { "id": 213, "name": "TIMOR-LESTE" },
  { "id": 222, "name": "TUVALU" },
  { "id": 228, "name": "UNITED STATES MINOR OUTLYING ISLANDS" },
  { "id": 232, "name": "VATICAN CITY STATE (HOLY SEE)" },
  { "id": 235, "name": "VIRGIN ISLANDS (BRITISH)" },
  { "id": 236, "name": "VIRGIN ISLANDS (U.S.)" },
  { "id": 234, "name": "VIET NAM" },
  { "id": 231, "name": "VANUATU" },
  { "id": 190, "name": "SERBIA AND MONTENEGRO" }
]

