import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Wrapper from "./Wrapper";
import { updateWindowWidth } from "../../appRedux/actions";
import { Redirect, Route, Switch } from "react-router-dom";

import Search from "../Search";
import SignIn from "../SignIn";
import Dashboard from "../Dashboard";
import Profile from "../Profile";
import Shipyard from "../Shipyard";
import Shipmanager from "../Shipmanager";
import CompanyGroup from "../CompanyGroup";
import Vessel from "../Vessel";
import Order from "../Order";
import News from "../News";
import RecyclingGuide from "../RecyclingGuide";
import ConstructionGuide from "../ConstructionGuide";
import NewBuildTracker from "../NewBuildTracker";
import RecyclingTracker from "../RecyclingTracker";
import Orderer from "../Orderer";
import Home from "../../routes/Home";
import EmailConfirmation from "../EmailConfirmation";
import LoginOrRegister from "../LoginOrRegister";
import RegisterSuccess from "../RegisterSuccess";
import Statistics from "../Statistics";
import NewsDetails from "../../components/NewsDetails";

const MainApp = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("resize", () => {
      dispatch(updateWindowWidth(window.innerWidth));
    });
  }, [dispatch]);

  const start = "/";
  return (
    <Wrapper>
      <div className="gx-main-content-wrapper">
        <Switch>
          <Route
            path={`${start}email-confirmation`}
            component={EmailConfirmation}
          />
          <Route path={`${start}dashboard`} component={Dashboard} />
          <Route path={`${start}profile`} component={Profile} />
          <Route path={`${start}shipyards/:slug`} component={Shipyard} />
          <Route path={`${start}ship-manager/:slug`} component={Shipmanager} />
          <Route
            path={`${start}company-groups/:slug`}
            component={CompanyGroup}
          />
          <Route path={`${start}vessels/:slug`} exact component={Vessel} />
          <Route
            path={`${start}login-or-register`}
            component={LoginOrRegister}
          />
          <Route
            path={`${start}register-success`}
            component={RegisterSuccess}
          />
          <Route path={`${start}orders/:slug`} component={Order} />
          <Route path={`${start}orderers/:slug`} component={Orderer} />
          <Route path={`${start}signIn`} component={SignIn} />
          <Route path={`${start}auth`} component={SignIn} />
          <Route path={`${start}home`} component={Home} />
          <Route path={`${start}search`} component={Search} />
          <Route path={`${start}statistics`} component={Statistics} />
          <Route path={`${start}news/:slug`} component={NewsDetails} />
          <Route path={`${start}news`} component={News} />
          <Route path={`${start}guide/recycling`} component={RecyclingGuide} />
          <Route
            path={`${start}guide/construction`}
            component={ConstructionGuide}
          />
          <Route
            path={`${start}tracker/new-build`}
            component={NewBuildTracker}
          />
          <Route
            path={`${start}tracker/recycling`}
            component={RecyclingTracker}
          />
          <Route exact path={`${start}`}>
            <Redirect to={`${start}home`} />
          </Route>
          <Route path={`${start}app`}>
            <Redirect to={`${start}home`} />
          </Route>
          <Route path="*">
            <div>404 page not found</div>
          </Route>
        </Switch>
      </div>
    </Wrapper>
  );
};
export default MainApp;
